.forthtab{
    background-position: center;
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    padding: 180px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.forthtab * {
    color:white;
}

.forthtab> :nth-child(1) > h1 {
    font-size: 56px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: gray 2px 2px 2px;
    margin-bottom: 80px;
}

.forthtab> :nth-child(1) > p {
    color: white;
    font-size: 23px;
    line-height: 160%;
    margin-bottom: 50px;
    word-break: keep-all;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
}

.map_div{
    max-width: 900px;
    width: 100%;
    height: 500px;
    /* box-shadow:#020202 5px 5px 5px; */
    /* border: 1px ridge #020202; */
    border-radius: 2px;
    margin-bottom: 50px;
}

.bus_car_div{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    width: 90vw;
    /* background-color: red; */
}

.how_come {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    text-align: center;
}
.how_come > :nth-child(1){
    padding-top: 50px;
    font-size: 100px;
    margin-right: 20px;
    text-shadow: 2px 2px 2px gray;
}

.how_come > :nth-child(2) > div{
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    line-height: 160%;
}

.how_come > :nth-child(2) > h3{
    font-size: 30px;
    font-family: "Noto Sans KR";
    margin-bottom: 15px;
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
}

.vertical_line{
    width: 1px;
    background-color: silver;
    height: 100%;
    float: left;
    border: 2px ridge silver ;
    border-radius: 2px;
  }


@media screen and (max-width: 768px){
    .forthtab> :nth-child(1) > h1 {
        font-size: 50px;
    }
    
    .forthtab> :nth-child(1) > p {
        font-size: 20px;
        text-align: center;
    }

    .map_div{
        height: 300px;
        width: 80vw;
        /* box-shadow: 1px 1px 1px; */
        margin-bottom: 50px;
    }

    .bus_car_div{
        flex-direction: column;
        text-align: center;
    }

    .how_come {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .how_come > :nth-child(1){
        /* padding-top: 80px; */
        padding-left: 20px;
        font-size: 60px;
        /* margin-right: 100px; */
        margin-bottom: 70px;
        align-items: center;
        justify-content: center;
    }

    .vertical_line{
        display: none;
    }
}