.redHederText{
    color: red;
    font-size: 1em;
}


.blueHederText{
    color: blue;
    font-size: 1em;
}

.redText{
    color: red;
    font-size: 1em;
}

.blueText{
    color: blue;
    font-size: 1em;
}
