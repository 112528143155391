.wrap_div * {
    font-family: "Noto Sans KR";
}

.wrap_div {
    /* background-color: #F7F8Fd; */
    background-color: #F4F8FF;
    padding: 180px 30px;
    font-family: "Noto Sans KR";;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    height: 100%;
}



.menu_div {
    height: max-content;
    border-radius: 5px;
    padding: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;
}

.menu_div> h1 {
    font-size: 20px;
    margin: 0px;
}

.menu_div > hr {
    border: 0;
    border-top: 1px solid rgba(17, 12, 46, 0.15);
    width: 100%;
    margin: 10px 0px;
}

.menu_div > p {
    /* background-color: red; */
    margin: 0px;
    padding: 5px;
}

.menu_div > p:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.content_div {
    height: max-content;
    position: relative;
    width: 90%;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    min-height: 472px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.datepicker_input > input {
    border: 1px solid #000000 !important;
}