@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

/* body {
    width: 100%;
  }
  .intro {
    width: 100%;
    height: 100vh;
    overflow: auto;
    margin: 0px auto;
    position: relative;
  }

  .full_video {
      width:177.vh;
      height:56.25vw;
      overflow-x:hidden;
      min-width:100%;
      min-height:100%;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      position:absolute;
  } */

  .wrap_div {
    width: 100vw;
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .style_a {
    background-color: rgba(88, 20, 225, 0.7);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    width: 150px;
    height: 50px;
    color: white;
    /* font-size: 16px; */
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    text-align: center;
    padding-top: 16px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.style_a:hover {
  cursor: pointer;
    background: #5814E1;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #5814E1,
                0 0 25px #5814E1,
                0 0 50px #5814E1,
                0 0 150px #5814E1;
  }



  .intro_txt_btn {
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    position:absolute;
  }

  .bg {
    background-repeat: repeat-x;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 1080px;
  }

  .bg_01 {
    background-image: url(/images/main/main_bg_02.png);
    background-size: cover;
    flex-direction:column;
  }

  .bg_02 {
    background-image: url(/images/main/main_bg_03.png);
    background-size: cover;
    flex-direction:column;
  }

  .bg_03 {
    background-image: url(/images/main/main_bg_04.png);
    background-size: cover;
    flex-direction:column;
  }

  .bg_03 > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .bg_03 > :nth-child(1) > h1 {
    color: white;
    font-family: "Noto Sans KR";
    font-size: 52px;
    text-shadow: gray 2px 2px 2px;
    margin-bottom: 30px;
  }
  .bg_03 > :nth-child(1) > p {
    font-family: "Noto Sans KR";
    color: white;
    font-size: 25px;
    text-shadow: gray 2px 2px 2px;
    line-height:180%;
  }

  .bg_03 > :nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 50px;
  }

  .bg_03 > :nth-child(2) > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .bg_03 > :nth-child(2) > div > h1 {
    font-size:150px;
    font-weight: 6000;
    color:#32FAEA;
    padding:0;
    margin:0;
    text-shadow: gray 2px 2px 1px;
  }

  .bg_03 > :nth-child(2) > div > span {
    font-size: 50px;
    font-weight:600;
    color:white;
    padding:0;
    margin-right: 40px;
    text-shadow: gray 2px 2px 1px;
  }

  .bg_03 > :nth-child(3){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .bg_03 > :nth-child(3) > :nth-child(1){
    position: relative;
    margin-right: 50px;
    margin-bottom: 0px;
  }
  .bg_03 > :nth-child(3) > :nth-child(1) > p{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    font-size: 25px;
    font-weight: bold;
    color: #0d35b4;
  }

  .bg_03 > :nth-child(3) > :nth-child(2){
    position: relative;
  }

  .bg_03 > :nth-child(3) > :nth-child(2) > p{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    font-size: 25px;
    font-weight: bold;
    color: #0d35b4;
  }


  @media screen and (max-width: 768px){
    .bg_03 { padding: 150px 0px; }
    .bg_03 > :nth-child(1) { padding: 0px 50px }
    .bg_03 > :nth-child(2) { flex-direction: column; }
    .bg_03 > :nth-child(2) > div > h1 { font-size: 150px;}
    .bg_03 > :nth-child(2) > div > span {font-size: 30px;}
    .bg_03 > :nth-child(3) { flex-direction: column; }
    .bg_03 > :nth-child(3) > :nth-child(1){ margin-right: 0px; margin-bottom: 50px; margin-top: 50px;}
    /* .bg_03 > :nth-child(3) > :nth-child(2){ } */
    .intro_txt_btn > :nth-child(1) > img { width: 460px; }
  }

