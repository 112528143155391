@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://blogfonts.com/css/aWQ9MTU1MTUxJnN1Yj0xNTEmYz1wJnR0Zj1wb3N0LW5vLWJpbGxzLWNvbG9tYm8uZXh0cmFib2xkLnR0ZiZuPXBvc3Qtbm8tYmlsbHMtY29sb21iby1leHRyYWJvbGQ/Post No Bills Colombo ExtraBold.ttf);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap);
@import url(https://blogfonts.com/css/aWQ9MTU1MTUxJnN1Yj0xNTEmYz1wJnR0Zj1wb3N0LW5vLWJpbGxzLWNvbG9tYm8uZXh0cmFib2xkLnR0ZiZuPXBvc3Qtbm8tYmlsbHMtY29sb21iby1leHRyYWJvbGQ/Post No Bills Colombo ExtraBold.ttf);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'); */


@font-face {
  font-family: 'Noto Sans KR';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/Noto Sans KR.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Play";
  src: url("/font/Play-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Introds_wrap_div__2p64M {
    background-image: url(/images/intro_DS/rule_image/rules_bg01.jpg);
    background-position: center;
    /* height: 8407px; */
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw !important;
    overflow: hidden;
}

.Introds_intro_content_text__2gT6P {
    padding: 0px 40px;
    font-size: 18px;
    text-align: center;
    word-break: keep-all;
    width: 1200px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    line-height: 131.5%;
    /* or 33px */
    letter-spacing: -0.03em;
    color: #000000;
    margin-top: 70px;
}

.Introds_intro_content_text__2gT6P > span {
    font-weight: bold;
    color: #61D1E0;
}

.Introds_title_div__23Dxg * {
    font-family: "Noto Sans KR";
}

.Introds_title_div__23Dxg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
}

.Introds_title_div__23Dxg > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px;
    color: #000000;
}

.Introds_title_div__23Dxg > hr {
    width: 100%;
}

.Introds_title_div_stadium__2_hll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -130px;
}

.Introds_title_div_stadium__2_hll h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0px;
    color: #000000;
    
}

.Introds_title_div_stadium__2_hll hr {
    width: 100%;
}

.Introds_intro_droneball_div__9QVKI{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.Introds_intro_third_forth_img__uX6SQ{
    width: 500px;
    height: 500px;
}

.Introds_intro_title_sub_text1__3edNU{
    font-size: 18px;
    position: relative;
    top: -500px;
    left: -430px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .Introds_intro_title_sub_text2__3suSB{
    font-size: 18px;
    position: relative;
    top: -695px;
    left: 400px;
    position: relative;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }
  
  .Introds_intro_title_sub_text3__aah-Z{
    font-size: 18px;
    position: relative;
    top: -280px;
    left: 350px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }

  .Introds_intro_title_sub_text4__2pq4W{
    font-size: 18px;
    position: relative;
    top: -530px;
    left: -310px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
  }


.Introds_droneBall_arrow__3UYSf{
    display: flex;
}

.Introds_droneBall_arrow1__r-6_j{
    position: relative;
    top: -500px;
    left: -300px;
}
.Introds_droneBall_arrow2__3n0HW{
    position: relative;
    top: -615px;
    left: 225px;
}
.Introds_droneBall_arrow3__wX8ZC{
    position: relative;
    top: -230px;
    left: 170px;
}

.Introds_droneBall_arrow4__12Dbs{
    position: relative;
    top: -550px;
    left: -290px;
}





  
  

.Introds_explanation_div__ytch0 {
    margin-bottom: 33px;
    background-color: #A50000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 10px 0px;
}

.Introds_explanation_div__ytch0 > * {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.015em;
}

.Introds_explanation_div_white__kkSjX {
    margin-bottom: 33px;
    background-color: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 10px 0px;
}

.Introds_explanation_div_white__kkSjX > * {
    letter-spacing: -0.015em;
    color: #020202;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.Introds_explanation_div_white__kkSjX * > span {
    color: #FF0000;
}

.Introds_explanation_div_white__kkSjX * > u {
    text-decoration: none;
    /* border-bottom: 10px solid yellow; */
    box-shadow: inset 0 0px 0 white, inset 0 -5px 0 yellow
}

.Introds_rule_image_div1__1dRom{
    position: relative;
}

.Introds_rule_image_div1__1dRom > :nth-child(2) {
    position: absolute;
    top: 37%;
    left: 5%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFBABA;
    font-weight: bold;
    font-size: 15px;
}

.Introds_rule_image_div1__1dRom > :nth-child(3) {
    position: absolute;
    top: 37%;
    right: 6%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFBABA;
    font-weight: bold;
    font-size: 15px;
}

.Introds_rule_image__1z79p {
    width: 1200px;
}

.Introds_rule_image_div2__3isWv{
    position: relative;
}

.Introds_rule_image_div2__3isWv > :nth-child(2) {
    position: absolute;
    top: 47%;
    left: 32%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.Introds_rule_image_div2__3isWv > :nth-child(3) {
    position: absolute;
    top: 47%;
    right: 25.5%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}


.Introds_little_title_div__3rd4F {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.Introds_little_title_div__3rd4F > p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Introds_little_title_div__3rd4F > hr {
    width: 100%;
}

.Introds_circle_rules__37eC7 {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;
    /* background-color: red; */
}

.Introds_circle_rules__37eC7 > div {
    position: relative;
}

.Introds_circle_rule_image__302tR {
    width: 350px;
}

.Introds_circle_rules__37eC7 > div > div {
    position: absolute;
    top: 50%;
    left: 48%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    font-family: "Noto Sans KR";
    height: 50%;
    /* background-color: red; */
}

.Introds_circle_rules__37eC7 > div > div > :nth-child(2) {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-top: -30px;
    font-weight: bold;
    width: 260px;
    color: #000000;
    /* background-color: red; */
}


.Introds_circle_rules__37eC7 > div > div > :nth-child(2) > span {
    color: #FF0000;
}




.Introds_circle_rules__37eC7 > div > div > :nth-child(1) {
    position: relative;
    top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Introds_circle_rules__37eC7 > div > div > div > h1{
    font-size: 60px;
    margin: 0px;
    font-weight: bold;
    color: #000000;
}

.Introds_circle_rules__37eC7 > div > div > div > p {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000000;
    /* background-color: red; */
}

.Introds_circle_rules__37eC7 > div > div > div hr {
    width: 250px;
}

.Introds_download_rulebook__2T95f{
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    font-size: 40px;
    margin-bottom: 100px;
    text-align: center;
}

.Introds_download_div__gJWK- {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 800px;
}

.Introds_download_div__gJWK- > a {
    text-decoration: none;
    background-color: #CF3C0C;
    border-radius: 5px;
    width: 150px;
    height: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Introds_download_div__gJWK- > a:hover {
    cursor: pointer;
}


.Introds_download_div__gJWK- > a > p {
    margin-bottom: 10px;
    color: #FFFFFF;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 20px;
}

@media screen and (max-width: 1900px){
    .Introds_download_rulebook__2T95f {
        color: #000000;
    }
}

@media screen and (max-width: 1200px) {
    .Introds_rule_image__1z79p { width: 800px; }
    .Introds_intro_content_text__2gT6P { width: 800px; }
    .Introds_intro_third_forth_img__uX6SQ {width: 400px; height: 400px;}
    .Introds_intro_title_sub_text1__3edNU{ font-size: 18px; position: static; margin-top: 50px;}
    .Introds_intro_title_sub_text2__3suSB{ font-size: 18px; position: static;}
    .Introds_intro_title_sub_text3__aah-Z{ font-size: 18px; position: static;}
    .Introds_intro_title_sub_text4__2pq4W{ font-size: 18px; position: static; }
    .Introds_droneBall_arrow__3UYSf { display: none; }
    .Introds_droneBall_arrow1__r-6_j{ position: static; display: none;}
    .Introds_droneBall_arrow2__3n0HW{ position: static; display: none; margin-bottom: 15px;}
    .Introds_droneBall_arrow3__wX8ZC{ position: static; display: none; margin-bottom: 15px;}
    .Introds_title_div_stadium__2_hll { margin-top: 130px; }


    .Introds_rule_image_div1__1dRom > :nth-child(2) {
        top: 35%;
        left: 5%;
        font-size: 15px;
    }
    .Introds_rule_image_div1__1dRom > :nth-child(3) {
        top: 35%;
        right: 4.2%;
        font-size: 15px;
    }
    .Introds_rule_image_div2__3isWv > :nth-child(2) {
        top: 45%;
        left: 32%;
        font-size: 10px;
    }
    
    .Introds_rule_image_div2__3isWv > :nth-child(3) {
        top: 45%;
        right: 24.5%;
        font-size: 10px;
    }
    .Introds_circle_rules__37eC7 {
        width: 800px;
    }
    .Introds_circle_rule_image__302tR {
        width: 250px;
    }
    .Introds_circle_rules__37eC7 > div > div > div h1{
        font-size: 40px;
    }
    
    .Introds_circle_rules__37eC7 > div > div > div p {
        font-size: 10px;
        margin-bottom: 5px;
    }
    .Introds_circle_rules__37eC7 > div > div > div hr {
        width: 150px;
    }
    .Introds_circle_rules__37eC7 > div > div > :nth-child(2) {
        width: 230px;
        font-size: 13px;
        margin-top: -30px;
        font-weight: bold;
    }

}

@media screen and (max-width: 768px) {
    .Introds_title_div__23Dxg {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    /* .title_div > hr {
        width: 100%;
    } */


    .Introds_rule_image__1z79p { width: 350px; }
    .Introds_intro_content_text__2gT6P { width: 100%; text-align: center; word-break: normal;}
    .Introds_intro_third_forth_img__uX6SQ { width: 300px !important; height: 300px !important;}


    .Introds_rule_image_div1__1dRom > :nth-child(2) {
        top: 32%;
        left: 5%;
        font-size: 8px;
        
        /* background-color: red; */
    }
    .Introds_rule_image_div1__1dRom > :nth-child(3) {
        top: 32%;
        right: 3.2%;
        font-size: 8px;
    }
    .Introds_rule_image_div2__3isWv > :nth-child(2) {
        top: 33%;
        left: 34%;
        font-size: 2px;
    }
    
    .Introds_rule_image_div2__3isWv > :nth-child(3) {
        top: 33%;
        right: 19.7%;
        font-size: 2px;
    }
    
    .Introds_circle_rules__37eC7 {
        width: 430px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 200px;
    }

    .Introds_explanation_div__ytch0 {
        width: 350px !important;
        padding: 10px;
    }
    
    .Introds_explanation_div__ytch0 p {
        word-break: keep-all;
    }

    .Introds_explanation_div_white__kkSjX {
        width: 350px;
        padding: 10px;
    }
    
    .Introds_explanation_div_white__kkSjX p {
        word-break: keep-all;
    }
    .Introds_download_rulebook__2T95f {
        color: #ffffff;
    }
    .Introds_download_div__gJWK- {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 430px;
        height: 500px;
    }
}
.Topmenu_wrapper__1t5xf {
    background-color: #d32d36;
    /* background-color: blue; */
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 79px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* overflow: hidden; */
}

.Topmenu_topmenuWrap__29HW8 {
    background-color: #d32d36;
    max-width: 1900px;
    width: 100vw;
    flex-shrink: 0;
    z-index: 1000;
}


/* .largeMenuLogo { */
    /* margin-top: 10px; */
    /* width: 55px !important; */
    /* background-color: black; */
/* } */

.Topmenu_smallLogoColumn__3cjhs {
    display: none !important;
}

/* .smallLogoColumn > img { */
    /* width: 40px; */
/* } */

.Topmenu_smallLogoColumn__3cjhs:hover {
    cursor: pointer;
}


.Topmenu_largeMenuLogo__axsJ0:hover {
    cursor: pointer;
}

.Topmenu_topmenuWrap__29HW8 span {
    font-weight: bold;
    color:white;
    font-size:1.2em;
}

.Topmenu_topmenuWrap__29HW8 .Topmenu_item__O52oh {
    font-weight: bold;
    font-size:1em;
    color: #d32d36;
}

.Topmenu_topmenuWrap__29HW8 .Topmenu_item__O52oh {
    font-weight: bold;
    font-size:1em;
    color: #d32d36;
}

.Topmenu_topmenuWrap__29HW8 .Topmenu_menuList__1egMq {
    width:1000px;
    justify-content: space-between;
    align-items: center;
}

.Topmenu_topmenuWrap__29HW8 .Topmenu_miniMenuDiv__2o8_y {
    display: none;
}
.Topmenu_miniMenuColumn__1FsXU {
    display: none !important;
}

.Topmenu_miniMenuColumn__1FsXU > div > div * {
    font-size: 12px !important;
}

.Topmenu_largeMenuDiv__HzUKK {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.Topmenu_largeMenuDiv__HzUKK > div > :nth-child(1){
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding-bottom: 10px !important;
}

.Topmenu_dropdown_tap__A7s4r {
    font-weight: bold !important;
    text-align: center !important;
    font-size: 13px !important;
}

.Topmenu_dropdown_tap__A7s4r:hover {
    background-color: #9B1C23 !important;
}


.Topmenu_dropdown_tap__A7s4r:hover > div >  img {
    color: white;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) {
    border: 0px !important;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) >div {
    background-color: #d32d36 !important;
    text-align: center !important;
    width: 95px !important;
    position: relative;
    height: 40px !important;
    color: white !important;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) > div > :nth-child(1) {
    color: white;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}


.Topmenu_dropdown_tap__A7s4r > :nth-child(2) >div:hover {
    background-color: #9B1C23 !important;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) > div >  span {
    font-family: "Noto Sans KR";
    font-weight: bold !important;
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    position: absolute !important;
    display: block !important;
    
    width: 95px !important;
    word-break: break-all !important;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    color: white !important;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) > div > :nth-child(2) {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    border: none;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) > div > :nth-child(2) > div{
    background-color: #d32d36 !important;
    font-family: "Noto Sans KR" !important;
    font-style: normal !important;
    font-weight: bold !important;
    color: white !important;
    font-size: 13px !important;
    line-height: 15px !important;
    left: 10px;
    text-align: center !important;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) > div > :nth-child(2) > div:hover{
    background-color: #9B1C23 !important;
}

.Topmenu_dropdown_tap__A7s4r > :nth-child(2) > div > :nth-child(2)::after{
    background-color: #d32d36 !important;
    left: 7px !important;
}

.Topmenu_miniMenuDiv__2o8_y > div > div {
    background-color: #d32d36 !important;
}

.Topmenu_miniMenuDiv__2o8_y > div > div > div {
    color: white !important;
}




.Topmenu_style_a1__3PLOZ {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block !important;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s;
    letter-spacing: 0px;
    white-space: nowrap;
}

.Topmenu_style_a1__3PLOZ:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;
  }

  .Topmenu_style_a2__COCaa {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* overflow: hidden; */
    transition: .5s;
    letter-spacing: 0px;
    margin-right: 5px;
    white-space: nowrap;
    font-family: "Noto Sans KR";
    /* width: 69px; */
}

.Topmenu_style_a2__COCaa:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;
  }


  .Topmenu_style_a3__16YNV {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* overflow: hidden; */
    transition: .5s;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    white-space: nowrap;
    /* width: 59px; */
}

.Topmenu_style_a3__16YNV:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;

  }

.Topmenu_profile_div__3UFcn > a > span{
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    color: white;
}

.Topmenu_login_dropdown__400Es {
    padding-right: 14px;
    position: relative !important;
}


.Topmenu_login_dropdown__400Es > div > :nth-child(1) > span  {
    display: static !important;
    color: #010101;
    font-size: 14px;
    display: none;
}

.Topmenu_login_dropdown__400Es > div > :nth-child(3) {
    position: absolute !important;
    background-color: white !important;
    top: 90px !important;
    left: 5px !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

}

.Topmenu_login_dropdown__400Es > div > :nth-child(3) > div > img {
    width: 30px !important;
}

.Topmenu_login_dropdown__400Es > div > :nth-child(3) > div > span {
    font-family: "Noto Sans KR" !important;
    font-size: 13px !important;
    color: black;
    font-weight: 400 !important;
    text-align: center !important;
    width: 100% !important;
    display: inline-block;
}

.Topmenu_login_dropdown__400Es > div > :nth-child(3) > :nth-child(1) > span {
    display: inline;
}




.Topmenu_largeMenuLogoColumn__28uzh {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: left !important;
}

@media screen and (min-width: 769px) {
    .Topmenu_topmenuWrap__29HW8 .Topmenu_miniMenuDiv__2o8_y {
        display: none;
    }

    .Topmenu_topmenuWrap__29HW8 .Topmenu_menuList__1egMq {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .Topmenu_topmenuWrap__29HW8 {
        max-width: 100vw;
        min-width: 0px;
        width: 100vw;
    }

    .Topmenu_topmenuWrap__29HW8 > :nth-child(1){
        display: none;
    }
    .Topmenu_miniMenuColumn__1FsXU {
        display: flex !important;
    
    }
    .Topmenu_largeMenuDiv__HzUKK{
        display: none;
    }
    .Topmenu_largeMenuLogo__axsJ0{
        display: none;
    }
    .Topmenu_largeMenuLogoColumn__28uzh{
        display: none !important;
    }
    .Topmenu_smallLogoColumn__3cjhs {
        display: flex !important;
    }

    .Topmenu_largeMenuDivColumn__1oXFw{
        display: none !important;
    }

    .Topmenu_topmenuWrap__29HW8 .Topmenu_miniMenuDiv__2o8_y {
        padding: 15px;
        display: flex;
        width:100%;
        align-items: center;
    }

    .Topmenu_login_dropdown__400Es {
        padding-right: 0px;
    }

    .Topmenu_login_dropdown__400Es > div > :nth-child(3) {
        left: -10px !important;    
    }

}

/* @font-face {
    font-family: 'Noto Sans KR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/Noto Sans KR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

.Footer_wrap_div__3AgKz * {
    font-family: "Noto Sans KR";
    color: white;
    font-size: 0.8em;
}

.Footer_wrap_div__3AgKz {
    background-color: #D32D36;
    background-image: url('/images/footer/background_01.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    flex-shrink: 0;
    width: 100vw;
    position: relative;
    z-index: 99;
}

.Footer_wrap_div__3AgKz > :nth-child(1) > :nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    /* margin-top: 15px; */
}

.Footer_wrap_div__3AgKz > :nth-child(1) > :nth-child(1) > span {
    font-style: normal;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
    line-height: 27px;
    margin-left: 15px;
}

.Footer_wrap_div__3AgKz > :nth-child(1) > :nth-child(2) {
    margin-top: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.Footer_wrap_div__3AgKz > :nth-child(2) {
    margin-top: 40px;
}

.Footer_wrap_div__3AgKz > :nth-child(2) > div > img:hover {
 cursor: pointer;
}


.Footer_lang_div__1tkJn * {
    color:black;
}

.Footer_lang_div__1tkJn {
    display: flex;
    /* background-color: white; */
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Footer_wrap_div__3AgKz > :nth-child(1) > :nth-child(2) > a {
    color: #FFFFFF;
    font-size: 9pt;
    border-bottom: 1px solid #FFFFFF;
    padding: 2px;
    margin-left: 60px;
}


@media screen and (max-width: 768px) {
    .Footer_wrap_div__3AgKz { flex-direction: column; word-break: keep-all;}
    .Footer_wrap_div__3AgKz > :nth-child(1) { display: flex; flex-direction: column; align-items: center;}
    .Footer_wrap_div__3AgKz > :nth-child(1) > p { text-align: center; }
}
.IntroKDSA_intro_tap_box__3kclO {
    background-position: center;
    padding-top: 180px;
    padding-bottom: 280px;
    background-image: url("/images/intro/bg_01.jpg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.IntroKDSA_img_div__2SK1D {
    display: flex;
    flex-direction: row;
    width: 800px;
    justify-content: space-between;
    margin-top: 80px;
}

.IntroKDSA_intro_tap_box__3kclO > p {
    font-size: 20px;
    font-family: "Noto Sans KR";
    font-style: normal;
    text-align: center;
    color: white;
}

.IntroKDSA_intro_tap_box__3kclO > div {
    font-size: 20px;
    font-family: "Noto Sans KR";
    font-style: normal;
    text-align: center;
    color: white;
}

.IntroKDSA_intro_tap_box__3kclO .IntroKDSA_count_txt__1KAwv {
    font-size: 98px;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-weight: bold;
    color: #32faea;
    padding: 0;
    margin: 0;
}

.IntroKDSA_intro_tap_box__3kclO .IntroKDSA_count_name__3offb {
    font-size: 28px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
    color: white;
    padding: 0;
    margin: 0;
}

.IntroKDSA_intro_tap_box__3kclO .IntroKDSA_text_div__3vhUc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.IntroKDSA_text_div__3vhUc > :nth-child(1) {
    font-family: "Noto Sans KR";
    color: white;
    font-weight: normal;
    text-shadow: gray 2px 2px 2px;
    font-size: 18px;
    margin-bottom: 0px;
}

.IntroKDSA_text_div__3vhUc > :nth-child(2) {
    color: white;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow: hidden;
}

.IntroKDSA_text_div__3vhUc > :nth-child(3) {
    max-width: 1200px;
    padding: 0px 40px;
}

.IntroKDSA_orgChart_div__21FuQ {
    margin-top: 70px;
    border-radius: 5px;
}

.IntroKDSA_squad_table__2e39N {
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    margin-top: 70px;
}

.IntroKDSA_squad_table__2e39N > thead > tr {
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.IntroKDSA_squad_table__2e39N > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}

.IntroKDSA_squad_table__2e39N > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.IntroKDSA_squad_table__2e39N > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.IntroKDSA_squad_table__2e39N > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.IntroKDSA_squad_table__2e39N > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
}

@media screen and (max-width: 1200px) {
    .IntroKDSA_text_div__3vhUc > :nth-child(3) {
        width: 800px;
    }
    .IntroKDSA_orgChart_div__21FuQ {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .IntroKDSA_intro_tap_box__3kclO .IntroKDSA_text_div__3vhUc > h1 {
        font-size: 40px;
        overflow: hidden;
    }

    .IntroKDSA_text_div__3vhUc > :nth-child(2) {
        width: 430px;
        overflow: hidden;
    }
    .IntroKDSA_text_div__3vhUc > :nth-child(3) {
        width: 430px;
        overflow: hidden;
    }

    .IntroKDSA_intro_tap_box__3kclO .IntroKDSA_img_div__2SK1D {
        flex-direction: column;
    }

    .IntroKDSA_intro_tap_box__3kclO .IntroKDSA_img_div__2SK1D > div {
        margin-bottom: 30px;
    }

    .IntroKDSA_squad_table__2e39N {
        width: 80vw;
    }

    .IntroKDSA_squad_table__2e39N > thead > tr {
        height: 40px;
        font-size: 12px;
    }

    .IntroKDSA_squad_table__2e39N > tbody > tr {
        height: 40px;
        font-size: 16px;
    }
}

.IntroStatus_wrap_div__3YdWD * {
    font-family: "Noto Sans KR";
}

.IntroStatus_wrap_div__3YdWD {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.IntroStatus_wrap_div__3YdWD > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IntroStatus_wrap_div__3YdWD > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.IntroStatus_wrap_div__3YdWD > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    text-align: center;
}

.IntroStatus_province_div__3fD_Y {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0px;
}


.IntroStatus_province_chief_table__KXGWO{
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IntroStatus_province_chief_table__KXGWO > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 16px;
}

.IntroStatus_province_chief_table__KXGWO > tbody > tr > :nth-child(1) {
    height: 60px;
    font-size: 18px;
    font-weight: bold;
}

.IntroStatus_squad_table__2M80s{
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IntroStatus_squad_table__2M80s > thead > tr {
    height: 40px ;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.IntroStatus_squad_table__2M80s > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}

.IntroStatus_squad_table__2M80s > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.IntroStatus_squad_table__2M80s > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.IntroStatus_squad_table__2M80s > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.IntroStatus_squad_table__2M80s > tbody > :nth-last-child(1) > td {
    border-bottom: 0px
}

.IntroStatus_testtest__NyXXJ{
    display: none;
}

@media screen and (max-width: 768px){

    /* .wrap_div > :nth-child(1) > h1 { font-size: 30px; } */

    .IntroStatus_wrap_div__3YdWD > :nth-child(2) { font-size: 15px; }

    .IntroStatus_wrap_div__3YdWD > :nth-child(2) > :nth-child(1) > :nth-child(1) > *{ font-size: 13px; }

    .IntroStatus_province_div__3fD_Y > h1 { font-size: 20px; }

    .IntroStatus_province_chief_table__KXGWO > tbody > tr > :nth-child(1) { height: 40px; font-size: 13px; }

    .IntroStatus_province_chief_table__KXGWO > tbody > tr {font-size: 12px;}

    .IntroStatus_squad_table__2M80s { width: 80vw; }
    
    .IntroStatus_squad_table__2M80s > thead > tr { height: 40px ; font-size: 18px;}

    .IntroStatus_squad_table__2M80s > tbody > tr { height: 40px; font-size: 16px; }
}
.TeamModal_wrap_modal__60lcZ > div{
  /* background: radial-gradient(circle, rgba(68,69,154,1) 0%, rgba(11,26,42,1) 66%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.TeamModal_content_div__148ru {
    /* margin: 50px, 0px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 50px 0px; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate( -50%, -50% ); */
}

.TeamModal_content_div__148ru > p {
    color: white;
    font-family: "Noto Sans KR";
    font-size: 25px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #61ece1;
}

.TeamModal_content_div__148ru > h1 {
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.TeamModal_content_div__148ru > :nth-child(3) {
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TeamModal_team_logo_div__JkgEP{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

.TeamModal_team_logo_div__JkgEP > img{
    width: 200px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
                0 0 0 6px #020202, 
                0 0 0 9px #61ece1;
}

.TeamModal_team_logo_div__JkgEP > p{
    color: white;
    font-family: "Noto Sans KR";
    font-size: 25px;
    margin-top: 30px;
    text-align: center;
}

.TeamModal_member_div__2foSh{
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 700px;
}

.TeamModal_member_profile__293r2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  position: relative;
}

/* .member_profile:hover {
  background-color: red;

} */

.TeamModal_member_profile__293r2:hover {
  cursor: pointer;
   
}
/* generated element for shine effect.
 * normal state is semi-transparent
 * white but with zero width. Set no
 * transition here for no mouse-leave
 * animations. Otherwise the effect
 * will play in reverse when your mouse
 * leaves the element
 */
.TeamModal_member_profile__293r2:after {
    content: "";
    position: absolute;
    top: 120px;
    left: 0px;
    width: 0%;
    height: 30%;
    background-color: rgba(255,255,255,0.4);
    transition: none;
   
}
/* on hover we animate the width to
 * 100% and opacity to 0 so the element
 * grows and fades out
 */
.TeamModal_member_profile__293r2:hover:after {
    width: 120%;
    background-color: rgba(255,255,255,0);
    transition: all 0.3s ease-out;
}

.TeamModal_member_profile__293r2 > img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
              0 0 0 6px #020202, 
              0 0 0 9px #61ece1;
  margin-bottom: 20px;
}

.TeamModal_member_profile__293r2 > p{
  margin: 0px;
  font-family: "Noto Sans KR";
}

.TeamModal_member_profile__293r2 > :nth-child(2){
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.TeamModal_member_profile__293r2 > :nth-child(3){
  font-size: 12px;
  color: #61ece1;
  /* font-weight: bold; */
}

.TeamModal_style_a__U_YoY {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    /* font-weight: bold; */

}

.TeamModal_style_a__U_YoY:hover {
    cursor: pointer;
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }


  @media screen and (max-width: 1200px){
    .TeamModal_wrap_modal__60lcZ { width: 800px !important; }
    .TeamModal_content_div__148ru { width: 800px; }
    .TeamModal_content_div__148ru > :nth-child(3) { flex-direction: column; }
    .TeamModal_team_logo_div__JkgEP{ margin-right: 0px; margin-bottom: 50px; }
  }

  @media screen and (max-width: 768px) {
    .TeamModal_wrap_modal__60lcZ { width: 430px !important; }
    .TeamModal_content_div__148ru { width: 430px; }
    .TeamModal_member_profile__293r2 { width: 240px; margin: 20px 0px;}
  }
.PlayerInfoModal_content_div__1jUth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* background-color: blue; */
}

.PlayerInfoModal_member_profile__3HPS9 > img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
                0 0 0 6px #020202, 
                0 0 0 9px #61ece1;
    margin-top: 10px;
  }

  .PlayerInfoModal_style_a__16qqq {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    /* font-weight: bold; */

}

.PlayerInfoModal_style_a__16qqq:hover {
    cursor: pointer;
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }
.IntroMascot_secondtab__3iijA {
    background-position: center;
    /* min-height: 1080px; */
    height: 100%;
    min-height: 100vh;
    width: 100vw !important;
    /* padding: 180px 0em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.IntroMascot_secondtab__3iijA * {
    color:white;
}

.IntroMascot_secondtab__3iijA > div {
    width: 1200px;
}


.IntroMascot_thirdtab__1103i > div {
    width: 1200px;
}

.IntroMascot_intro_third_forth_img__2mvtH{
    width: 350px;
}

.IntroMascot_intro_third_forth_img2__2JPsK{
    width: 400px;
}

.IntroMascot_secondtab__3iijA > div > :nth-child(1) {
    margin-right: 60px;
}

.IntroMascot_thirdtab__1103i > div > :nth-child(1) {
    margin-right: 60px;
}

.IntroMascot_secondtab__3iijA > div > :nth-child(2) > :nth-child(1) > h1 {
    text-align: center;
    display: inline;
    font-size: 56px;
    font-family: "Noto Sans KR";
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
}
.IntroMascot_secondtab__3iijA > div > :nth-child(2) > :nth-child(2) > div{
    font-family: "Noto Sans KR";
    /* margin-top: 30px; */
    color: white;
    width: 700px;
    font-size: 20px;
    line-height: 160%;
}


.IntroMascot_thirdtab__1103i > div > :nth-child(1) > :nth-child(1) > h1 {
    text-align: center;
    display: block;
    font-size: 56px;
    font-family: "Noto Sans KR";
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
    word-break: keep-all;
}

.IntroMascot_thirdtab__1103i > div > :nth-child(1) > :nth-child(2) > div {
    color: white;
    width: 700px;
    line-height: 160%;
    font-size: 20px;
    line-height: 160%;
    font-family: "Noto Sans KR";
}
/* style={{ textAlign: "left", display: "inline", fontSize: "1.3em" }} */
/*  */
.IntroMascot_thirdtab__1103i {
    background-position: center;
    /* min-height: 1080px; */
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    /* padding: 180px 0em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.IntroMascot_thirdtab__1103i * {
    color:white;
}

/*  */

.IntroMascot_nav_link_down__1NDFz {
    position: absolute;
    bottom: 10px;
    /* background-color: red; */
}


.IntroMascot_nav_link_down__1NDFz:hover {
    cursor: pointer;
}

/* .nav_link_down > i:hover {
    color: #D32D36;
} */

.IntroMascot_nav_link_up__22610 {
    position: absolute;
    top: 10px;
}

.IntroMascot_nav_link_up__22610:hover {
    cursor: pointer;
}
/* 
.nav_link_up > i:hover {
    color: #D32D36;
} */

.IntroMascot_intro_tap_box__1ZQ-q h1 {
    margin: 0;
    font-size:5em
}

.IntroMascot_intro_tap_box__1ZQ-q .IntroMascot_text_div__l1vtp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* max-width:1200px;
    min-width:600px; */
}

/* @media screen and (max-width: 1200px){
    .intro_third_forth_img{
        width: 300px;
    }

    .secondtab > div > :nth-child(2) > :nth-child(2) > div{
        width: 500px;
    }
    
    .thirdtab > div > :nth-child(1) > :nth-child(2) > div{
        width: 500px;
    }
} */


@media screen and (max-width: 768px){
    .IntroMascot_secondtab__3iijA { height: -webkit-max-content; height: max-content; padding: 180px 0px;}
    .IntroMascot_thirdtab__1103i { height: -webkit-max-content; height: max-content;}
    .IntroMascot_secondtab__3iijA > div > :nth-child(1) { margin-right: 0px; }
    .IntroMascot_thirdtab__1103i > div > :nth-child(1) { margin-right: 0px; }

    .IntroMascot_secondtab__3iijA > div > :nth-child(2) > :nth-child(1) > h1 {
        /* color: red !important; */
        font-size: 3em;
        width: 85vw;
        word-break: break-all;
    }

    .IntroMascot_thirdtab__1103i > div > :nth-child(1) > :nth-child(1) > h1 {
        /* color: red !important; */
        font-size: 3em;
        width: 85vw;
        word-break: break-all;
    }

    .IntroMascot_secondtab__3iijA > div > :nth-child(2) > :nth-child(2) > div{
        color: white;
        width: 100vw;
        font-size: 1.2em;
        line-height: 160%;
    }

    .IntroMascot_secondtab__3iijA > div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 430px;
    }
    
    .IntroMascot_secondtab__3iijA > div > :nth-child(2) {
        margin-top:50px; 
    }

    .IntroMascot_secondtab__3iijA {
        width: 350px;
    }

    .IntroMascot_intro_third_forth_img__2mvtH {
        width: 280px;
    }

    .IntroMascot_intro_third_forth_img2__2JPsK{
        width: 280px;
        margin-bottom: 40px;
    }

    /* .secondtab > div > :nth-child(2) > :nth-child(2) {
        width: 90vw;
    } */

    .IntroMascot_thirdtab__1103i { padding: 120px 0px 30px 0px !important; }
    .IntroMascot_thirdtab__1103i > div > :nth-child(1) > :nth-child(1) {
        width: 400px;
    }

    .IntroMascot_thirdtab__1103i > div > :nth-child(1)  {
        margin-bottom: 180px;
    }

    .IntroMascot_thirdtab__1103i > div > :nth-child(1) > :nth-child(2) > div {
        color: white;
        font-size: 1.2em;
        line-height: 160%;
        /* background-color: red; */
        width: 400px;
    }

    .IntroMascot_thirdtab__1103i> div {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width:90vw;
    }

    .IntroMascot_thirdtab__1103i > div > :nth-child(2) {
        margin-top:50px; 
    }

    .IntroMascot_thirdtab__1103i > .IntroMascot_intro_third_forth_img__2mvtH {
        margin-bottom: 60px;
        width: 350px;
    }


    .IntroMascot_thirdtab__1103i > div > :nth-child(1) > :nth-child(2) {
        width: 460px;
    }

    .IntroMascot_nav_link_down__1NDFz { display: none; }
    .IntroMascot_nav_link_up__22610 { display: none; }
}
.IntroLocation_forthtab__11DGp{
    background-position: center;
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    padding: 180px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.IntroLocation_forthtab__11DGp * {
    color:white;
}

.IntroLocation_forthtab__11DGp> :nth-child(1) > h1 {
    font-size: 56px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: gray 2px 2px 2px;
    margin-bottom: 80px;
}

.IntroLocation_forthtab__11DGp> :nth-child(1) > p {
    color: white;
    font-size: 23px;
    line-height: 160%;
    margin-bottom: 50px;
    word-break: keep-all;
    font-family: "Noto Sans KR";
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
}

.IntroLocation_map_div__DzYDK{
    max-width: 900px;
    width: 100%;
    height: 500px;
    /* box-shadow:#020202 5px 5px 5px; */
    /* border: 1px ridge #020202; */
    border-radius: 2px;
    margin-bottom: 50px;
}

.IntroLocation_bus_car_div__1zlKq{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    width: 90vw;
    /* background-color: red; */
}

.IntroLocation_how_come__3uQLS {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    text-align: center;
}
.IntroLocation_how_come__3uQLS > :nth-child(1){
    padding-top: 50px;
    font-size: 100px;
    margin-right: 20px;
    text-shadow: 2px 2px 2px gray;
}

.IntroLocation_how_come__3uQLS > :nth-child(2) > div{
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    font-weight: bold;
    line-height: 160%;
}

.IntroLocation_how_come__3uQLS > :nth-child(2) > h3{
    font-size: 30px;
    font-family: "Noto Sans KR";
    margin-bottom: 15px;
    font-weight: bold;
    text-shadow: 2px 2px 2px gray;
}

.IntroLocation_vertical_line__1NGTi{
    width: 1px;
    background-color: silver;
    height: 100%;
    float: left;
    border: 2px ridge silver ;
    border-radius: 2px;
  }


@media screen and (max-width: 768px){
    .IntroLocation_forthtab__11DGp> :nth-child(1) > h1 {
        font-size: 50px;
    }
    
    .IntroLocation_forthtab__11DGp> :nth-child(1) > p {
        font-size: 20px;
        text-align: center;
    }

    .IntroLocation_map_div__DzYDK{
        height: 300px;
        width: 80vw;
        /* box-shadow: 1px 1px 1px; */
        margin-bottom: 50px;
    }

    .IntroLocation_bus_car_div__1zlKq{
        flex-direction: column;
        text-align: center;
    }

    .IntroLocation_how_come__3uQLS {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .IntroLocation_how_come__3uQLS > :nth-child(1){
        /* padding-top: 80px; */
        padding-left: 20px;
        font-size: 60px;
        /* margin-right: 100px; */
        margin-bottom: 70px;
        align-items: center;
        justify-content: center;
    }

    .IntroLocation_vertical_line__1NGTi{
        display: none;
    }
}
.Membersignup_modalLabel__2uKaT.Membersignup_modalLabel__2uKaT>span{ color: white; }

.Membersignup_invalid__2EH6Y{
    color: red;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 15px;
}

.Membersignup_valid__1u_RW{
    color: white;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 15px;
}

/* style={{ minHeight: "80vh", width: "800px",backgroundSize: "cover", display: "flex", justifyContent: "center", alignItems: "center"}} */
.Membersignup_login_form_wrap__3PIfc{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
    /* opacity: 0.7; */
    border: 0px;
    box-shadow: 0 0 30px white !important;
    background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
    /* background-color: ; */
    border-radius: 15px;
}


.Membersignup_login_form_div__1-ttl{
    border-radius: 15px;
    padding: 50px;
    /* opacity: 0.7; */
    border: 0px;
    box-shadow: 0 0 30px white !important;
    background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
  }

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes Membersignup_move-twink-back__2qVQi {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes Membersignup_move-twink-back__2qVQi {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes Membersignup_move-clouds-back__1P07c {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes Membersignup_move-clouds-back__1P07c {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.Membersignup_stars__3Xm89, .Membersignup_twinkling__2dhLG, .Membersignup_clouds__3XZmL {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1600px;
  height: 100vh;
  display:block;
}

.Membersignup_stars__3Xm89 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.Membersignup_twinkling__2dhLG{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:Membersignup_move-twink-back__2qVQi 200s linear infinite;
  animation:Membersignup_move-twink-back__2qVQi 200s linear infinite;
}

.Membersignup_clouds__3XZmL{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:Membersignup_move-clouds-back__1P07c 200s linear infinite;
  animation:Membersignup_move-clouds-back__1P07c 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.Membersignup_style_a1__A6ln4 {
    border-radius: 5px;
    background-color: #4B89DC;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membersignup_style_a1__A6ln4:hover {
    background: #4B89DC;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #4B89DC,
                0 0 25px #4B89DC,
                0 0 50px #4B89DC,
                0 0 100px #4B89DC;
  }

  .Membersignup_style_a2__2v0bv {
    border-radius: 5px;
    background-color: #88DC4B;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membersignup_style_a2__2v0bv:hover {
    background: #88DC4B;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #88DC4B,
                0 0 25px #88DC4B,
                0 0 50px #88DC4B,
                0 0 100px #88DC4B;
  }

  .Membersignup_style_a3__2EUGn {

    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membersignup_style_a3__2EUGn:hover {
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }

.Membersignup_style_a__2Rtdw> span {
    position: absolute;
    display: block;
  }

.Membersignup_style_a__2Rtdw >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim1__2_Wk3 1s linear infinite;
            animation: Membersignup_btn-anim1__2_Wk3 1s linear infinite;
  }

  .Membersignup_mouse_hover__1Wl_k:hover{
    cursor: pointer;
  }

  @-webkit-keyframes Membersignup_btn-anim1__2_Wk3 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes Membersignup_btn-anim1__2_Wk3 {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .Membersignup_style_a__2Rtdw > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim2__qkS7f 1s linear infinite;
            animation: Membersignup_btn-anim2__qkS7f 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes Membersignup_btn-anim2__qkS7f {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes Membersignup_btn-anim2__qkS7f {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .Membersignup_style_a__2Rtdw > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim3__31OCi 1s linear infinite;
            animation: Membersignup_btn-anim3__31OCi 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes Membersignup_btn-anim3__31OCi {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes Membersignup_btn-anim3__31OCi {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .Membersignup_style_a__2Rtdw > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: Membersignup_btn-anim4__1_2tJ 1s linear infinite;
            animation: Membersignup_btn-anim4__1_2tJ 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes Membersignup_btn-anim4__1_2tJ {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes Membersignup_btn-anim4__1_2tJ {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */


@media (min-width:500px) and (max-width: 768px) {
    .Membersignup_login_form_wrap__3PIfc {width: 500px;}
    .Membersignup_login_form_div__1-ttl { width: 500px;}
    .Membersignup_profileRow__zr-a6 {
      width: 400px;
      overflow-x: hidden;
    }
}

@media (max-width: 499px) {
  .Membersignup_login_form_wrap__3PIfc {width: 320px;}
  .Membersignup_login_form_div__1-ttl { width: 320px;}
  .Membersignup_profileRow__zr-a6 {
    width: 250px;
    overflow-x: hidden;
  }
}
.MemberSignUpGeral_textPolicy__QtatA.MemberSignUpGeral_textPolicy__QtatA>span{ 
    color: white;
    font-size: 15px;
    padding: 10px;
    margin-top: 10px;
    font-family: "Noto Sans KR";
    
}

.MemberSignUpGeral_login_form_wrap__fJKBf{
    position: relative;
    z-index: 5;
    max-width: 1000px;
    width: 80vw;
    /* background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important; */
}

.MemberSignUpGeral_login_form_div__1aUg5{
    /* padding-bottom: 50px !important; */
    padding: 0px 50px 50px 50px !important;
    border: 0px;
    box-shadow: 0 0 30px white !important;
    /* background: linear-gradient(235deg, #020202, #565656, #020202) !important; */
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
  }

/* ============================ 버튼 이벤트 a태그 */
.MemberSignUpGeral_style_a1__3zq0P {
  border-radius: 5px;
  background-color: #88DC4B;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px
}

.MemberSignUpGeral_style_a1__3zq0P:hover {
    background: #88DC4B;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #88DC4B,
                0 0 25px #88DC4B,
                0 0 50px #88DC4B,
                0 0 100px #88DC4B;
  }

  .MemberSignUpGeral_style_a2__1miaA {
    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 20px;
    letter-spacing: 0px
}

.MemberSignUpGeral_style_a2__1miaA:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e,
              0 0 25px #e02e0e,
              0 0 50px #e02e0e,
              0 0 100px #e02e0e;
  }

.MemberSignUpGeral_style_a__3PP9h > span {
    position: absolute;
    display: block;
  }

.MemberSignUpGeral_style_a__3PP9h >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim1__1E8-g 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim1__1E8-g 1s linear infinite;
  }

  .MemberSignUpGeral_mouse_hover__3PLMh:hover{
    cursor: pointer;
  }

  @-webkit-keyframes MemberSignUpGeral_btn-anim1__1E8-g {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes MemberSignUpGeral_btn-anim1__1E8-g {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .MemberSignUpGeral_style_a__3PP9h > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim2__3vs1e 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim2__3vs1e 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes MemberSignUpGeral_btn-anim2__3vs1e {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes MemberSignUpGeral_btn-anim2__3vs1e {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .MemberSignUpGeral_style_a__3PP9h > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim3__3Mhx1 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim3__3Mhx1 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes MemberSignUpGeral_btn-anim3__3Mhx1 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes MemberSignUpGeral_btn-anim3__3Mhx1 {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .MemberSignUpGeral_style_a__3PP9h > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: MemberSignUpGeral_btn-anim4__3qjdy 1s linear infinite;
            animation: MemberSignUpGeral_btn-anim4__3qjdy 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes MemberSignUpGeral_btn-anim4__3qjdy {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes MemberSignUpGeral_btn-anim4__3qjdy {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes MemberSignUpGeral_move-twink-back__3q9x0 {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes MemberSignUpGeral_move-twink-back__3q9x0 {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes MemberSignUpGeral_move-clouds-back__dfiNn {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes MemberSignUpGeral_move-clouds-back__dfiNn {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.MemberSignUpGeral_stars__N0it7, .MemberSignUpGeral_twinkling__3kuFW, .MemberSignUpGeral_clouds__1RvAC {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1300px;
  display: block;
}

.MemberSignUpGeral_stars__N0it7 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.MemberSignUpGeral_twinkling__3kuFW{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:MemberSignUpGeral_move-twink-back__3q9x0 200s linear infinite;
  animation:MemberSignUpGeral_move-twink-back__3q9x0 200s linear infinite;
}

.MemberSignUpGeral_clouds__1RvAC{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:MemberSignUpGeral_move-clouds-back__dfiNn 200s linear infinite;
  animation:MemberSignUpGeral_move-clouds-back__dfiNn 200s linear infinite;
}

/* ==================== */


@media screen and (max-width: 768px) {
    .MemberSignUpGeral_form_wrap__3uC4k {width: 1000px;}
}
.Membermypage_invalid__VPqVk{
    color: red;
    margin-top: 7px;
    margin-left: 5px;
}

.Membermypage_valid__9Tzr9{
    color: white;
    margin-top: 7px;
    margin-left: 5px;
}


.Membermypage_myPageWrapDiv__1xxWS intro_title_sub_text {font-size:1em}
.Membermypage_myPageWrapDiv__1xxWS .Membermypage_mypage_box__1hKps {
    display: flex;
}

.Membermypage_mypage_box__1hKps {
    background-position: center;
    padding: 80px 0em;
    /* justify-content: center; */
    background-repeat: no-repeat;
    background-size: cover;
}

.Membermypage_mypage_box__1hKps .Membermypage_text_div__3yYiR {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    max-width:1200px;
    min-width:700px;
}

@media screen and (max-width: 768px) {
    .Membermypage_form_wrap__1T5hw {width: 1000px;}
}

.Membermypage_login_form_wrap__2XQbu{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
    /* opacity: 0.7; */
    border: 0px;
    box-shadow: 0 0 30px white !important;
    background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
    /* background-color: ; */
    border-radius: 15px;
    margin-top: 200px;
}

.Membermypage_login_form_wrap__2XQbu * {
  font-size:1em;
  color: #1b1b1b;
  font-family: "Noto Sans KR";
}

.Membermypage_login_form_wrap__2XQbu > .Membermypage_login_form_div__1W33H label {
  text-align: start;
}

.Membermypage_login_form_wrap__2XQbu .Membermypage_modifiy__3Di9h span {
  color: black;
  font-weight: bold;
}

.Membermypage_login_form_wrap__2XQbu .Membermypage_mypage__1B7Ow {
  display:flex;
  align-items: center;
  font-weight: bold;
}

.Membermypage_login_form_wrap__2XQbu .Membermypage_mypage__1B7Ow span {
  color: black;
}

.Membermypage_login_form_wrap__2XQbu .Membermypage_mypage__1B7Ow span:nth-child(2) {
  margin-left:10px;
  background-color: 'red';
}


.Membermypage_login_form_div__1W33H{
    border-radius: 15px;
    padding: 50px;
    /* opacity: 0.7; */
    border: 0px;
   
  }
.Membermypage_login_form_div__1W33H > .Membermypage_profile__2pMZE{
    display:flex;
    align-items: center;
    justify-content: center;
}

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes Membermypage_move-twink-back__2OCMT {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes Membermypage_move-twink-back__2OCMT {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes Membermypage_move-clouds-back__2hBjl {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes Membermypage_move-clouds-back__2hBjl {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.Membermypage_stars__3G78x, .Membermypage_twinkling__Rg4nn, .Membermypage_clouds__2HWkl {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1900px;
  height: 100vh;
  display:block;
}

.Membermypage_stars__3G78x {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.Membermypage_twinkling__Rg4nn{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:Membermypage_move-twink-back__2OCMT 200s linear infinite;
  animation:Membermypage_move-twink-back__2OCMT 200s linear infinite;
}

.Membermypage_clouds__2HWkl{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:Membermypage_move-clouds-back__2hBjl 200s linear infinite;
  animation:Membermypage_move-clouds-back__2hBjl 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.Membermypage_style_a1__1a8uM {
    border-radius: 5px;
    background-color: #4B89DC;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 5px;
    letter-spacing: 0px
}

.Membermypage_style_a1__1a8uM:hover {
    background: #4B89DC;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #4B89DC,
                0 0 25px #4B89DC,
                0 0 50px #4B89DC,
                0 0 100px #4B89DC;
  }

  .Membermypage_style_a2__1TYs5 {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membermypage_style_a2__1TYs5:hover {
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }

  .Membermypage_style_a3__2TBUk {

    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.Membermypage_style_a3__2TBUk:hover {
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }

.Membermypage_style_a__-LIlu> span {
    position: absolute;
    display: block;
  }

.Membermypage_style_a__-LIlu >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim1__2yFpm 1s linear infinite;
            animation: Membermypage_btn-anim1__2yFpm 1s linear infinite;
  }

  .Membermypage_mouse_hover__3PfdG:hover{
    cursor: pointer;
  }

  @-webkit-keyframes Membermypage_btn-anim1__2yFpm {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes Membermypage_btn-anim1__2yFpm {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .Membermypage_style_a__-LIlu > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim2__2zg44 1s linear infinite;
            animation: Membermypage_btn-anim2__2zg44 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes Membermypage_btn-anim2__2zg44 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes Membermypage_btn-anim2__2zg44 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .Membermypage_style_a__-LIlu > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim3__1ajtt 1s linear infinite;
            animation: Membermypage_btn-anim3__1ajtt 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes Membermypage_btn-anim3__1ajtt {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes Membermypage_btn-anim3__1ajtt {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .Membermypage_style_a__-LIlu > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: Membermypage_btn-anim4__1K98X 1s linear infinite;
            animation: Membermypage_btn-anim4__1K98X 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes Membermypage_btn-anim4__1K98X {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes Membermypage_btn-anim4__1K98X {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */


@media (min-width: 500px) and (max-width: 768px) {
    .Membermypage_login_form_wrap__2XQbu {
      width: 500px; 
      overflow-y: hidden; 
      overflow-x: hidden;
    }
    
    .Membermypage_login_form_div__1W33H { 
      width: 500px; 
      overflow-y: hidden; 
      overflow-x: hidden;
    }

    .Membermypage_profileRow__EiF2K {
      width: 400px;
      overflow-x: hidden;
    }
}

@media (max-width: 499px) {
  .Membermypage_login_form_wrap__2XQbu {
    width: 300px; 
    overflow-y: hidden;
  }
  
  .Membermypage_profileRow__EiF2K {
    width: 200px;
  }

  .Membermypage_Text__3kPj8 {
    font-size: 13px;
  }
  

}






* {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans KR";
}
/* header {
    background-color:rgba(33, 33, 33, 0.9);
    color:#ffffff;
    display:block;
    font: 14px/1.3 Arial,sans-serif;
    height:50px;
    position:relative;
    z-index:5;
}
h2{
    margin-top: 30px;
    text-align: center;
}
header h2{
    font-size: 22px;
    margin: 0 auto;
    padding: 10px 0;
    width: 80%;
    text-align: center;
}
header a, a:visited {
    text-decoration:none;
    color:#fcfcfc;
} */

@keyframes login_move-twink-back__2EFS3 {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes login_move-twink-back__2EFS3 {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes login_move-clouds-back__1uG7E {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes login_move-clouds-back__1uG7E {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.login_stars__3t8bm, .login_twinkling__18KLB, .login_clouds__3HZ7D {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.login_stars__3t8bm {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.login_twinkling__18KLB{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:login_move-twink-back__2EFS3 200s linear infinite;
  animation:login_move-twink-back__2EFS3 200s linear infinite;
}

.login_clouds__3HZ7D{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:login_move-clouds-back__1uG7E 200s linear infinite;
  animation:login_move-clouds-back__1uG7E 200s linear infinite;
}


.login_login_form_wrap__2rLOz{
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.login_login_form_div__2OHfp{
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.login_style_a__15p6D {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.login_style_a__15p6D:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.login_style_a__15p6D > span {
    position: absolute;
    display: block;
  }

.login_style_a__15p6D >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim1__3wRzA 1s linear infinite;
            animation: login_btn-anim1__3wRzA 1s linear infinite;
  }

  .login_mouse_hover__3c-lJ:hover{
    cursor: pointer;
  }

  @-webkit-keyframes login_btn-anim1__3wRzA {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes login_btn-anim1__3wRzA {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .login_style_a__15p6D > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim2__2vMI7 1s linear infinite;
            animation: login_btn-anim2__2vMI7 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes login_btn-anim2__2vMI7 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes login_btn-anim2__2vMI7 {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .login_style_a__15p6D > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim3__2WYfJ 1s linear infinite;
            animation: login_btn-anim3__2WYfJ 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes login_btn-anim3__2WYfJ {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes login_btn-anim3__2WYfJ {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .login_style_a__15p6D > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: login_btn-anim4__12pbu 1s linear infinite;
            animation: login_btn-anim4__12pbu 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
 
  @-webkit-keyframes login_btn-anim4__12pbu {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
 
  @keyframes login_btn-anim4__12pbu {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }

  @media (max-width:500px) {
    .login_login_form_div__2OHfp{
      width: 280px;
    }

    .login_style_a__15p6D {
     padding: 5px 10px;
    }
  }
  /* ================================= */
* {
    margin: 0;
    padding: 0;
}

.FindId_label__2onyk.FindId_label__2onyk>span{ color: white; }
/* header {
/* header {
    background-color:rgba(33, 33, 33, 0.9);
    color:#ffffff;
    display:block;
    font: 14px/1.3 Arial,sans-serif;
    height:50px;
    position:relative;
    z-index:5;
}
h2{
    margin-top: 30px;
    text-align: center;
}
header h2{
    font-size: 22px;
    margin: 0 auto;
    padding: 10px 0;
    width: 80%;
    text-align: center;
}
header a, a:visited {
    text-decoration:none;
    color:#fcfcfc;
} */

@keyframes FindId_move-twink-back__3VSIH {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FindId_move-twink-back__3VSIH {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes FindId_move-clouds-back__1HbG3 {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes FindId_move-clouds-back__1HbG3 {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.FindId_stars__1DewZ, .FindId_twinkling__2KlNk, .FindId_clouds__3JZaH {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
}

.FindId_stars__1DewZ {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.FindId_twinkling__2KlNk{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:FindId_move-twink-back__3VSIH 200s linear infinite;
  animation:FindId_move-twink-back__3VSIH 200s linear infinite;
}

.FindId_clouds__3JZaH{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:FindId_move-clouds-back__1HbG3 200s linear infinite;
  animation:FindId_move-clouds-back__1HbG3 200s linear infinite;
}



.FindId_findid_form_wrap__2CgBS{
    position: relative;
    z-index: 5;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR";
}
/* header {
  background-color:rgba(33, 33, 33, 0.9);
  color:#ffffff;
  display:block;
  font: 14px/1.3 Arial,sans-serif;
  height:50px;
  position:relative;
  z-index:5;
}
h2{
  margin-top: 30px;
  text-align: center;
}
header h2{
  font-size: 22px;
  margin: 0 auto;
  padding: 10px 0;
  width: 80%;
  text-align: center;
}
header a, a:visited {
  text-decoration:none;
  color:#fcfcfc;
} */

@keyframes FindId_move-twink-back__3VSIH {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FindId_move-twink-back__3VSIH {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes FindId_move-clouds-back__1HbG3 {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes FindId_move-clouds-back__1HbG3 {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}

.FindId_stars__1DewZ, .FindId_twinkling__2KlNk, .FindId_clouds__3JZaH {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
min-height: 1080px;
display:block;
}

.FindId_stars__1DewZ {
background:#000 url(/images/login/stars.png) repeat top center;
z-index:0;
}

.FindId_twinkling__2KlNk{
background:transparent url(/images/login/twinkling.png) repeat top center;
z-index:1;
-webkit-animation:FindId_move-twink-back__3VSIH 200s linear infinite;
animation:FindId_move-twink-back__3VSIH 200s linear infinite;
}

.FindId_clouds__3JZaH{
  background:transparent url(/images/login/clouds3.png) repeat top center;
  z-index:3;
-webkit-animation:FindId_move-clouds-back__1HbG3 200s linear infinite;
animation:FindId_move-clouds-back__1HbG3 200s linear infinite;
}


.FindId_login_form_wrap__2rH5I{
  position: relative;
  z-index: 5;
}

.FindId_login_form_div__3Hlf3{
/* opacity: 0.7; */
border: 0px;
width: 350px;
box-shadow: 0 0 30px white !important;
border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.FindId_style_a__b43Mx {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px;
  font-family: "Noto Sans KR";
}

.FindId_style_a__b43Mx:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.FindId_style_a__b43Mx > span {
  position: absolute;
  display: block;
}

.FindId_style_a__b43Mx >:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
}

.FindId_mouse_hover__1MTKA:hover{
  cursor: pointer;
}

@-webkit-keyframes FindId_btn-anim1__1JDme {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

@keyframes FindId_btn-anim1__1JDme {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.FindId_style_a__b43Mx > :nth-child(2){
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
}

@-webkit-keyframes FindId_btn-anim2__1DzVu {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

@keyframes FindId_btn-anim2__1DzVu {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.FindId_style_a__b43Mx > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
}

@-webkit-keyframes FindId_btn-anim3__m4GtI {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

@keyframes FindId_btn-anim3__m4GtI {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.FindId_style_a__b43Mx > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
}

@-webkit-keyframes FindId_btn-anim4__3OgV8 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@keyframes FindId_btn-anim4__3OgV8 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@media (max-width:500px) {
  .FindId_login_form_div__3Hlf3{
    width: 280px;
  }
}

/* ================================= */
* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans KR";
}

.FindPassword_label__2BgyW.FindPassword_label__2BgyW>span{ color: white; }
/* header {
  background-color:rgba(33, 33, 33, 0.9);
  color:#ffffff;
  display:block;
  font: 14px/1.3 Arial,sans-serif;
  height:50px;
  position:relative;
  z-index:5;
}
h2{
  margin-top: 30px;
  text-align: center;
}
header h2{
  font-size: 22px;
  margin: 0 auto;
  padding: 10px 0;
  width: 80%;
  text-align: center;
}
header a, a:visited {
  text-decoration:none;
  color:#fcfcfc;
} */

@keyframes FindPassword_move-twink-back__3rF5U {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FindPassword_move-twink-back__3rF5U {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes FindPassword_move-clouds-back__3lsd4 {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes FindPassword_move-clouds-back__3lsd4 {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}

.FindPassword_stars__3ppzR, .FindPassword_twinkling__3sfOb, .FindPassword_clouds__1GRw0 {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
min-height: 1080px;
display:block;
}

.FindPassword_stars__3ppzR {
background:#000 url(/images/login/stars.png) repeat top center;
z-index:0;
}

.FindPassword_twinkling__3sfOb{
background:transparent url(/images/login/twinkling.png) repeat top center;
z-index:1;
-webkit-animation:FindPassword_move-twink-back__3rF5U 200s linear infinite;
animation:FindPassword_move-twink-back__3rF5U 200s linear infinite;
}

.FindPassword_clouds__1GRw0{
  background:transparent url(/images/login/clouds3.png) repeat top center;
  z-index:3;
-webkit-animation:FindPassword_move-clouds-back__3lsd4 200s linear infinite;
animation:FindPassword_move-clouds-back__3lsd4 200s linear infinite;
}


.FindPassword_login_form_wrap__2ksUg{
  position: relative;
  display:'flex';
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.FindPassword_login_form_div__1B8WT{
/* opacity: 0.7; */
border: 0px;
width: 350px;
box-shadow: 0 0 30px white !important;
border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/* ============================ 버튼 이벤트 a태그 */
.FindPassword_style_a__27H7w {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px;
  font-family: "Noto Sans KR";
}

.FindPassword_style_a__27H7w:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.FindPassword_style_a__27H7w > span {
  position: absolute;
  display: block;
}

.FindPassword_style_a__27H7w >:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
 
  
}

.FindPassword_mouse_hover__2IbhE:hover{
  cursor: pointer;
}

@-webkit-keyframes FindPassword_btn-anim1__S4dj0 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

@keyframes FindPassword_btn-anim1__S4dj0 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.FindPassword_style_a__27H7w > :nth-child(2){
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;

}

@-webkit-keyframes FindPassword_btn-anim2__1aVqS {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

@keyframes FindPassword_btn-anim2__1aVqS {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.FindPassword_style_a__27H7w > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
}

@-webkit-keyframes FindPassword_btn-anim3__9cb-J {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

@keyframes FindPassword_btn-anim3__9cb-J {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.FindPassword_style_a__27H7w > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
}

@-webkit-keyframes FindPassword_btn-anim4__4ZqA1 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@keyframes FindPassword_btn-anim4__4ZqA1 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

@media (max-width:500px) {
  .FindPassword_login_form_div__1B8WT{
    width: 280px;
  }

  .FindPassword_optionText__2971D {
   font-size: 11px;
  }
}
/* body {
    width: 100%;
  }
  .intro {
    width: 100%;
    height: 100vh;
    overflow: auto;
    margin: 0px auto;
    position: relative;
  }

  .full_video {
      width:177.vh;
      height:56.25vw;
      overflow-x:hidden;
      min-width:100%;
      min-height:100%;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      position:absolute;
  } */

  .Main_wrap_div__2F12t {
    width: 100vw;
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .Main_style_a__17qXz {
    background-color: rgba(88, 20, 225, 0.7);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    width: 150px;
    height: 50px;
    color: white;
    /* font-size: 16px; */
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    text-align: center;
    padding-top: 16px;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.Main_style_a__17qXz:hover {
  cursor: pointer;
    background: #5814E1;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #5814E1,
                0 0 25px #5814E1,
                0 0 50px #5814E1,
                0 0 150px #5814E1;
  }



  .Main_intro_txt_btn__1x-NK {
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%, -50%);
            transform:translate(-50%, -50%);
    position:absolute;
  }

  .Main_bg__2pDij {
    background-repeat: repeat-x;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 1080px;
  }

  .Main_bg_01__25h6P {
    background-image: url(/images/main/main_bg_02.png);
    background-size: cover;
    flex-direction:column;
  }

  .Main_bg_02__Tn0s5 {
    background-image: url(/images/main/main_bg_03.png);
    background-size: cover;
    flex-direction:column;
  }

  .Main_bg_03__ZbQNv {
    background-image: url(/images/main/main_bg_04.png);
    background-size: cover;
    flex-direction:column;
  }

  .Main_bg_03__ZbQNv > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .Main_bg_03__ZbQNv > :nth-child(1) > h1 {
    color: white;
    font-family: "Noto Sans KR";
    font-size: 52px;
    text-shadow: gray 2px 2px 2px;
    margin-bottom: 30px;
  }
  .Main_bg_03__ZbQNv > :nth-child(1) > p {
    font-family: "Noto Sans KR";
    color: white;
    font-size: 25px;
    text-shadow: gray 2px 2px 2px;
    line-height:180%;
  }

  .Main_bg_03__ZbQNv > :nth-child(2) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 50px;
  }

  .Main_bg_03__ZbQNv > :nth-child(2) > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .Main_bg_03__ZbQNv > :nth-child(2) > div > h1 {
    font-size:150px;
    font-weight: 6000;
    color:#32FAEA;
    padding:0;
    margin:0;
    text-shadow: gray 2px 2px 1px;
  }

  .Main_bg_03__ZbQNv > :nth-child(2) > div > span {
    font-size: 50px;
    font-weight:600;
    color:white;
    padding:0;
    margin-right: 40px;
    text-shadow: gray 2px 2px 1px;
  }

  .Main_bg_03__ZbQNv > :nth-child(3){
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Main_bg_03__ZbQNv > :nth-child(3) > :nth-child(1){
    position: relative;
    margin-right: 50px;
    margin-bottom: 0px;
  }
  .Main_bg_03__ZbQNv > :nth-child(3) > :nth-child(1) > p{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    font-size: 25px;
    font-weight: bold;
    color: #0d35b4;
  }

  .Main_bg_03__ZbQNv > :nth-child(3) > :nth-child(2){
    position: relative;
  }

  .Main_bg_03__ZbQNv > :nth-child(3) > :nth-child(2) > p{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
    font-size: 25px;
    font-weight: bold;
    color: #0d35b4;
  }


  @media screen and (max-width: 768px){
    .Main_bg_03__ZbQNv { padding: 150px 0px; }
    .Main_bg_03__ZbQNv > :nth-child(1) { padding: 0px 50px }
    .Main_bg_03__ZbQNv > :nth-child(2) { flex-direction: column; }
    .Main_bg_03__ZbQNv > :nth-child(2) > div > h1 { font-size: 150px;}
    .Main_bg_03__ZbQNv > :nth-child(2) > div > span {font-size: 30px;}
    .Main_bg_03__ZbQNv > :nth-child(3) { flex-direction: column; }
    .Main_bg_03__ZbQNv > :nth-child(3) > :nth-child(1){ margin-right: 0px; margin-bottom: 50px; margin-top: 50px;}
    /* .bg_03 > :nth-child(3) > :nth-child(2){ } */
    .Main_intro_txt_btn__1x-NK > :nth-child(1) > img { width: 460px; }
  }



.PlayerRankMain_wrap_div__3prBs {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/main/bg_02.jpg);
    background-size: cover;
    flex-direction:column;
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    /* height: 100vh; */
    overflow: hidden;
}

.PlayerRankMain_wrap_div__3prBs > :nth-child(1) > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.015em;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 18px;
}

/* .wrap_div > :nth-child(1) > hr {
    width: 240px;
} */

.PlayerRankMain_all_rank_btn__21aou {
    margin-top: 23px;
    border: 2px solid #CA2373;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #D84C5D);
            filter: drop-shadow(0px 0px 9px #D84C5D);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
}

.PlayerRankMain_all_rank_btn__21aou:hover {
    cursor: pointer;
    background-color: #CA2373;
}


.PlayerRankMain_content_div__3Eyac {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_div__3mrhP {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 270px;
    
}

.PlayerRankMain_rank_div__3mrhP > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_div2__2a9Pk {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.PlayerRankMain_rank_div2__2a9Pk > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_div3__1SYIe {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.PlayerRankMain_rank_div3__1SYIe > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PlayerRankMain_rank_team_border1__3Cb09{
    -webkit-filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
            filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -15%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.PlayerRankMain_logoTeam_img1__2qiGD{
    position: absolute;
    top: -15%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 295px;
    height: 295px;
    z-index: 99;
}

.PlayerRankMain_rank_flash1__20Bkh {
    position: absolute;
    top: -15%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PlayerRankMain_rank_team_border2__b8MWC{
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
    position: absolute;
    top: -16%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.PlayerRankMain_logoTeam_img2__1zqXz{
    position: absolute;
    top: -16%;
    left: 50.2%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
    height: 200px;
    width: 200px;
    z-index: 99;
}

.PlayerRankMain_rank_flash2__2qd4v {
    position: absolute;
    top: -16%;
    left: 50.6%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PlayerRankMain_rank_team_border3__dGmso{
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 0px 3px #33004A), drop-shadow(0px 0px 60px #FF0000); */
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
    position: absolute;
    top: -16%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}
.PlayerRankMain_rank_flash3___EJpU {
    position: absolute;
    top: -16%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PlayerRankMain_player_recored_text__3z9T9 {
    color: white;
    font-family: "Noto Sans KR";
    font-style: normal;
    /* font-weight: bold; */
    font-size: 20px;
    margin: 20px 0px 0px 0px;
    text-align: center;
}
.PlayerRankMain_player_name__3tnw-{
    color: white;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -0.015em;
    margin-bottom: 5px;
}


.PlayerRankMain_player_team__2vKQP {
    color: #5ED8FF;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.015em;
    width: 300px;
    text-align: center;
}

.PlayerRankMain_player_team1__2qygG {
    color: #5ED8FF;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.015em;
    width: 300px;
    text-align: center;
    margin-bottom: 80px !important;
}



.PlayerRankMain_rank_crown__AJjCr {
    width: 25px;
    height: 21px;
    margin-top: 30px;
}



.PlayerRankMain_rank_flag__3kULk {
    -webkit-filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
            filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1500px) {
    .PlayerRankMain_rank_div__3mrhP {
        margin: 0px 50px;
    }
}


@media screen and (max-width: 1000px) {
    .PlayerRankMain_wrap_div__3prBs {
        height: 2100px;
        
    }
    .PlayerRankMain_content_div__3Eyac {
        margin-top: 90px;
        flex-direction: column;
        position: relative;
    }

    .PlayerRankMain_player_team1__2qygG {
        margin-bottom: 0px;
    }

    .PlayerRankMain_rank_div__3mrhP {
        margin-right: 0px;
        position: relative;
        margin: 0px;
        top: -330px;
        left: 0px;
    }
    .PlayerRankMain_rank_div2__2a9Pk {
        /* margin-top: 500px; */
        position: relative;
        top: 530px;
    }
    .PlayerRankMain_rank_div3__1SYIe {
        margin-top: 300px;
        
    }
}

@media screen and (max-width: 768px) {
    .PlayerRankMain_rank_team_border1__3Cb09 { -webkit-filter: none; filter: none; }
    .PlayerRankMain_rank_team_border2__b8MWC { -webkit-filter: none; filter: none; }
    .PlayerRankMain_rank_team_border3__dGmso { -webkit-filter: none; filter: none; }
}
.TeamRankMain_wrap_div__xbCvo {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/images/main/bg_01.jpg);
    background-size: cover;
    flex-direction:column;
    padding: 70px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    /* height: 100vh; */
    overflow: hidden;
}

.TeamRankMain_wrap_div__xbCvo > :nth-child(1) > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.015em;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 18px;
}

/* .wrap_div > :nth-child(1) > hr {
    width: 240px;
} */

.TeamRankMain_all_rank_btn__GiuCw {
    margin-top: 23px;
    border: 2px solid #BD3FDC;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #C44CD8);
            filter: drop-shadow(0px 0px 9px #C44CD8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #FFFFFF;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.TeamRankMain_all_rank_btn__GiuCw:hover {
    cursor: pointer;
    background-color: #BD3FDC;
}


.TeamRankMain_content_div__dFzgZ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_div__2Se5B {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px 270px;
    
}

.TeamRankMain_rank_div__2Se5B > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_div2__1JPjv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.TeamRankMain_rank_div2__1JPjv > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_div3__KcNl2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-right: 70px; */
    margin-top: 0px;
}

.TeamRankMain_rank_div3__KcNl2 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeamRankMain_rank_team_border1__G_tUu{
    -webkit-filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
            filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -19%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.TeamRankMain_logoTeam_img1__3SctR{
    position: absolute;
    top: -19%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 295px;
    height: 295px;
    z-index: 99;
}

.TeamRankMain_rank_flash1__10fNh {
    position: absolute;
    top: -19%;
    left: 50.5%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TeamRankMain_rank_team_border2__2rEgr{
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF5C00);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.TeamRankMain_logoTeam_img2__Rzwm_{
    position: absolute;
    top: -21%;
    left: 50.2%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49% 0%, 94% 25%, 94% 75%, 49% 100%, 5% 75%, 5% 25%);
    height: 200px;
    width: 200px;
    z-index: 99;
}

.TeamRankMain_rank_flash2__2vh7q {
    position: absolute;
    top: -21%;
    left: 50.6%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TeamRankMain_rank_team_border3__Gquj4{
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 0px 3px #33004A), drop-shadow(0px 0px 60px #FF0000); */
    -webkit-filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
            filter: drop-shadow(1px 1px 250px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 20px#33004A) drop-shadow(0px 0px 3px#FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}
.TeamRankMain_rank_flash3__1eyIy {
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TeamRankMain_rank_team_name1__LWNM_{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space: nowrap;
    margin-bottom: 80px !important;
    width: 300px;
}

.TeamRankMain_rank_team_name__NXjr3{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    white-space: nowrap;
    width: 300px;
}

.TeamRankMain_rank_crown__2atrc {
    margin-top: 30px;
}



.TeamRankMain_rank_flag__235j- {
    -webkit-filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
            filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1500px) {
    .TeamRankMain_rank_div__2Se5B {
        margin: 0px 50px;
    }
}



@media screen and (max-width: 1000px) {
    .TeamRankMain_wrap_div__xbCvo {
        height: 1900px;
    }
    
    .TeamRankMain_content_div__dFzgZ {
        margin-top: 50px;
        flex-direction: column;
        position: relative;
    }



    .TeamRankMain_rank_team_name1__LWNM_{
        margin-bottom: 0px;
    }

    .TeamRankMain_rank_div__2Se5B {
        margin-right: 0px;
        position: relative;
        margin: 0px;
        top: -220px;
        left: 0px;
    }
    .TeamRankMain_rank_div2__1JPjv {
        /* margin-top: 500px; */
        position: relative;
        top: 530px;
    }
    .TeamRankMain_rank_div3__KcNl2 {
        margin-top: 400px;
        
    }
}

.MainVideo_content_div__2pPyQ {
  width: 100vw !important;
  height: 100vh;
  background-repeat: repeat-x;
  background-position: center;
  background-size: cover;
  flex-direction:column;
  padding: 180px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* .video_frame {
  pointer-events: none;
} */

.MainVideo_iframe_div__1fpiF {
  padding-top:56.25%;
  position: relative;
}

@media screen and (max-width: 768px){
  .MainVideo_iframe_div__1fpiF {
    padding-top: 490px;
    pointer-events: auto;
  }
}

/*  */



.CompetitionInfo_content_div__bnryi {
    padding: 70px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/images/info_competition/bg_01.jpg);
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

.CompetitionInfo_content_div__bnryi > h1 {
    font-family: "post-no-bills-colombo-extrabold", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(52, 52, 52, 0.25), 0px 0px 250px #ffffff;
}

.CompetitionInfo_content_div__bnryi > hr {
    width: 171px;
}

.CompetitionInfo_dropdown_div__1VYGX {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.CompetitionInfo_dropdown_div__1VYGX > :nth-child(1) {
    margin-right: 10px;
}

.CompetitionInfo_arrow_btn__2_OJo {
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 2px 4px 6px gray;
}

.CompetitionInfo_arrow_btn__2_OJo:hover {
    cursor: pointer;
    color: #ffffff;
}

.CompetitionInfo_competition_carousel__1-rOr {
    max-width: 1200px;
    width: 100vw;
    height: 100vh;
    max-height: 700px;
    margin-top: 80px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_competition_carousel__1-rOr > div > :nth-child(1) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__1-rOr > div > :nth-child(2) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__1-rOr > div > :nth-child(4) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__1-rOr > div > :nth-child(5) {
    opacity: 0.8 !important;
}

.CompetitionInfo_competition_posters__1Nf68 {
    border-radius: 10px;
    box-shadow: 0px 4px 30px gray;
}

.CompetitionInfo_competition_posters__1Nf68:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition-duration: 700ms;
}

.CompetitionInfo_transition_div__O6q9s {
    position: fixed !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 30px;
    border-radius: 15px;
}

.CompetitionInfo_transition_content_div__6L9OC {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_transition_img__3vIqX {
    max-width: 600px;
    width: auto;
    height: 70vh;
    max-height: 900px;
}

.CompetitionInfo_transition_img__3vIqX:hover {
    cursor: pointer;
}

.CompetitionInfo_infoText_div__k-r8u {
    margin: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Noto Sans KR";
    word-break: keep-all;
    text-align: center;
}

.CompetitionInfo_infoText_div__k-r8u > hr {
    width: 100%;
}

.CompetitionInfo_infoText_div__k-r8u > h1 {
    margin: 5px;
    font-weight: bold;
}

.CompetitionInfo_infoText_div__k-r8u > p {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin: 5px;
}

.CompetitionInfo_infoText_div__k-r8u > p > span {
    font-family: "Noto Sans KR";
    text-align: center;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.CompetitionInfo_orange_span__3Bq5- {
    color: #f7941c;
    font-weight: 400;
}

/* .infoText_div > button {
    background: linear-gradient(90deg, #4158CD 4.83%, #A177E7 97.24%), #C4C4C4;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
    outline: none;
    border: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    width: 110px;
    margin-top: 10px;
}

.infoText_div > button:hover{
    cursor: pointer;
} */

.CompetitionInfo_all_rank_btn__2Y6tp {
    margin-top: 23px;
    border: 2px solid #bd3fdc;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #c44cd8);
            filter: drop-shadow(0px 0px 9px #c44cd8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #ffffff;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.CompetitionInfo_all_rank_btn__2Y6tp:hover {
    cursor: pointer;
    background-color: #bd3fdc;
}

@media screen and (max-width: 1200px) {
    .CompetitionInfo_competition_carousel__1-rOr {
        max-width: 800px;
        max-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .CompetitionInfo_content_div__bnryi {
        padding: 100px 0px;
    }
    .CompetitionInfo_dropdown_div__1VYGX {
        flex-direction: column;
    }
    .CompetitionInfo_dropdown_div__1VYGX > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .CompetitionInfo_competition_carousel__1-rOr {
        width: 100%;
        height: 300px;
        margin-bottom: 40px;
    }
    .CompetitionInfo_transition_img__3vIqX {
        height: 40vh;
    }
    .CompetitionInfo_infoText_div__k-r8u {
        width: 80%;
    }
}

.Shop_wrap_div__2Pxp2 {
    background-image: url("/images/main/bg_shop.png");
    width: 100vw;
    display: flex;
    padding: 30px 0px;
    /* height: 200px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-repeat: no-repeat; */
    background-position: center;
    /* background-size: cover; */
    /* overflow-x: hidden; */
}

.Shop_banner_div__BhzbJ {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    flex-wrap: wrap;
}

.Shop_banner_div__BhzbJ > div {
    margin: 10px;
}
.NationalPlayerMain_wrap_div__IEPO3 {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    min-height: 100vh;

    background-position: center;
    width: 100vw;
    overflow: hidden;
}

.NationalPlayerMain_title_female_div__26FIX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
}

.NationalPlayerMain_title_male_div__1h2GH {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NationalPlayerMain_title_female_div__26FIX p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.NationalPlayerMain_title_female_div__26FIX h1 {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}


.NationalPlayerMain_title_male_div__1h2GH p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.NationalPlayerMain_title_male_div__1h2GH h1 {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.NationalPlayerMain_content_div__2qtEo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    width: 1320px;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 90px;
    gap: 90px;
    /* background-color: red !important; */
}


.NationalPlayerMain_profile_div__2iUpd {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.NationalPlayerMain_profile_img_div__1n4eF img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.NationalPlayerMain_profile_text_div__3d2c5 {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.NationalPlayerMain_profile_text_div__3d2c5 p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(1)> :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(1)> :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(2) {
    margin-bottom: 10px;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(2)> :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.NationalPlayerMain_profile_text_div__3d2c5> :nth-child(3)> :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
    .NationalPlayerMain_content_div__2qtEo {
        width: 740px;
    }
}

@media screen and (max-width: 738px) {

    /* .content_div {flex-direction: column; width: 370px;} */
    .NationalPlayerMain_content_div__2qtEo {
        flex-direction: column;
        width: 100vw;
    }
}

.NationalPlayerMain_card_wrap__2VJmH {
    width: 380px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.NationalPlayerMain_card_content__2-GSe {
    padding: 30px 0px 30px 33px;
    background-color: #0E1B37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
}

.NationalPlayerMain_card_player_name_text__1aHcP {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: white;
    font-family: Play;
}

.NationalPlayerMain_card_description__37DJC {
    font-size: 14px;
    line-height: 16px;
    color: white;
    font-family: Play;
}

.NationalPlayerMain_image_wrap__3V3Qj {
    width: 220px;
    height: 270px;
    position: relative;
}
.SquadManager_wrap_div__1_7TE * {
    font-family: "Noto Sans KR";
}

.SquadManager_wrap_div__1_7TE {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.SquadManager_wrap_div__1_7TE > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 100px; */
    margin-bottom: 80px;
}

.SquadManager_wrap_div__1_7TE > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.SquadManager_wrap_div__1_7TE > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    text-align: center;
}

/* .wrap_div > :nth-child(2) {
    margin-bottom: 50px;
    width: 250px;
    font-size: 20px
} */

.SquadManager_wrap_div__1_7TE > :nth-child(2) {
    color: white;
    font-size: 20px;
    font-family: "Noto Sans KR";

    /* text-align: right; */

}

.SquadManager_wrap_div__1_7TE > :nth-child(2) > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.SquadManager_wrap_div__1_7TE > :nth-child(2) > :nth-child(1) > :nth-child(1) {
    margin-bottom: 30px;
    font-size: 15px;
}

.SquadManager_wrap_div__1_7TE > :nth-child(2) > :nth-child(1) > :nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 5px;  
}

.SquadManager_wrap_div__1_7TE > :nth-child(2) > :nth-child(1) > :nth-child(2) > *{
    background-color: transparent;
    border-color: white;
    color: white;
    font-size: 15px;
    width: -webkit-max-content;
    width: max-content;
}


.SquadManager_squad_table__1yF7M{
    max-width: 800px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.SquadManager_squad_table__1yF7M > thead > tr {
    height: 40px ;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.SquadManager_squad_table__1yF7M > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}


.SquadManager_squad_table__1yF7M > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.SquadManager_squad_table__1yF7M > tbody > tr:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}


.SquadManager_squad_table__1yF7M > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.SquadManager_squad_table__1yF7M > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.SquadManager_squad_table__1yF7M > tbody > :nth-last-child(1) > td {
    border-bottom: 0px
}

.SquadManager_team_modal__2JSLV{
    position: relative;
    -webkit-transform: translate( 22, 22 );
            transform: translate( 22, 22 );
    z-index: 50;
}


@media screen and (max-width: 768px){
    /* .wrap_div > :nth-child(1) > p { font-size: 18px; } */
    
    /* .wrap_div > :nth-child(1) > h1 { font-size: 50px; } */

    .SquadManager_squad_table__1yF7M > thead > tr > th:first-child {
        width:18%
    }
    
    .SquadManager_squad_table__1yF7M > thead > tr > :nth-child(2) {
        width:56.5%
    }
    
    .SquadManager_squad_table__1yF7M > thead > tr > :nth-child(3) {
        width:25.5%
    }

    .SquadManager_wrap_div__1_7TE > :nth-child(2) { font-size: 15px; }

    .SquadManager_wrap_div__1_7TE > :nth-child(2) > :nth-child(1) > :nth-child(1) > *{ font-size: 13px; width: 100px; }

    .SquadManager_squad_table__1yF7M > thead > tr { height: 40px ; font-size: 15px;}

    .SquadManager_squad_table__1yF7M > tbody > tr { height: 40px; font-size: 13px; }
}

/* #5814e1 -> 테이블 헤더쪽 색상 */
.NationalPlayer_wrap_div__iRnkg {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    /* height: 100vh; */
    /* height: 100%; */
    /* min-height: 600px; */
    min-height: 100vh;
}

.NationalPlayer_title_female_div__37N8W {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
}

.NationalPlayer_title_male_div__1Opcm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NationalPlayer_title_female_div__37N8W p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.NationalPlayer_title_female_div__37N8W h1 {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}


.NationalPlayer_title_male_div__1Opcm p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.NationalPlayer_title_male_div__1Opcm h1 {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.NationalPlayer_content_div__1bTmb {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    width: 1320px;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 90px;
    gap: 90px;
    /* background-color: red !important; */
}


.NationalPlayer_profile_div__2J36S {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.NationalPlayer_profile_img_div__15xCe img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
}

.NationalPlayer_profile_text_div__1iYOO {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.NationalPlayer_profile_text_div__1iYOO p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(1) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(1) > :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(2) {
    margin-bottom: 10px;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(2) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.NationalPlayer_profile_text_div__1iYOO > :nth-child(3) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px){
    .NationalPlayer_content_div__1bTmb {width: 740px;}
}

@media screen and (max-width: 738px){
    /* .content_div {flex-direction: column; width: 370px;} */
    .NationalPlayer_content_div__1bTmb {flex-direction: column; width: 100vw;}
}

.NationalPlayer_card_wrap__LfExu {
    width: 380px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.NationalPlayer_card_content__2sRrG {
    padding: 30px 0px 30px 33px ;
    background-color: #0E1B37;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 160px;
}

.NationalPlayer_card_player_name_text__2fh6W {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: white;
    font-family: Play;
}

.NationalPlayer_card_description__25j8x {
    font-size: 14px;
    line-height: 16px;
    color: white;
    font-family: Play;
}

.NationalPlayer_image_wrap__1o1fE {
    width: 220px;
    height: 270px;
    position: relative;
}
.RegionalPlayer_wrap_div__3ysHn {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    /* height: 100vh; */
    /* min-height: 600px; */
    min-height: 100vh;
}

.RegionalPlayer_title_div__3VvSr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
}

.RegionalPlayer_title_div__3VvSr p {
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 18px;
    margin-bottom: 5px;
}

.RegionalPlayer_title_div__3VvSr h1{
    color: white;
    font-family: "Noto Sans KR";
    text-shadow: 2px 2px 2px gray;
    font-size: 56px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
}

.RegionalPlayer_content_div__3n1Nj {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80vw;
    max-width: 1200px;
    flex-wrap: wrap;
}

.RegionalPlayer_region_drop__3I5QW {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: white !important;
    color: white !important;
    font-size: 15px;
    width: 180px !important;
    margin-bottom: 40px;
}

.RegionalPlayer_profile_div__E7XSh {
    margin: 10px;
    width: 350px;
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.3);
    /* border-radius: 15px; */
}


.RegionalPlayer_profile_img_div__uFUSQ img {
    width: 155px;
    height: 198px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate( -50%, -50% );
            transform: translate( -50%, -50% );
}

.RegionalPlayer_profile_text_div__3_Xww {
    width: 160px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    border: 0px;
    border-radius: 10px;
    padding: 5px;
}

.RegionalPlayer_profile_text_div__3_Xww p {
    margin: 0px;
    font-family: "Noto Sans KR";
    font-size: 15px;
    font-style: oblique;
    color: white;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(1) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 23px;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(1) > :nth-child(2) {
    color: #61ece1;
    font-size: 12px;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(2) {
    margin-bottom: 10px;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(2) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

.RegionalPlayer_profile_text_div__3_Xww > :nth-child(3) > :nth-child(1) {
    font-weight: bold;
    color: #61ece1;
    font-size: 18px;
}

@media screen and (max-width: 1200px){
    .RegionalPlayer_content_div__3n1Nj {width: 740px;}
}

@media screen and (max-width: 738px){
    .RegionalPlayer_content_div__3n1Nj {flex-direction: column; width: 370px;}
}
.PrivacyPolicy_textPolicy__rY69s.PrivacyPolicy_textPolicy__rY69s>span{ 
    color: white;
    font-size: 15px;
    padding: 10px;
    margin-top: 10px;
    font-family: "Noto Sans KR";
    
}

.PrivacyPolicy_wrap_div__3BpZx {
  min-height: 1300px;
  height: 100vh;
}

.PrivacyPolicy_login_form_wrap__QrezG{
    position: relative;
    z-index: 5;
    max-width: 1000px;
    width: 80vw;
    /* background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important; */
}

.PrivacyPolicy_login_form_div__1mC7c{
    /* padding-bottom: 50px !important; */
    padding: 0px 50px 50px 50px !important;
    border: 0px;
    box-shadow: 0 0 30px white !important;
    /* background: linear-gradient(235deg, #020202, #565656, #020202) !important; */
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
  }

/* ============================ 버튼 이벤트 a태그 */
.PrivacyPolicy_style_a1__1yswn {
  border-radius: 5px;
  background-color: #88DC4B;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 0px
}

.PrivacyPolicy_style_a1__1yswn:hover {
    background: #88DC4B;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #88DC4B,
                0 0 25px #88DC4B,
                0 0 50px #88DC4B,
                0 0 100px #88DC4B;
  }

  .PrivacyPolicy_style_a2__k22K9 {
    background-color: #e02e0e;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px
}

.PrivacyPolicy_style_a2__k22K9:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e,
              0 0 25px #e02e0e,
              0 0 50px #e02e0e,
              0 0 100px #e02e0e;
  }

.PrivacyPolicy_style_a__3Xv-Z > span {
    position: absolute;
    display: block;
  }

.PrivacyPolicy_style_a__3Xv-Z >:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim1__1wWck 1s linear infinite;
            animation: PrivacyPolicy_btn-anim1__1wWck 1s linear infinite;
  }

  .PrivacyPolicy_mouse_hover__37kXd:hover{
    cursor: pointer;
  }

  @-webkit-keyframes PrivacyPolicy_btn-anim1__1wWck {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  @keyframes PrivacyPolicy_btn-anim1__1wWck {
    0% {
      left: -100%;
    }
    50%,100% {
      left: 100%;
    }
  }

  .PrivacyPolicy_style_a__3Xv-Z > :nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim2__30DyJ 1s linear infinite;
            animation: PrivacyPolicy_btn-anim2__30DyJ 1s linear infinite;
    -webkit-animation-delay: .25s;
            animation-delay: .25s
  }
  
  @-webkit-keyframes PrivacyPolicy_btn-anim2__30DyJ {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  @keyframes PrivacyPolicy_btn-anim2__30DyJ {
    0% {
      top: -100%;
    }
    50%,100% {
      top: 100%;
    }
  }
  
  .PrivacyPolicy_style_a__3Xv-Z > :nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim3__1--jr 1s linear infinite;
            animation: PrivacyPolicy_btn-anim3__1--jr 1s linear infinite;
    -webkit-animation-delay: .5s;
            animation-delay: .5s
  }
  
  @-webkit-keyframes PrivacyPolicy_btn-anim3__1--jr {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  @keyframes PrivacyPolicy_btn-anim3__1--jr {
    0% {
      right: -100%;
    }
    50%,100% {
      right: 100%;
    }
  }
  
  .PrivacyPolicy_style_a__3Xv-Z > :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    -webkit-animation: PrivacyPolicy_btn-anim4__fz4q1 1s linear infinite;
            animation: PrivacyPolicy_btn-anim4__fz4q1 1s linear infinite;
    -webkit-animation-delay: .75s;
            animation-delay: .75s
  }
  
  @-webkit-keyframes PrivacyPolicy_btn-anim4__fz4q1 {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  
  @keyframes PrivacyPolicy_btn-anim4__fz4q1 {
    0% {
      bottom: -100%;
    }
    50%,100% {
      bottom: 100%;
    }
  }
  /* ================================= */

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes PrivacyPolicy_move-twink-back__3PIUo {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes PrivacyPolicy_move-twink-back__3PIUo {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes PrivacyPolicy_move-clouds-back__GbZgo {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes PrivacyPolicy_move-clouds-back__GbZgo {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.PrivacyPolicy_stars__1ZmaY, .PrivacyPolicy_twinkling__33csY, .PrivacyPolicy_clouds__2WKcn {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  min-height: 1300px;
  display: block;
}

.PrivacyPolicy_stars__1ZmaY {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.PrivacyPolicy_twinkling__33csY{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:PrivacyPolicy_move-twink-back__3PIUo 200s linear infinite;
  animation:PrivacyPolicy_move-twink-back__3PIUo 200s linear infinite;
}

.PrivacyPolicy_clouds__2WKcn{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:PrivacyPolicy_move-clouds-back__GbZgo 200s linear infinite;
  animation:PrivacyPolicy_move-clouds-back__GbZgo 200s linear infinite;
}

/* ==================== */


@media screen and (max-width: 768px) {
    .PrivacyPolicy_wrap_div__3BpZx { height: 1300px; }
    .PrivacyPolicy_form_wrap__EUE8L {width: 1000px;}
    .PrivacyPolicy_login_form_div__1mC7c {
      font-size: 9pt;
      text-align: center;
    }
}
.competiton_application_competition__2_16M.competiton_application_competition__2_16M > span {
  color: white;
}

@media screen and (max-width: 768px) {
  .competiton_application_competition_form_wrap__g0-jF {
    max-width: 300px;
  }
}

.competiton_application_login_form_wrap__11IJ6 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
  /* background-color: ; */
  border-radius: 15px;
}

.competiton_application_login_form_div__3Biv8 {
  border-radius: 15px;
  padding: 50px;
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
}

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes competiton_application_move-twink-back__2kDVS {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes competiton_application_move-twink-back__2kDVS {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes competiton_application_move-clouds-back__2VF_u {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-webkit-keyframes competiton_application_move-clouds-back__2VF_u {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.competiton_application_stars__96hHD,
.competiton_application_twinkling__1NSTZ,
.competiton_application_clouds__3sGkh {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 1500px;
  height: 100%;
  display: block;
}

.competiton_application_stars__96hHD {
  background: #000 url(/images/login/stars.png) repeat top center;
  z-index: 0;
}

.competiton_application_twinkling__1NSTZ {
  background: transparent url(/images/login/twinkling.png) repeat top center;
  z-index: 1;
  -webkit-animation: competiton_application_move-twink-back__2kDVS 200s linear infinite;
  animation: competiton_application_move-twink-back__2kDVS 200s linear infinite;
}

.competiton_application_clouds__3sGkh {
  background: transparent url(/images/login/clouds3.png) repeat top center;
  z-index: 3;
  -webkit-animation: competiton_application_move-clouds-back__2VF_u 200s linear infinite;
  animation: competiton_application_move-clouds-back__2VF_u 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.competiton_application_style_a1__1oBr8 {
  border-radius: 5px;
  background-color: #4b89dc;
  position: relative;
  display: inline-block;
  padding: 7px 10px;
  color: white;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.competiton_application_style_a1__1oBr8:hover {
  background: #4b89dc;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #4b89dc, 0 0 25px #4b89dc, 0 0 50px #4b89dc,
    0 0 100px #4b89dc;
}

.competiton_application_style_a2__3x7YC {
  border-radius: 5px;
  background-color: #88dc4b;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.competiton_application_style_a2__3x7YC:hover {
  background: #88dc4b;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #88dc4b, 0 0 25px #88dc4b, 0 0 50px #88dc4b,
    0 0 100px #88dc4b;
}

.competiton_application_style_a3__1Z3Fl {
  background-color: #e02e0e;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.competiton_application_style_a3__1Z3Fl:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e, 0 0 25px #e02e0e, 0 0 50px #e02e0e,
    0 0 100px #e02e0e;
}

.competiton_application_style_a__1LLnV > span {
  position: absolute;
  display: block;
}

.competiton_application_style_a__1LLnV > :nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim1__3LN1e 1s linear infinite;
          animation: competiton_application_btn-anim1__3LN1e 1s linear infinite;
}

.competiton_application_mouse_hover__34p51:hover {
  cursor: pointer;
}

@-webkit-keyframes competiton_application_btn-anim1__3LN1e {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

@keyframes competiton_application_btn-anim1__3LN1e {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.competiton_application_style_a__1LLnV > :nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim2__1S5MN 1s linear infinite;
          animation: competiton_application_btn-anim2__1S5MN 1s linear infinite;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

@-webkit-keyframes competiton_application_btn-anim2__1S5MN {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

@keyframes competiton_application_btn-anim2__1S5MN {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.competiton_application_style_a__1LLnV > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim3__gvQ4p 1s linear infinite;
          animation: competiton_application_btn-anim3__gvQ4p 1s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes competiton_application_btn-anim3__gvQ4p {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

@keyframes competiton_application_btn-anim3__gvQ4p {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.competiton_application_style_a__1LLnV > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  -webkit-animation: competiton_application_btn-anim4__3vmBS 1s linear infinite;
          animation: competiton_application_btn-anim4__3vmBS 1s linear infinite;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

@-webkit-keyframes competiton_application_btn-anim4__3vmBS {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@keyframes competiton_application_btn-anim4__3vmBS {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
/* ================================= */

@media screen and (max-width: 768px) {
  .competiton_application_login_form_wrap__11IJ6 {
    width: 500px;
  }
  .competiton_application_login_form_div__3Biv8 {
    width: 500px;
  }
}

.LeagueTab_item_div__3i7BN {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.LeagueTab_item_div__3i7BN:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.LeagueTab_item_div__3i7BN:first-child {
    border-radius: 8px 0 0 8px;
}

.LeagueTab_item_div__3i7BN:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .LeagueTab_item_div__3i7BN {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .LeagueTab_item_div__3i7BN {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
 
}


.CompetitionInfo_competition_div__1hEKG * {
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_div__1hEKG {
    font-family: "Noto Sans KR";
    background-image: url("/images/info_squad/squad_bg_01.jpg");
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.CompetitionInfo_competition_div__1hEKG > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 50px 0px; */
}

.CompetitionInfo_competition_div__1hEKG > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.CompetitionInfo_competition_div__1hEKG > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    color: white;
    text-shadow: gray 2px 2px 2px;
}

.CompetitionInfo_competition_div__1hEKG > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_dropdown__3224X {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 50px;
}

.CompetitionInfo_competition_dropdown__3224X > :nth-child(1) > :nth-child(1) {
    margin-right: 10px;
}

/* .competition_dropdown > * { */
.CompetitionInfo_competition_dropdown__3224X > div > * {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
    text-align: center !important;
    /* font-size: 20px; */
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
}

.CompetitionInfo_league_tab__Q9qjK {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;
}

.CompetitionInfo_matchType_tab__1hhEZ {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;
}

.CompetitionInfo_competition_table__3ZmAo {
    max-width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_table__3ZmAo > thead > tr {
    height: 40px;
    font-size: 18px;
    justify-content: center;
    color: white;
}

.CompetitionInfo_competition_table__3ZmAo > thead > tr > th {
    background-color: #5814e1;
    font-size: 15px;
    justify-content: center;
    width: 200px;
}

.CompetitionInfo_competition_table__3ZmAo > tbody {
    background-image: url("/images/info_squad/squad_table_bg.png");
    color: white;
}

.CompetitionInfo_competition_table__3ZmAo > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.CompetitionInfo_competition_table__3ZmAo > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.CompetitionInfo_competition_table__3ZmAo > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
}

.CompetitionInfo_table_wrap__1cNTe {
    display: block;
    width: 100%;
    overflow-x: scroll;
}

@media (max-width: 499px) {
    .CompetitionInfo_table_wrap__1cNTe {
        width: 300px;
    }

    .CompetitionInfo_table_wrap__1cNTe::-webkit-scrollbar-thumb {
        background-color: white;
    }

    .CompetitionInfo_competition_table__3ZmAo {
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
    }

    .CompetitionInfo_competition_div__1hEKG > :nth-child(2) {
        font-size: 15px;
    }

    .CompetitionInfo_competition_table__3ZmAo > thead > tr > th {
        font-size: 9pt;
    }

    .CompetitionInfo_competition_table__3ZmAo > tbody > tr {
        font-size: 9pt;
    }
}

/* @media (min-width:500px) and (max-width: 768px){ */
@media screen and (max-width: 768px) {
    .CompetitionInfo_competition_div__1hEKG > :nth-child(2) {
        font-size: 15px;
    }

    .CompetitionInfo_competition_table__3ZmAo > thead > tr > th {
        font-size: 9pt;
    }

    .CompetitionInfo_competition_table__3ZmAo > tbody > tr {
        height: 40px;
        font-size: 9pt;
    }

    .CompetitionInfo_competition_dropdown__3224X > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .CompetitionInfo_competition_dropdown__3224X > :nth-child(1) > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    /* .competition_dropdown > * { */
    .CompetitionInfo_competition_dropdown__3224X > div > * {
        background-color: transparent !important;
        border-color: white !important;
        color: white !important;
        width: 300px !important;
    }
}

.CompetitionInfo_redText__3Op-l {
    color: red;
}

.CompetitionInfo_blueText__3EbDM {
    color: blue;
}
/* #5814e1 -> 테이블 헤더쪽 색상 */

.MainModal_style_a__9mX14 {
    background-color: rgba(50, 250, 83, 0.8);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    /* width: fit-content; */
    height: 50px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 0px;
    text-align: center;
    font-weight: bold;
    font-family: "Noto Sans KR";
}

.MainModal_style_a__9mX14:hover {
  cursor: pointer;
    background: #32FA53;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #32FA53,
                0 0 25px #32FA53,
                0 0 50px #32FA53,
                0 0 150px #32FA53;
  }

  .MainModal_style_a1__eqd4X {
    background-color: rgba(82, 82, 82, 0.8);
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 20px;
    /* width: 180px; */
    height: 50px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    letter-spacing: 0px;
    text-align: center;
    font-weight: bold;
    font-family: "Noto Sans KR";
}
.MainModal_style_a1__eqd4X:hover {
    cursor: pointer;
      background: #525252;
      color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 5px #525252,
                  0 0 25px #525252,
                  0 0 50px #525252,
                  0 0 150px #525252;
    }

.MainModal_mouse_hover__1Uf2u {
    cursor: pointer;
}


@media (min-width:554px) and (max-width:768px){
  .MainModal_style_a__9mX14 {padding:10px; font-size: 9pt; height: 40px;}
  .MainModal_style_a1__eqd4X {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}


@media (min-width:321px) and (max-width:553px){
  .MainModal_style_a__9mX14 {padding: 10px; font-size: 9pt; height: 40px;}
  .MainModal_style_a1__eqd4X {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}

@media (max-width:320px){
  .MainModal_style_a__9mX14 {padding: 10px; font-size: 9pt; height: 40px;}
  .MainModal_style_a1__eqd4X {margin-top: 10px; padding: 10px; font-size: 9pt; height: 40px;}
}
.MatchType_item_div__1sJYz {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.MatchType_item_div__1sJYz:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.MatchType_item_div__1sJYz:first-child {
    border-radius: 8px 0 0 8px;
}

.MatchType_item_div__1sJYz:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .MatchType_item_div__1sJYz {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 13px;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .MatchType_item_div__1sJYz {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 13px;
        border-right:1px solid #c0c0c0;
    }
 
}
.CompetitionInfo_content_div__37LWt {
    padding: 100px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/images/info_competition/bg_02.jpg);
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

.CompetitionInfo_content_div__37LWt > h1 {
    font-family: "post-no-bills-colombo-extrabold", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(52, 52, 52, 0.25), 0px 0px 250px #ffffff;
}

.CompetitionInfo_content_div__37LWt > hr {
    width: 171px;
}

.CompetitionInfo_dropdown_div__3-TZC {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.CompetitionInfo_dropdown_div__3-TZC > :nth-child(1) {
    margin-right: 10px;
}

.CompetitionInfo_arrow_btn__3emyT {
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 2px 4px 6px gray;
}

.CompetitionInfo_arrow_btn__3emyT:hover {
    cursor: pointer;
    color: #ffffff;
}

.CompetitionInfo_competition_carousel__7GylI {
    max-width: 1200px;
    width: 100vw;
    height: 100vh;
    max-height: 700px;
    margin-top: 80px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_competition_carousel__7GylI > div > :nth-child(1) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__7GylI > div > :nth-child(2) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__7GylI > div > :nth-child(4) {
    opacity: 0.8 !important;
}
.CompetitionInfo_competition_carousel__7GylI > div > :nth-child(5) {
    opacity: 0.8 !important;
}

.CompetitionInfo_competition_posters__1mSIq {
    border-radius: 10px;
    box-shadow: 0px 4px 30px gray;
}

.CompetitionInfo_competition_posters__1mSIq:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition-duration: 700ms;
}

.CompetitionInfo_transition_div__25XX3 {
    position: fixed !important;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 30px;
    border-radius: 15px;
}

.CompetitionInfo_transition_content_div__2ejVR {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfo_transition_img__1Csxl {
    max-width: 600px;
    width: auto;
    height: 70vh;
    max-height: 900px;
}

.CompetitionInfo_transition_img__1Csxl:hover {
    cursor: pointer;
}

.CompetitionInfo_infoText_div__1ln4X {
    margin: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Noto Sans KR";
    word-break: keep-all;
    text-align: center;
}

.CompetitionInfo_infoText_div__1ln4X > hr {
    width: 100%;
}

.CompetitionInfo_infoText_div__1ln4X > h1 {
    margin: 5px;
    font-weight: bold;
}

.CompetitionInfo_infoText_div__1ln4X > p {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin: 5px;
}

.CompetitionInfo_infoText_div__1ln4X > p > span {
    font-family: "Noto Sans KR";
    text-align: center;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.CompetitionInfo_orange_span__1Zqki {
    color: #f7941c;
    font-weight: 400;
}

/* .infoText_div > button {
    background: linear-gradient(90deg, #4158CD 4.83%, #A177E7 97.24%), #C4C4C4;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
    outline: none;
    border: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    width: 110px;
    margin-top: 10px;
}

.infoText_div > button:hover{
    cursor: pointer;
} */

.CompetitionInfo_all_rank_btn__1m1j- {
    margin-top: 23px;
    border: 2px solid #bd3fdc;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0px 0px 9px #c44cd8);
            filter: drop-shadow(0px 0px 9px #c44cd8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #ffffff;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.CompetitionInfo_all_rank_btn__1m1j-:hover {
    cursor: pointer;
    background-color: #bd3fdc;
}

@media screen and (max-width: 1200px) {
    .CompetitionInfo_competition_carousel__7GylI {
        max-width: 800px;
        max-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .CompetitionInfo_content_div__37LWt {
        padding: 100px 0px;
    }
    .CompetitionInfo_dropdown_div__3-TZC {
        flex-direction: column;
    }
    .CompetitionInfo_dropdown_div__3-TZC > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .CompetitionInfo_competition_carousel__7GylI {
        width: 100%;
        height: 300px;
        margin-bottom: 40px;
    }
    .CompetitionInfo_transition_img__1Csxl {
        height: 40vh;
    }
    .CompetitionInfo_infoText_div__1ln4X {
        width: 80%;
    }
}

.CompetitionInfoLeague_wrap_div__3iOCT {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    /* min-height: 100vh; */
    height: 100%;
    color: #FFFFFF;
}

.CompetitionInfoLeague_wrap_div__3iOCT > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    /* height: 67px; */
    padding: 10px 0px;
    color: #FFFFFF;
    margin-bottom: 190px;
    width: 100vw;
}

.CompetitionInfoLeague_league1_title__2GpTr {
    background: linear-gradient(89.98deg, rgba(255,255,255,0) 25%, rgba(227, 109, 0, 0.546875) 50%, rgba(255, 255, 255, 0) 75%);
}
.CompetitionInfoLeague_league2_title__3LDEk {
    background: linear-gradient(89.98deg, rgba(255,255,255,0) 25%, rgba(0, 132, 227, 0.546875) 46.54%, rgba(255, 255, 255, 0) 75%);
}
.CompetitionInfoLeague_league3_title__33lSV {
    background: linear-gradient(89.98deg,  rgba(255,255,255,0) 25%, rgba(182, 0, 227, 0.546875) 46.54%, rgba(255, 255, 255, 0) 75%);
}

.CompetitionInfoLeague_content_div__3i5eV {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.CompetitionInfoLeague_rank_div__1wOi0 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}

.CompetitionInfoLeague_rank_div__1wOi0 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfoLeague_rank_div2__2xbrK {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
}

.CompetitionInfoLeague_rank_div2__2xbrK > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompetitionInfoLeague_rank_div3__AK8F_ {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-top: 0px;
}

.CompetitionInfoLeague_rank_div3__AK8F_ > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.CompetitionInfoLeague_rank_div4__3z6Z8 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    margin-top: 0px;
}

.CompetitionInfoLeague_rank_div4__3z6Z8 > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.CompetitionInfoLeague_rank_team_name__1yUIe{
    margin-top: 25px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    width: 200px;
}

.CompetitionInfoLeague_rank_team_reward__3v-oZ{
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #F7941C;
}

.CompetitionInfoLeague_rank_team_border1__yfGlk{
    -webkit-filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
            filter: drop-shadow(1px 1px 250px #AD00FF) drop-shadow(0px 4px 20px rgba(255, 0, 199, 0.8)) drop-shadow(0px 0px 3px #33004A);
    position: absolute;
    top: -34%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.CompetitionInfoLeague_logoTeam_img1__2Fk00{
    position: absolute;
    top: -34%;
    left: 50.4%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 164px;
    height: 164px;
    z-index: 99;
}



.CompetitionInfoLeague_rank_team_border2__94SgH{
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF5C00);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF5C00);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}

.CompetitionInfoLeague_logoTeam_img2__3IhH_{
    position: absolute;
    top: -21%;
    left: 50.4%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(50% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
    width: 114px;
    height: 114px;
    /* height: 113px; */
    z-index: 99;
}

.CompetitionInfoLeague_rank_team_border3__1KZHf{
    -webkit-filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
            filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}


.CompetitionInfoLeague_rank_team_border4__2uZFX{
    -webkit-filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
            filter: drop-shadow(0px 0px 3px #33004A) drop-shadow(1px 1px 60px #FF0000);
    position: absolute;
    top: -21%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 90;
}



.CompetitionInfoLeague_rank_flag__tOCPG {
    -webkit-filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
            filter: drop-shadow(0px 2px 50px rgba(0, 163, 255, 0.8));
}

@media screen and (max-width: 1000px){
    .CompetitionInfoLeague_wrap_div__3iOCT {
        height: 100%;
    }
    .CompetitionInfoLeague_content_div__3i5eV {
        flex-direction: column;
    }
    .CompetitionInfoLeague_rank_div__1wOi0 {
        margin-right: 0px;
        margin: 100px 0px;
        position: relative;
        top: -340px;
    }

    .CompetitionInfoLeague_rank_div2__2xbrK {
        margin-right: 0px;
        margin: 100px 0px;
        position: relative;
        top: 390px;
    }

    .CompetitionInfoLeague_rank_div3__AK8F_{
        margin: 45px 0px;
    }
    .CompetitionInfoLeague_rank_div4__3z6Z8 {
        margin-top: 100px;
        margin-bottom: 85px;
    }
}
.TournamentBracketForResult_bracketTable__29rYE {
    margin-top: 100px;
    margin-bottom: 150px;
    border: 0;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-left: 25px;
}

.TournamentBracketForResult_matchWinner__2OnCJ {
    background: #272f36;
    color: #FF8C00;
    box-sizing: border-box;
    font-weight: 600;
    line-height: 0.7rem;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
 }

.TournamentBracketForResult_matchWinner__2OnCJ span {
    vertical-align: middle;
    font-size: 0.9rem;
    font-family: "Noto Sans KR";
}
  
.TournamentBracketForResult_matchWinner__2OnCJ span:last-child {
    padding-left: 10px;
    padding-right: 5px;
}

.TournamentBracketForResult_matchLosser__BlWzv {
    background: #182026;
    color: #6b798c;
    box-sizing: border-box;
    font-weight: 700;
    line-height: 0.7rem;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
}

.TournamentBracketForResult_matchLosser__BlWzv span {
    vertical-align: middle;
    font-size: 0.9rem;
    font-family: "Noto Sans KR";
}
  
.TournamentBracketForResult_matchLosser__BlWzv span:last-child {
    padding-left: 12px;
    padding-right: 5px;
}

.TournamentBracketForResult_teamLog__2DTQf {
    width: 23px;
    height: 23px;
    vertical-align: middle;
    border-radius: 50%;
}

.TournamentBracketForResult_borderTop__11q6W {
    border-top:1px solid #6b798c
}

.TournamentBracketForResult_borderTopLeft__2kghb {
    border-top:1px solid #6b798c;
    border-left:1px solid #6b798c;
}

.TournamentBracketForResult_borderTopRight__2gHJp {
    border-top:1px solid #6b798c;
    border-right:1px solid #6b798c;
}

.TournamentBracketForResult_borderLeftTop__3G8nx {
    border-left:1px solid #6b798c;
    border-top:1px solid #6b798c;
}

.TournamentBracketForResult_borderRight__1zGfX {
    border-right:1px solid #6b798c;
}

.TournamentBracketForResult_borderLeft__2L6yc {
    border-left:1px solid #6b798c;
}


@media screen and (max-width: 768px){
    .TournamentBracketForResult_teamLog__2DTQf {
        width: 15px;
        height: 15px;
        vertical-align: middle;
    }

    .TournamentBracketForResult_matchWinner__2OnCJ {
        background: #272f36;
        color: #FF8C00;
        box-sizing: border-box;
        font-weight: 700;
        line-height: 0.7rem;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
     }
    
    .TournamentBracketForResult_matchWinner__2OnCJ span {
        vertical-align: middle;
        font-size: 0.6rem;
        font-family: "Noto Sans KR";
    }
      
    .TournamentBracketForResult_matchWinner__2OnCJ span:last-child {
        padding-left: 2px;
        padding-right: 2px;
    }
    
    .TournamentBracketForResult_matchLosser__BlWzv {
        background: #182026;
        color: #6b798c;
        box-sizing: border-box;
        font-weight: 700;
        line-height: 0.6rem;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
     }
    
    .TournamentBracketForResult_matchLosser__BlWzv span {
        vertical-align: middle;
        font-size: 0.7rem;
        font-family: "Noto Sans KR";
    }
      
    .TournamentBracketForResult_matchLosser__BlWzv span:last-child {
        padding-left: 2px;
        padding-right: 2px;
    }

    .TournamentBracketForResult_bracketTable__29rYE {
        border: 0;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        padding-left: 1px;
        display: none;
    }
  }
.AuthLevelTab_item_div__1iyII {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.AuthLevelTab_item_div__1iyII:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.AuthLevelTab_item_div__1iyII:first-child {
    border-radius: 8px 0 0 8px;
}

.AuthLevelTab_item_div__1iyII:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .AuthLevelTab_item_div__1iyII {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .AuthLevelTab_item_div__1iyII {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right:1px solid #c0c0c0;
    }
 
}
.RankPlayer_rank_div__2gzQ3 * {
    font-family: "Noto Sans KR";
    font-size: 12pt;
}

.RankPlayer_rank_div__2gzQ3 {
    font-family: "Noto Sans KR";
    background-image: url("/images/rank/rank_bg_01.jpg");
    min-height: 1080px;
    height: 100vh;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.RankPlayer_rank_div__2gzQ3 > .RankPlayer_rank_wrap__3GdWl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RankPlayer_rank_div__2gzQ3 > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.RankPlayer_rank_div__2gzQ3 > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 40px;
    font-family: "Noto Sans KR";
    color: white;
    text-shadow: gray 2px 2px 2px;
}

/* Rectangle 9516 */
.RankPlayer_rank_div__2gzQ3 > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.RankPlayer_rank_div__2gzQ3 > .RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.RankPlayer_radioButton__39Fzx {
    display: flex;
    background-image: url("/images/rank/level_tab_bg.png");
    align-items: center;
    width: 150px;
    height: 41px;
    font-size: 15px;
    border-radius: 20px;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
}

.RankPlayer_radioButton__39Fzx:first-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankPlayer_radioButton__39Fzx:first-child > :nth-child(2) {
    margin-right: auto;
}

.RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(2) {
    margin-right: auto;
}

.RankPlayer_radioButton__39Fzx:last-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankPlayer_radioButton__39Fzx:last-child > :nth-child(2) {
    margin-right: auto;
}

.RankPlayer_rank_table__iXi-_ {
    width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    border-collapse: separate;
    border-spacing: 0 20px;
}

.RankPlayer_rank_table__iXi-_ > thead > tr {
    height: 40px;
    font-size: 20px;
    color: white;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > th {
    background-color: rgba(20, 22, 30, 0.8);
    width: 160px;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 4%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5.5%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5.5%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 12.5%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 31.5%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(7) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 15.5%;
}

.RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(8) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 15.5%;
}

.RankPlayer_trophyRow__24z56 {
    width: 4%;
}

.RankPlayer_rank__2U0bk {
    font-size: 35px;
    width: 5.5%;
}

.RankPlayer_rankImgRow__1tFGn {
    font-size: 18px;
    width: 5.5%;
}

.RankPlayer_ProfileImgRow__1P3L0 {
    font-size: 18px;
    width: 10%;
}

.RankPlayer_ProfileName__zu88e {
    font-size: 18px;
    padding-left: 7px;
    width: 12.5%;
}

.RankPlayer_TeamRow__3Ow7G {
    font-size: 18px;
    width: 31.5%;
    padding-left: 11px;
}

.RankPlayer_LevelRow__36WUt {
    font-size: 18px;
    width: 15.5%;
    padding-left: 14px;
}

.RankPlayer_record__2QJln {
    font-size: 18px;
    width: 15.5%;
    padding-left: 14px;
}

.RankPlayer_rank_table__iXi-_ > tbody {
    color: white;
}

.RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn {
    background-image: url("/images/rank/rank_back_01.png");
    background-size: 100% 100%;
    max-width: 1600px;
    width: 100%;
    height: 85px;
    background-repeat: no-repeat;
}

.RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn:hover {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    background-image: url("/images/rank/rank_back_over.png");
    cursor: pointer;
}

.RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn:hover :nth-child(2) {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    color: #ff2358;
}

.RankPlayer_rank_table__iXi-_ > tbody > tr > td > .RankPlayer_bodyTable___HF_g {
    height: 500px;
    overflow-y: scroll;
}

.RankPlayer_rank_table__iXi-_ > tbody > tr > td > .RankPlayer_bodyTable___HF_g::-webkit-scrollbar-thumb {
    background-color: white;
}

.RankPlayer_record__2QJln {
    width: 20%;
}

.RankPlayer_rankUp__947zr {
    width: 15px;
    height: 11px;
}

.RankPlayer_rankSame__3rm7z {
    width: 16px;
    height: 8px;
}

.RankPlayer_rankDown__3YKnV {
    width: 15px;
    height: 11px;
}

.RankPlayer_rankRecord__1y1pD {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff2358;
    border-radius: 11px;
    width: 100px;
    height: 45px;
    font-size: 15px;
    background-size: cover;
    position: relative;
    margin: auto;
    text-align: center;
}

.RankPlayer_profile__SfThm {
    width: 44px;
    height: 44px;
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.RankPlayer_trophy__1FZYF {
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.RankPlayer_table_wrap__1na0K {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 499px) {
    .RankPlayer_table_wrap__1na0K {
        widows: 100vw;
        overflow-x: auto;
    }

    .RankPlayer_rank_div__2gzQ3 > * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__iXi-_ {
        max-width: 700px;
        /* width: 100%; */
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        overflow-x: auto;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .RankPlayer_rank_table__iXi-_ > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
    }

    .RankPlayer_rank_table__iXi-_ > tbody {
        color: white;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn > td:first-child {
        padding-left: 5px;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 5%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(2) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 9%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(3) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 9%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(4) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(5) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 12.5%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(6) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 23.5%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(7) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15.5%;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > :nth-child(8) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15.5%;
    }

    .RankPlayer_trophyRow__24z56 {
        width: 5%;
    }

    .RankPlayer_rank__2U0bk {
        font-size: 15px;
        width: 9%;
    }

    .RankPlayer_rankImgRow__1tFGn {
        font-size: 13px;
        width: 9%;
    }

    .RankPlayer_ProfileImgRow__1P3L0 {
        font-size: 13px;
        padding-left: 5px;
        width: 10%;
    }

    .RankPlayer_ProfileName__zu88e {
        font-size: 13px;
        padding-left: 10px;
        width: 12.5%;
    }

    .RankPlayer_TeamRow__3Ow7G {
        font-size: 13px;
        width: 23.5%;
        padding-left: 10px;
    }

    .RankPlayer_LevelRow__36WUt {
        font-size: 13px;
        width: 15.5%;
        padding-left: 16px;
    }

    .RankPlayer_record__2QJln {
        font-size: 13px;
        width: 15.5%;
        padding-left: 13px;
    }

    .RankPlayer_rankUp__947zr {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankSame__3rm7z {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankDown__3YKnV {
        width: 8px;
        height: 5px;
    }

    .RankPlayer_rankRecord__1y1pD {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 50px;
        height: 25px;
        font-size: 7px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankPlayer_profile__SfThm {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankPlayer_trophy__1FZYF {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankPlayer_radioButton__39Fzx {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 90px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankPlayer_radioButton__39Fzx:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__39Fzx:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }

    .RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 5px;
    }

    .RankPlayer_radioButton__39Fzx:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__39Fzx:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    .RankPlayer_rank_div__2gzQ3 * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__iXi-_ {
        max-width: 500px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .RankPlayer_rank_table__iXi-_ > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankPlayer_rank_table__iXi-_ > tbody {
        color: white;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn > td:first-child {
        padding-left: 5px;
    }

    .RankPlayer_rank__2U0bk {
        font-size: 1em;
    }

    .RankPlayer_rankUp__947zr {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankSame__3rm7z {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankDown__3YKnV {
        width: 8px;
        height: 5px;
    }

    .RankPlayer_rankRecord__1y1pD {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 50px;
        height: 25px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankPlayer_profile__SfThm {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankPlayer_trophy__1FZYF {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankPlayer_radioButton__39Fzx {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 90px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankPlayer_radioButton__39Fzx:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__39Fzx:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }

    .RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 5px;
    }

    .RankPlayer_radioButton__39Fzx:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__39Fzx:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .RankPlayer_rank_div__2gzQ3 * {
        font-family: "Noto Sans KR";
        font-size: 10pt;
    }

    .RankPlayer_rank_table__iXi-_ {
        max-width: 1000px;
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankPlayer_rank_table__iXi-_ > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15px;
        height: 5px;
    }
    .RankPlayer_rank_table__iXi-_ > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankPlayer_rank_table__iXi-_ > tbody {
        color: white;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 70px;
        background-size: 100% 100%;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankPlayer_rank_table__iXi-_ > tbody .RankPlayer_tableRow__VE0nn > td:first-child {
        padding-left: 20px;
    }

    .RankPlayer_rank__2U0bk {
        font-size: 1em;
    }

    .RankPlayer_rankUp__947zr {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankSame__3rm7z {
        width: 10px;
        height: 5px;
    }

    .RankPlayer_rankDown__3YKnV {
        width: 8px;
        height: 5px;
    }

    .RankPlayer_rankRecord__1y1pD {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 40px;
        height: 30px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankPlayer_profile__SfThm {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankPlayer_trophy__1FZYF {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankPlayer_radioButton__39Fzx {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankPlayer_radioButton__39Fzx:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__39Fzx:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }

    .RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_rank_div__2gzQ3 > div.RankPlayer_rank_wrap__3GdWl .RankPlayer_levelWrap__2PJNR > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 5px;
    }

    .RankPlayer_radioButton__39Fzx:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankPlayer_radioButton__39Fzx:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 20px;
    }
}

.RankTeam_rank_div__2bC2P * {
    font-family: "Noto Sans KR";
    font-size: 12pt;
}

.RankTeam_rank_div__2bC2P {
    font-family: "Noto Sans KR";
    background-image: url("/images/rank/rank_bg_01.jpg");
    min-height: 1200px;
    height: 100vh;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.RankTeam_rank_div__2bC2P > .RankTeam_rank_wrap__3AI2U {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RankTeam_rank_div__2bC2P > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    font-family: "Noto Sans KR";
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.RankTeam_rank_div__2bC2P > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 40px;
    font-family: "Noto Sans KR";
    color: white;
    text-shadow: gray 2px 2px 2px;
}

.RankTeam_rank_div__2bC2P > :nth-child(2) {
    color: white;
    font-size: 15px;
    justify-content: space-between;
    font-family: "Noto Sans KR";
}

.RankTeam_rank_div__2bC2P > .RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: center;
    margin-bottom: 40px;
}

.RankTeam_radioButton__kdjyb {
    display: flex;
    background-image: url("/images/rank/level_tab_bg.png");
    align-items: center;
    width: 200px;
    height: 41px;
    font-size: 15px;
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 10px;
}

.RankTeam_radioButton__kdjyb:first-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankTeam_radioButton__kdjyb:first-child > :nth-child(2) {
    margin-right: auto;
}

.RankTeam_rank_div__2bC2P > .RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankTeam_rank_div__2bC2P > .RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(2) {
    margin-right: auto;
}

.RankTeam_radioButton__kdjyb:last-child > :nth-child(1) {
    margin-right: auto;
    margin-left: 10px;
}

.RankTeam_radioButton__kdjyb:last-child > :nth-child(2) {
    margin-right: auto;
}

.RankTeam_rank_table__2_WW2 {
    width: 1600px;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
    border-collapse: separate;
    border-spacing: 0 20px;
}

.RankTeam_rank_table__2_WW2 > thead > tr {
    height: 40px !important;
    color: white;
}

.RankTeam_rank_table__2_WW2 > thead > tr > th {
    background-color: rgba(20, 22, 30, 0.8);
    width: 160px;
}

.RankTeam_rank_table__2_WW2 > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 8%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 51%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 20%;
}

.RankTeam_rank_table__2_WW2 > thead > tr {
    height: 80px;
    color: white;
}

.RankTeam_rank_table__2_WW2 > tbody {
    color: white;
}

.RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 {
    background-image: url("/images/rank/rank_back_01.png");
    background-size: 100% 100%;
    max-width: 1600px;
    width: 100%;
    height: 85px;
    background-repeat: no-repeat;
    overflow-x: "auto";
}

.RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9:hover {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    background-image: url("/images/rank/rank_back_over.png");
    cursor: pointer;
}

.RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9:hover :nth-child(2) {
    /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
    box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
    color: #ff2358;
}

.RankTeam_rank_table__2_WW2 > tbody > tr > td > .RankTeam_bodyTable__3fdKC {
    height: 500px;
    overflow-y: scroll;
}

.RankTeam_rank_table__2_WW2 > tbody > tr > td > .RankTeam_bodyTable__3fdKC::-webkit-scrollbar-thumb {
    background-color: white;
}

.RankTeam_rank_table__2_WW2 > thead > tr > th:first-child {
    background-color: rgba(20, 22, 30, 0.8);
    width: 5%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(2) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(3) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(4) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 10%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(5) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 45%;
}

.RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(6) {
    background-color: rgba(20, 22, 30, 0.8);
    width: 20%;
}

.RankTeam_trophyRow__3nwIo {
    width: 5%;
}

.RankTeam_rank__1Qa8o {
    font-size: 35px;
    width: 10%;
}

.RankTeam_rankImgRow__1wZRK {
    font-size: 18px;
    width: 10%;
}

.RankTeam_teamLogRow__2_ghI {
    font-size: 18px;
    width: 10%;
    padding-left: 5px;
}

.RankTeam_teamText__35-Xw {
    font-size: 18px;
    width: 45%;
    padding-left: 5px;
}

.RankTeam_record__2trwQ {
    font-size: 18px;
    width: 20%;
    padding-left: 15px;
}

.RankTeam_rankUp__2fR-0 {
    width: 15px;
    height: 11px;
}

.RankTeam_moreBg__dj4Gf {
    width: 100px;
    height: 100px;
}

.RankTeam_rankSame__2Eqr1 {
    width: 16px;
    height: 8px;
}

.RankTeam_rankDown__33YPo {
    width: 15px;
    height: 11px;
}

.RankTeam_rankRecord__38q9N {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff2358;
    border-radius: 11px;
    width: 122px;
    height: 45px;
    font-size: 15px;
    background-size: cover;
    position: relative;
    margin: auto;
    text-align: center;
}

.RankTeam_teamLog__1Nixl {
    width: 44px;
    height: 44px;
    -webkit-clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
            clip-path: polygon(49.5% 0%, 94% 25%, 94% 75%, 50% 100%, 5% 75%, 5% 25%);
}

.RankTeam_trophy__35TdQ {
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.RankTeam_competition_table__1Au-0 > tbody > :nth-last-child(1) > td {
    border-bottom: 0px;
    text-align: center;
}

.RankTeam_table_wrap__1OT_6 {
    /* display: block; */
    display: flex;
    /* width: 90vw; */
    max-width: 1600px;
    width: 90vw;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* background-color: yellow; */
}

.RankTeam_competition_dropdown__KTeYs {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    margin-bottom: 50px;
}

.RankTeam_competition_dropdown__KTeYs > div > * {
    background-color: transparent !important;
    border-color: white !important;
    color: white !important;
    text-align: center !important;
    /* font-size: 20px; */
    max-width: -webkit-max-content !important;
    max-width: max-content !important;
}

@media (max-width: 499px) {
    .RankTeam_table_wrap__1OT_6 {
        width: 300px;
        overflow-x: "scroll";
    }

    .RankTeam_rank_div__2bC2P * {
        font-family: "Noto Sans KR";
        font-size: 9pt;
    }

    .RankTeam_rank_table__2_WW2 {
        width: 500px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        height: 5px;
    }
    .RankTeam_rank_table__2_WW2 > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankTeam_rank_table__2_WW2 > tbody {
        color: white;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
        overflow-x: "auto";
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 > td:first-child {
        padding-left: 5px;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 5%;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(2) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(3) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10%;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(4) {
        background-color: rgba(20, 22, 30, 0.8);
        padding-left: 12px;
        width: 10%;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(5) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 45%;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > :nth-child(6) {
        background-color: rgba(20, 22, 30, 0.8);
        width: 20%;
    }

    .RankTeam_trophyRow__3nwIo {
        width: 5%;
    }

    .RankTeam_rank__1Qa8o {
        font-size: 15px;
        padding-right: 16px;
        width: 10%;
    }

    .RankTeam_rankImgRow__1wZRK {
        font-size: 13px;
        padding-right: 5px;
        width: 10%;
    }

    .RankTeam_teamLogRow__2_ghI {
        font-size: 13px;
        width: 10%;
    }

    .RankTeam_teamText__35-Xw {
        font-size: 13px;
        padding-left: 5px;
        width: 45%;
    }

    .RankTeam_record__2trwQ {
        font-size: 13px;
        width: 20%;
        padding-left: 27px;
        padding-right: 13px;
    }

    .RankTeam_rankUp__2fR-0 {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankSame__2Eqr1 {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankDown__33YPo {
        width: 8px;
        height: 5px;
    }

    .RankTeam_rankRecord__38q9N {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 27px;
        height: 21px;
        font-size: 7px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankTeam_teamLog__1Nixl {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankTeam_trophy__35TdQ {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankTeam_radioButton__kdjyb {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankTeam_radioButton__kdjyb:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__kdjyb:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .RankTeam_rank_div__2bC2P > div.RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_rank_div__2bC2P > div.RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 22.5px;
    }

    .RankTeam_radioButton__kdjyb:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__kdjyb:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .RankTeam_rank_div__2bC2P * {
        font-family: "Noto Sans KR";
        font-size: 8pt;
    }

    .RankTeam_rank_table__2_WW2 {
        /* max-width: 500px; */
        /* width: 90vw; */
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
        /* background-color: red; */
    }

    .RankTeam_rank_table__2_WW2 > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 10px;
        height: 5px;
    }
    .RankTeam_rank_table__2_WW2 > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankTeam_rank_table__2_WW2 > tbody {
        color: white;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 > td:first-child {
        padding-left: 10px;
    }

    .RankTeam_rank__1Qa8o {
        font-size: 1em;
    }

    .RankTeam_rankUp__2fR-0 {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankSame__2Eqr1 {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankDown__33YPo {
        width: 8px;
        height: 5px;
    }

    .RankTeam_rankRecord__38q9N {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 30px;
        height: 25px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankTeam_teamLog__1Nixl {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankTeam_trophy__35TdQ {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankTeam_radioButton__kdjyb {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankTeam_radioButton__kdjyb:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__kdjyb:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .RankTeam_rank_div__2bC2P > div.RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_rank_div__2bC2P > div.RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 22.5px;
    }

    .RankTeam_radioButton__kdjyb:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__kdjyb:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .RankTeam_rank_div__2bC2P * {
        font-family: "Noto Sans KR";
        font-size: 10pt;
    }

    .RankTeam_rank_table__2_WW2 {
        max-width: 1000px;
        width: 900px;
        text-align: center;
        border-spacing: 0px;
        font-family: "Noto Sans KR";
        border-collapse: separate;
        border-spacing: 0 20px;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr {
        height: 80px;
        font-size: 10px;
        color: white;
    }

    .RankTeam_rank_table__2_WW2 > thead > tr > th:first-child {
        background-color: rgba(20, 22, 30, 0.8);
        width: 15px;
        height: 5px;
    }
    .RankTeam_rank_table__2_WW2 > thead > tr > th {
        background-color: rgba(20, 22, 30, 0.8);
        width: 160px;
    }

    .RankTeam_rank_table__2_WW2 > tbody {
        color: white;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 {
        background-image: url("/images/rank/rank_back_01.png");
        font-size: 15px;
        height: 60px;
        background-size: 100% 100%;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9:hover {
        /* background: linear-gradient(225deg, rgba(255, 184, 201, 0.05) -2.7%, rgba(255, 0, 61, 0.05) 48.78%, rgba(255, 184, 201, 0.05) 98.16%);
        box-shadow: 0px 0px 20px #FF2358, 0px 0px 15px #FF2358; */
        background-image: url("/images/rank/rank_back_over.png");
        cursor: pointer;
        color: #ff2358;
    }

    .RankTeam_rank_table__2_WW2 > tbody .RankTeam_tableRow__3Pky9 > td:first-child {
        padding-left: 20px;
    }

    .RankTeam_rank__1Qa8o {
        font-size: 1em;
    }

    .RankTeam_rankUp__2fR-0 {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankSame__2Eqr1 {
        width: 10px;
        height: 5px;
    }

    .RankTeam_rankDown__33YPo {
        width: 8px;
        height: 5px;
    }

    .RankTeam_rankRecord__38q9N {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2358;
        border-radius: 11px;
        width: 40px;
        height: 30px;
        font-size: 10px;
        background-size: cover;
        position: relative;
        margin: auto;
        text-align: center;
    }

    .RankTeam_teamLog__1Nixl {
        border-radius: 50%;
        margin-top: 10px;
        width: 25px;
        height: 25px;
    }

    .RankTeam_trophy__35TdQ {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .RankTeam_radioButton__kdjyb {
        display: flex;
        background-image: url("/images/rank/level_tab_bg.png");
        align-items: center;
        width: 120px;
        height: 30px;
        font-size: 10px;
        border-radius: 20px;
        padding: 10px;
        text-align: center;
        color: white;
    }

    .RankTeam_radioButton__kdjyb:first-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__kdjyb:first-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 10px;
    }

    .RankTeam_rank_div__2bC2P > div.RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_rank_div__2bC2P > div.RankTeam_rank_wrap__3AI2U .RankTeam_rankTypeWrap__21A_d > :nth-child(2) > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 32.5px;
    }

    .RankTeam_radioButton__kdjyb:last-child > :nth-child(1) {
        margin-right: auto;
        margin-left: 2px;
    }

    .RankTeam_radioButton__kdjyb:last-child > :nth-child(2) {
        margin-left: auto;
        padding-left: 2px;
        margin-right: 11.25px;
    }
}

.RankTypeTab_item_div__2qrCG {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.RankTypeTab_item_div__2qrCG:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.RankTypeTab_item_div__2qrCG:first-child {
    border-radius: 8px 0 0 8px;
}

.RankTypeTab_item_div__2qrCG:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}
.LeagueTab_item_div__189k6 {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size: 1.1em;
    border-right: 1px solid #c0c0c0;
}

.LeagueTab_item_div__189k6:hover {
    cursor: pointer;
    background-color: #5814e1;
    color: white;
}

.LeagueTab_item_div__189k6:first-child {
    border-radius: 8px 0 0 8px;
}

.LeagueTab_item_div__189k6:last-child {
    border-radius: 0 8px 8px 0;
    border: 0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .LeagueTab_item_div__189k6 {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right: 1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .LeagueTab_item_div__189k6 {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 9pt;
        border-right: 1px solid #c0c0c0;
    }
}

.RankYouthTeam_intro_tap_box__xhMlT {
    background-position: center;
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #090832; */
}
.ScoreDisplay_wrap_div__T63gC {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px;
    overflow-y: hidden;
}

.ScoreDisplay_wrap_div__T63gC > p {
    font-family: "Noto Sans KR";
    color: white;
    font-weight: bold;
}

.ScoreDisplay_wrap_div__T63gC > :nth-child(1){
    font-size: 3em;
    margin: 0px;
}

.ScoreDisplay_wrap_div__T63gC > :nth-child(2){
    font-size: 40em;
    margin: 0px;
    padding: 0px;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.ScoreInput_wrap_div__2S3QW {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding-top: 150px; */
    overflow-y: hidden;
}

.ScoreInput_wrap_div__2S3QW > h1 {
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 300px;
    color: white;
}

.ScoreInput_wrap_div__2S3QW > :nth-child(2) {
    font-size: 80px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding-top: 7px;
}

.ScoreInput_wrap_div__2S3QW > :nth-child(3) {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding-top: 3px;
}

.ScoreInput_wrap_div__2S3QW > p:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
}
/* div, p, span {font-size:1em} */
.List_wrap_div__OopCO {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__OopCO * {
    color: white;
}

.List_title_div__1FouP {
    margin-bottom: 80px;
}

.List_title_div__1FouP h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__hDYAm {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__hDYAm div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__Xyqqf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__Xyqqf > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__Xyqqf > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__Xyqqf > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__Xyqqf:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3D5AY {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3D5AY:hover {
    cursor: pointer;
}

.List_board_pagination__3lzRQ {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__3lzRQ > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__3lzRQ > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__3lzRQ > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__1hR0e {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3QxQh {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__tmb9H {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__tmb9H:hover {
    cursor: pointer;
}

.List_scroll_hidden__U_wpA::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__U_wpA {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__OopCO { padding: 100px 0px; }
    .List_table_content_line__Xyqqf { width: 100%; }
}
.Content_wrap_div__2qlqb {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__2qlqb *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__2qlqb > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__GkTRY {
    margin-bottom: 80px;
}

.Content_title_div__GkTRY h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1qAFU {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__FDAz6 {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__2R1kf {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__2R1kf:hover {
    cursor: pointer;
}

.Content_submit_btn__3sfqc:hover {
    cursor: pointer;
}

.Content_img_preview__2p3LL {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__NiBXw iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__NiBXw * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__2qlqb {
        padding: 100px 0px;
    }
    .Content_content_div__1qAFU {
        width: 100%;
    }

    .Content_board_content_div__FDAz6 {
        padding: 10px;
    }

    .Content_video_iframe__NiBXw iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.ModalConfirm_wrap_div__2nsML * {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}
.Writer_wrap_div__2gR4v {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__2gR4v *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__19M7M {
    margin-bottom: 80px;
}

.Writer_title_div__19M7M h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__145mM {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__2jNTH {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__2jNTH:hover {
    cursor: pointer;
}

.Writer_del_btn__2bTV7 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__2bTV7:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__1dGKI  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__2gR4v { padding: 100px 0px }
    .Writer_content_div__145mM { width: 100vw; }
}
.Modify_wrap_div__1H1eM {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__1H1eM *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__38h_f {
    margin-bottom: 80px;
}

.Modify_title_div__38h_f > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__Q5zNe {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__1Pyia {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__1Pyia:hover {
    cursor: pointer;
}

.Modify_del_btn__kr8Pz {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__kr8Pz:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__1H1eM { padding: 100px 0px; }
    .Modify_content_div__Q5zNe {
        padding: 10px;
    }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__1HQUX {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__1HQUX * {
    color: white;
}

.List_title_div__3vRKd {
    margin-bottom: 80px;
}

.List_title_div__3vRKd h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__GThQN {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__GThQN div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__1xb78 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__1xb78 > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__1xb78 > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__1xb78 > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__1xb78:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3hcD5 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3hcD5:hover {
    cursor: pointer;
}

.List_board_pagination__1HwG3 {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__1HwG3 > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__1HwG3 > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__1HwG3 > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__wXQf8 {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__1e1uz {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2raz9 {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2raz9:hover {
    cursor: pointer;
}

.List_scroll_hidden__25Fx0::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__25Fx0 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__1HQUX { padding: 100px 0px; }
    .List_table_content_line__1xb78 { width: 100%; }
}
.Content_wrap_div__4_1WG {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__4_1WG *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__4_1WG > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__1aZ93 {
    margin-bottom: 80px;
}

.Content_title_div__1aZ93 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3mySO {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__UKWiR {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1tojx {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1tojx:hover {
    cursor: pointer;
}

.Content_submit_btn__3X_GB:hover {
    cursor: pointer;
}

.Content_img_preview__2phrz {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__8fVCE iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__8fVCE * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__4_1WG {
        padding: 100px 0px;
    }
    .Content_content_div__3mySO {
        width: 100%;
    }

    .Content_board_content_div__UKWiR {
        padding: 10px;
    }

    .Content_video_iframe__8fVCE iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Writer_wrap_div__31MlK {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__31MlK *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__W2GZv {
    margin-bottom: 80px;
}

.Writer_title_div__W2GZv h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__3e-IY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__8mJ6s {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__8mJ6s:hover {
    cursor: pointer;
}

.Writer_del_btn__p8ytq {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__p8ytq:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__2mJUJ  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__31MlK { padding: 100px 0px }
    .Writer_content_div__3e-IY { width: 100vw; }
}
.Modify_wrap_div__3xLul {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3xLul *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__NdBRL {
    margin-bottom: 80px;
}

.Modify_title_div__NdBRL > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__11w-X {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__zYh-m {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__zYh-m:hover {
    cursor: pointer;
}

.Modify_del_btn__2HUPg {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__2HUPg:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3xLul { padding: 100px 0px; }
    .Modify_content_div__11w-X {
        padding: 10px;
    }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__1VM-B {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__1VM-B * {
    color: white;
}

.List_title_div__3FBHD {
    margin-bottom: 80px;
}

.List_title_div__3FBHD h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__xfnHa {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__xfnHa div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__1y92- {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__1y92- > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__1y92- > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__1y92- > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__1y92-:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__2pG7Q {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__2pG7Q:hover {
    cursor: pointer;
}

.List_board_pagination__78cB5 {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__78cB5 > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__78cB5 > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__78cB5 > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__355Qs {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__MCLPF {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__Tc5dD {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__Tc5dD:hover {
    cursor: pointer;
}

.List_scroll_hidden__fg7Am::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__fg7Am {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__1VM-B { padding: 100px 0px; }
    .List_table_content_line__1y92- { width: 100%; }
}
.Content_wrap_div__LNXSs {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__LNXSs *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__LNXSs > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__2sFpu {
    margin-bottom: 80px;
}

.Content_title_div__2sFpu h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__Dp-Oz {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__1wth3 {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1I9lm {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1I9lm:hover {
    cursor: pointer;
}

.Content_submit_btn__5o6Sf:hover {
    cursor: pointer;
}

.Content_img_preview__1fbML {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__2WtEs iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__2WtEs * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__LNXSs {
        padding: 100px 0px;
    }
    .Content_content_div__Dp-Oz {
        width: 100%;
    }

    .Content_board_content_div__1wth3 {
        padding: 10px;
    }

    .Content_video_iframe__2WtEs iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.EducationApply_wrap_div__17Ds4 * {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}
.Writer_wrap_div__hgpbL {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__hgpbL *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__V4Y-x {
    margin-bottom: 80px;
}

.Writer_title_div__V4Y-x h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__18qsN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1sBzP {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1sBzP:hover {
    cursor: pointer;
}

.Writer_del_btn__1mqOv {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__1mqOv:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__YIfPN  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__hgpbL { padding: 100px 0px }
    .Writer_content_div__18qsN { width: 100vw; }
}
.Modify_wrap_div__20RId {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__20RId *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3Lhze {
    margin-bottom: 80px;
}

.Modify_title_div__3Lhze > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__FkK_F {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__2422K {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__2422K:hover {
    cursor: pointer;
}

.Modify_del_btn__3kq43 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3kq43:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__20RId { padding: 100px 0px; }
    .Modify_content_div__FkK_F {
        padding: 10px;
    }
}
.ApplyTeamRegistration_wrap_div__J-udR * {
    font-family: "Noto Sans KR";
}

.ApplyTeamRegistration_wrap_div__J-udR {
    /* background-color: #F7F8Fd; */
    background-color: #F4F8FF;
    padding: 180px 30px;
    font-family: "Noto Sans KR";;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    height: 100%;
}



.ApplyTeamRegistration_menu_div__2H9JO {
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;
}

.ApplyTeamRegistration_menu_div__2H9JO> h1 {
    font-size: 20px;
    margin: 0px;
}

.ApplyTeamRegistration_menu_div__2H9JO > hr {
    border: 0;
    border-top: 1px solid rgba(17, 12, 46, 0.15);
    width: 100%;
    margin: 10px 0px;
}

.ApplyTeamRegistration_menu_div__2H9JO > p {
    /* background-color: red; */
    margin: 0px;
    padding: 5px;
}

.ApplyTeamRegistration_menu_div__2H9JO > p:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.ApplyTeamRegistration_content_div__qbpIm {
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    width: 90%;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    min-height: 472px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.ApplyTeamRegistration_datepicker_input__GqVln > input {
    border: 1px solid #000000 !important;
}
/* div, p, span {font-size:1em} */
.List_wrap_div__3z1qA {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__3z1qA * {
    color: white;
}

.List_title_div__zQYD8 {
    margin-bottom: 80px;
}

.List_title_div__zQYD8 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3gy1i {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3gy1i div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__xfVvl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__xfVvl > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__xfVvl > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__xfVvl > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__xfVvl:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__UIB1L {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__UIB1L:hover {
    cursor: pointer;
}

.List_board_pagination__3pgUR {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__3pgUR > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__3pgUR > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__3pgUR > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__2gj2u {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__Onaxi {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__wXzeO {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__wXzeO:hover {
    cursor: pointer;
}

.List_scroll_hidden__2KKvY::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__2KKvY {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__3z1qA { padding: 100px 0px; }
    .List_table_content_line__xfVvl { width: 100%; }
}
.Content_wrap_div__289HD {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__289HD *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__289HD > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__4Torm {
    margin-bottom: 80px;
}

.Content_title_div__4Torm h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1udBd {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__1-OSA {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__2LKmn {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__2LKmn:hover {
    cursor: pointer;
}

.Content_submit_btn__2RD0y:hover {
    cursor: pointer;
}

.Content_img_preview__37bPr {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__1R19C iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__1R19C * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__289HD {
        padding: 100px 0px;
    }
    .Content_content_div__1udBd {
        width: 100%;
    }

    .Content_board_content_div__1-OSA {
        padding: 10px;
    }

    .Content_video_iframe__1R19C iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Writer_wrap_div__ysVpz {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__ysVpz *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__1nqoI {
    margin-bottom: 80px;
}

.Writer_title_div__1nqoI h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__3S4W3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__jkumE {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__jkumE:hover {
    cursor: pointer;
}

.Writer_del_btn__3O7AR {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__3O7AR:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__1iK7D  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__ysVpz { padding: 100px 0px }
    .Writer_content_div__3S4W3 { width: 100vw; }
}
.Modify_wrap_div__ZNeV4 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__ZNeV4 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__1Bu4N {
    margin-bottom: 80px;
}

.Modify_title_div__1Bu4N > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3b09V {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3WAEn {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3WAEn:hover {
    cursor: pointer;
}

.Modify_del_btn__3n06V {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3n06V:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__ZNeV4 { padding: 100px 0px; }
    .Modify_content_div__3b09V {
        padding: 10px;
    }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__WeenC {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__WeenC * {
    color: white;
}

.List_title_div__3RaKz {
    margin-bottom: 80px;
}

.List_title_div__3RaKz h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__lGcX6 {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__lGcX6 div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3TAEp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3TAEp > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3TAEp > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3TAEp > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3TAEp:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3O8dj {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3O8dj:hover {
    cursor: pointer;
}

.List_board_pagination__2Ujz6 {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__2Ujz6 > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__2Ujz6 > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__2Ujz6 > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__iw1J_ {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3SIAD {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__1LBrh {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__1LBrh:hover {
    cursor: pointer;
}

.List_scroll_hidden__3QF8c::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3QF8c {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__WeenC { padding: 100px 0px; }
    .List_table_content_line__3TAEp { width: 100%; }
}
.Content_wrap_div__i5oYi {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__i5oYi *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__i5oYi > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3ENlC {
    margin-bottom: 80px;
}

.Content_title_div__3ENlC h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3ahdx {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__28mO4 {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1UxcC {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1UxcC:hover {
    cursor: pointer;
}

.Content_submit_btn__3MoK4:hover {
    cursor: pointer;
}

.Content_img_preview__25vjz {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__27PFQ iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__27PFQ * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__i5oYi {
        padding: 100px 0px;
    }
    .Content_content_div__3ahdx {
        width: 100%;
    }

    .Content_board_content_div__28mO4 {
        padding: 10px;
    }

    .Content_video_iframe__27PFQ iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__3AjON {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3AjON *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__aMyjk {
    margin-bottom: 80px;
}

.Modify_title_div__aMyjk > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3zaS3 {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__Ze_d5 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__Ze_d5:hover {
    cursor: pointer;
}

.Modify_del_btn__ilqZe {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__ilqZe:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3AjON { padding: 100px 0px; }
    .Modify_content_div__3zaS3 {
        padding: 10px;
    }
}
.Writer_wrap_div__2wm7L {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__2wm7L *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__ZcLyf {
    margin-bottom: 80px;
}

.Writer_title_div__ZcLyf h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__2Zqd9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__256Np {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__256Np:hover {
    cursor: pointer;
}

.Writer_del_btn__3L4JF {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__3L4JF:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__thucx  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__2wm7L { padding: 100px 0px }
    .Writer_content_div__2Zqd9 { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2B9lV {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2B9lV * {
    color: white;
}

.List_title_div__tzKi4 {
    margin-bottom: 80px;
}

.List_title_div__tzKi4 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__188ah {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__188ah div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__16uSN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__16uSN > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__16uSN > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__16uSN > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__16uSN:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__Gvi_z {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__Gvi_z:hover {
    cursor: pointer;
}

.List_board_pagination__M_OBB {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__M_OBB > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__M_OBB > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__M_OBB > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__2_5qU {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__HDIIa {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__1yNWN {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__1yNWN:hover {
    cursor: pointer;
}

.List_scroll_hidden__3gAiv::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3gAiv {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2B9lV { padding: 100px 0px; }
    .List_table_content_line__16uSN { width: 100%; }
}
.Content_wrap_div__3CyGd {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__3CyGd *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__3CyGd > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__2cN5Q {
    margin-bottom: 80px;
}

.Content_title_div__2cN5Q h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__2ql_g {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3fXis {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__3quKR {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__3quKR:hover {
    cursor: pointer;
}

.Content_submit_btn__3SsrB:hover {
    cursor: pointer;
}

.Content_img_preview__mwcuq {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__2BpCN iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__2BpCN * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__3CyGd {
        padding: 100px 0px;
    }
    .Content_content_div__2ql_g {
        width: 100%;
    }

    .Content_board_content_div__3fXis {
        padding: 10px;
    }

    .Content_video_iframe__2BpCN iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__23bTA {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__23bTA *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__14JEC {
    margin-bottom: 80px;
}

.Modify_title_div__14JEC > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3j3lC {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__2F7bi {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__2F7bi:hover {
    cursor: pointer;
}

.Modify_del_btn__2UoXl {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__2UoXl:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__23bTA { padding: 100px 0px; }
    .Modify_content_div__3j3lC {
        padding: 10px;
    }
}
.Writer_wrap_div__8Ab0H {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__8Ab0H *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__2Ibs1 {
    margin-bottom: 80px;
}

.Writer_title_div__2Ibs1 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__1nEp9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__2hzHm {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__2hzHm:hover {
    cursor: pointer;
}

.Writer_del_btn__dvr0V {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__dvr0V:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__2-TrU  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__8Ab0H { padding: 100px 0px }
    .Writer_content_div__1nEp9 { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__1Xp97 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__1Xp97 * {
    color: white;
}

.List_title_div__3bcgS {
    margin-bottom: 80px;
}

.List_title_div__3bcgS h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3Vgt7 {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3Vgt7 div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3aGwC {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3aGwC > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3aGwC > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3aGwC > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3aGwC:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__1ycaX {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__1ycaX:hover {
    cursor: pointer;
}

.List_board_pagination__2vb2g {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__2vb2g > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__2vb2g > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__2vb2g > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__1pzKS {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__2VtR_ {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2jncm {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2jncm:hover {
    cursor: pointer;
}

.List_scroll_hidden__1AG1s::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__1AG1s {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__1Xp97 { padding: 100px 0px; }
    .List_table_content_line__3aGwC { width: 100%; }
}
.Content_wrap_div__1yUKO {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1yUKO *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1yUKO > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__2opru {
    margin-bottom: 80px;
}

.Content_title_div__2opru h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3vabK {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3vyqD {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__XO4df {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__XO4df:hover {
    cursor: pointer;
}

.Content_submit_btn__2MZgi:hover {
    cursor: pointer;
}

.Content_img_preview__6nwMu {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__1Mfji iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__1Mfji * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1yUKO {
        padding: 100px 0px;
    }
    .Content_content_div__3vabK {
        width: 100%;
    }

    .Content_board_content_div__3vyqD {
        padding: 10px;
    }

    .Content_video_iframe__1Mfji iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__11QMg {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__11QMg *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__o3JjO {
    margin-bottom: 80px;
}

.Modify_title_div__o3JjO > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1oToQ {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__8SlhU {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__8SlhU:hover {
    cursor: pointer;
}

.Modify_del_btn__3IZ87 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__3IZ87:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__11QMg { padding: 100px 0px; }
    .Modify_content_div__1oToQ {
        padding: 10px;
    }
}
.Writer_wrap_div__1hZCd {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1hZCd *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__1U8S2 {
    margin-bottom: 80px;
}

.Writer_title_div__1U8S2 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__1ZraD {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__3v71g {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__3v71g:hover {
    cursor: pointer;
}

.Writer_del_btn__3VGG6 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__3VGG6:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__ZJTSI  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1hZCd { padding: 100px 0px }
    .Writer_content_div__1ZraD { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__2QKxT {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__2QKxT * {
    color: white;
}

.List_title_div__dnsA3 {
    margin-bottom: 80px;
}

.List_title_div__dnsA3 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__1JGSL {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__1JGSL div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__2ha0j {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__2ha0j > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__2ha0j > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__2ha0j > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__2ha0j:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__2y2Wy {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__2y2Wy:hover {
    cursor: pointer;
}

.List_board_pagination__Zkujt {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__Zkujt > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__Zkujt > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__Zkujt > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__Goe4P {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__wHtRn {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__3Bgal {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__3Bgal:hover {
    cursor: pointer;
}

.List_scroll_hidden__2xT6D::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__2xT6D {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__2QKxT { padding: 100px 0px; }
    .List_table_content_line__2ha0j { width: 100%; }
}
.Content_wrap_div__1Ohuz {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1Ohuz *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1Ohuz > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3Jc8T {
    margin-bottom: 80px;
}

.Content_title_div__3Jc8T h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__j8PpE {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2_Fj4 {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1AQsT {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1AQsT:hover {
    cursor: pointer;
}

.Content_submit_btn__vFvxk:hover {
    cursor: pointer;
}

.Content_img_preview__kytKO {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__3aQdB iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__3aQdB * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1Ohuz {
        padding: 100px 0px;
    }
    .Content_content_div__j8PpE {
        width: 100%;
    }

    .Content_board_content_div__2_Fj4 {
        padding: 10px;
    }

    .Content_video_iframe__3aQdB iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__ZAhMm {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__ZAhMm *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__RA3HL {
    margin-bottom: 80px;
}

.Modify_title_div__RA3HL > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__1X7UE {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__ad6oJ {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__ad6oJ:hover {
    cursor: pointer;
}

.Modify_del_btn__1qEMM {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__1qEMM:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__ZAhMm { padding: 100px 0px; }
    .Modify_content_div__1X7UE {
        padding: 10px;
    }
}
.Writer_wrap_div__1nOuT {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1nOuT *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__tpC4Q {
    margin-bottom: 80px;
}

.Writer_title_div__tpC4Q h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__16hj7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__3jdCc {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__3jdCc:hover {
    cursor: pointer;
}

.Writer_del_btn__2Cs4P {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__2Cs4P:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__4lYJO  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1nOuT { padding: 100px 0px }
    .Writer_content_div__16hj7 { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__3nA8Q {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__3nA8Q * {
    color: white;
}

.List_title_div__1qVSs {
    margin-bottom: 80px;
}

.List_title_div__1qVSs h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__2yz79 {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__2yz79 div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__31P2M {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__31P2M > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__31P2M > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__31P2M > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__31P2M:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__3B8rT {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__3B8rT:hover {
    cursor: pointer;
}

.List_board_pagination__12l8V {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__12l8V > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__12l8V > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__12l8V > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__2maxq {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__1O_od {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__P8J2S {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__P8J2S:hover {
    cursor: pointer;
}

.List_scroll_hidden__1GCO5::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__1GCO5 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__3nA8Q { padding: 100px 0px; }
    .List_table_content_line__31P2M { width: 100%; }
}
.Content_wrap_div__2tPA2 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__2tPA2 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__2tPA2 > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3Ti7e {
    margin-bottom: 80px;
}

.Content_title_div__3Ti7e h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__3FaBz {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2mZQS {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__2kg9L {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__2kg9L:hover {
    cursor: pointer;
}

.Content_submit_btn__3b2Pr:hover {
    cursor: pointer;
}

.Content_img_preview__2n9cm {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__1YwDt iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__1YwDt * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__2tPA2 {
        padding: 100px 0px;
    }
    .Content_content_div__3FaBz {
        width: 100%;
    }

    .Content_board_content_div__2mZQS {
        padding: 10px;
    }

    .Content_video_iframe__1YwDt iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__3iGM_ {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3iGM_ *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3LAw9 {
    margin-bottom: 80px;
}

.Modify_title_div__3LAw9 > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__OBXBl {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3PJs5 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3PJs5:hover {
    cursor: pointer;
}

.Modify_del_btn__1N_VY {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__1N_VY:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3iGM_ { padding: 100px 0px; }
    .Modify_content_div__OBXBl {
        padding: 10px;
    }
}
.Writer_wrap_div__m8BVd {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__m8BVd *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3Fv9M {
    margin-bottom: 80px;
}

.Writer_title_div__3Fv9M h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__2yFiI {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__UUiOM {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__UUiOM:hover {
    cursor: pointer;
}

.Writer_del_btn__1HG01 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__1HG01:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__3-5Bu  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__m8BVd { padding: 100px 0px }
    .Writer_content_div__2yFiI { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__344XO {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__344XO * {
    color: white;
}

.List_title_div__dB7ad {
    margin-bottom: 80px;
}

.List_title_div__dB7ad h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3OqHg {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3OqHg div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3nT3Z {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3nT3Z > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3nT3Z > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3nT3Z > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3nT3Z:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__IG1yf {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__IG1yf:hover {
    cursor: pointer;
}

.List_board_pagination___HJh5 {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination___HJh5 > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination___HJh5 > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination___HJh5 > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__qZpPN {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__3Rarv {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__1vAql {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__1vAql:hover {
    cursor: pointer;
}

.List_scroll_hidden__1dnsI::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__1dnsI {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__344XO { padding: 100px 0px; }
    .List_table_content_line__3nT3Z { width: 100%; }
}
.Content_wrap_div__1P7Jr {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__1P7Jr *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__1P7Jr > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__37QhX {
    margin-bottom: 80px;
}

.Content_title_div__37QhX h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div___sI6- {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__2RCmN {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__1mneX {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__1mneX:hover {
    cursor: pointer;
}

.Content_submit_btn__5EOTg:hover {
    cursor: pointer;
}

.Content_img_preview__2axnM {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__fohDw iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__fohDw * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__1P7Jr {
        padding: 100px 0px;
    }
    .Content_content_div___sI6- {
        width: 100%;
    }

    .Content_board_content_div__2RCmN {
        padding: 10px;
    }

    .Content_video_iframe__fohDw iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__3WuJL {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__3WuJL *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3FQjJ {
    margin-bottom: 80px;
}

.Modify_title_div__3FQjJ > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3bSAX {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3u4D5 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3u4D5:hover {
    cursor: pointer;
}

.Modify_del_btn__5hVVN {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__5hVVN:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__3WuJL { padding: 100px 0px; }
    .Modify_content_div__3bSAX {
        padding: 10px;
    }
}
.Writer_wrap_div__1g_pg {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1g_pg *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3UGiG {
    margin-bottom: 80px;
}

.Writer_title_div__3UGiG h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__2ppO4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__35Trw {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__35Trw:hover {
    cursor: pointer;
}

.Writer_del_btn__1uMzo {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__1uMzo:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__18U7q  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1g_pg { padding: 100px 0px }
    .Writer_content_div__2ppO4 { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__iDD1r {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__iDD1r * {
    color: white;
}

.List_title_div__1kSFM {
    margin-bottom: 80px;
}

.List_title_div__1kSFM h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header___TPQa {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header___TPQa div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__3stdi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__3stdi > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__3stdi > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__3stdi > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__3stdi:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__1oVel {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__1oVel:hover {
    cursor: pointer;
}

.List_board_pagination__6r3wt {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__6r3wt > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__6r3wt > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__6r3wt > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__2qW87 {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__26pgV {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__2pflq {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__2pflq:hover {
    cursor: pointer;
}

.List_scroll_hidden__3duxx::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__3duxx {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__iDD1r { padding: 100px 0px; }
    .List_table_content_line__3stdi { width: 100%; }
}
.Content_wrap_div__Syt66 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__Syt66 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__Syt66 > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__3dKwE {
    margin-bottom: 80px;
}

.Content_title_div__3dKwE h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__1XL5F {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__3uFut {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__9dnzy {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__9dnzy:hover {
    cursor: pointer;
}

.Content_submit_btn__2yDnz:hover {
    cursor: pointer;
}

.Content_img_preview__1fUba {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__10CHo iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__10CHo * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__Syt66 {
        padding: 100px 0px;
    }
    .Content_content_div__1XL5F {
        width: 100%;
    }

    .Content_board_content_div__3uFut {
        padding: 10px;
    }

    .Content_video_iframe__10CHo iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2PRsG {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2PRsG *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__3K40h {
    margin-bottom: 80px;
}

.Modify_title_div__3K40h > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3RVip {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__3Csv1 {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__3Csv1:hover {
    cursor: pointer;
}

.Modify_del_btn__2LTXP {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__2LTXP:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2PRsG { padding: 100px 0px; }
    .Modify_content_div__3RVip {
        padding: 10px;
    }
}
.Writer_wrap_div__1-LOk {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1-LOk *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__3zYiA {
    margin-bottom: 80px;
}

.Writer_title_div__3zYiA h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__3JNp- {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__2mprw {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__2mprw:hover {
    cursor: pointer;
}

.Writer_del_btn__454Q9 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__454Q9:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__2kIGI  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1-LOk { padding: 100px 0px }
    .Writer_content_div__3JNp- { width: 100vw; }
}
/* div, p, span {font-size:1em} */
.List_wrap_div__QfEmf {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.List_wrap_div__QfEmf * {
    color: white;
}

.List_title_div__169US {
    margin-bottom: 80px;
}

.List_title_div__169US h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.List_board_header__3gJcj {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.List_board_header__3gJcj div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.List_table_content_line__zt5T3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.List_table_content_line__zt5T3 > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.List_table_content_line__zt5T3 > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.List_table_content_line__zt5T3 > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.List_table_content_line__zt5T3:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.List_board_btn__17nJQ {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.List_board_btn__17nJQ:hover {
    cursor: pointer;
}

.List_board_pagination__183Jn {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.List_board_pagination__183Jn > a {
    outline: none;
    font-size: 10px;
}

.List_board_pagination__183Jn > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_board_pagination__183Jn > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.List_form_select__P_b8B {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.List_search_text__2MAR7 {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.List_search_btn__3uuqO {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.List_search_btn__3uuqO:hover {
    cursor: pointer;
}

.List_scroll_hidden__GhlYF::-webkit-scrollbar {
    display: none;
}

.List_scroll_hidden__GhlYF {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .List_wrap_div__QfEmf { padding: 100px 0px; }
    .List_table_content_line__zt5T3 { width: 100%; }
}
.Content_wrap_div__3X2y7 {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.Content_wrap_div__3X2y7 *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Content_wrap_div__3X2y7 > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.Content_title_div__hCur8 {
    margin-bottom: 80px;
}

.Content_title_div__hCur8 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.Content_content_div__30l2g {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.Content_board_content_div__1CqmX {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.Content_comment_btn__3dWNI {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Content_comment_btn__3dWNI:hover {
    cursor: pointer;
}

.Content_submit_btn__1v_fu:hover {
    cursor: pointer;
}

.Content_img_preview__2VIXd {
    width: 90%;
    margin-bottom: 10px;
}

.Content_video_iframe__2TogU iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.Content_video_iframe__2TogU * {
    line-height: normal;
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .Content_wrap_div__3X2y7 {
        padding: 100px 0px;
    }
    .Content_content_div__30l2g {
        width: 100%;
    }

    .Content_board_content_div__1CqmX {
        padding: 10px;
    }

    .Content_video_iframe__2TogU iframe {
        max-width: 90%;
        height: 300px;
    }
    

}
.Modify_wrap_div__2fXCG {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Modify_wrap_div__2fXCG *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Modify_title_div__25IGy {
    margin-bottom: 80px;
}

.Modify_title_div__25IGy > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Modify_content_div__3Va3_ {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.Modify_modify_btn__1g8nL {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Modify_modify_btn__1g8nL:hover {
    cursor: pointer;
}

.Modify_del_btn__39Wk9 {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Modify_del_btn__39Wk9:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Modify_wrap_div__2fXCG { padding: 100px 0px; }
    .Modify_content_div__3Va3_ {
        padding: 10px;
    }
}
.Writer_wrap_div__1l-LA {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.Writer_wrap_div__1l-LA *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.Writer_title_div__qs5x5 {
    margin-bottom: 80px;
}

.Writer_title_div__qs5x5 h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.Writer_content_div__voa1g {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding: 20px;

}

.Writer_writer_btn__1LmFK {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.Writer_writer_btn__1LmFK:hover {
    cursor: pointer;
}

.Writer_del_btn__AzJ7j {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.Writer_del_btn__AzJ7j:hover {
    cursor: pointer;
}

.Writer_se2_inputarea__3w_k3  iframe {
    width: 100px !important;
}

@media screen and (max-width: 768px) {
    .Writer_wrap_div__1l-LA { padding: 100px 0px }
    .Writer_content_div__voa1g { width: 100vw; }
}
.Kiosk_allWrap__1Jzr3 {
    background-image: url(/images/kiosk/back_01.jpg);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Kiosk_kioskWrap__3Ey4A {
    background-image: url(/images/kiosk/20201130.png);
    background-size: cover;
    width: 1920px;
    height: 1080px;
    flex-direction: column;
}

.Kiosk_card__38vzL {
    width:231px;
    height: 367px;
}
.AdminManager_wrap_div__2zG9_ * {
    font-family: "Noto Sans KR";
}

.AdminManager_wrap_div__2zG9_ {
    /* background-color: #F7F8Fd; */
    background-color: #F4F8FF;
    padding: 100px 30px;
    font-family: "Noto Sans KR";;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    height: 100%;
}

.AdminManager_content_div__1ULAh {
    height: -webkit-max-content;
    height: max-content;
    position: relative;
    width: 90%;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    min-height: 472px;
}
.LeftMenu_menu_div__2TLdV {
    /* width: 150px; */
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;
    min-width: 200px;
}

.LeftMenu_menu_div__2TLdV> h1 {
    font-size: 20px;
    margin: 0px;
}

.LeftMenu_menu_div__2TLdV > hr {
    border: 0;
    border-top: 1px solid rgba(17, 12, 46, 0.15);
    width: 100%;
    margin: 10px 0px;
}

.LeftMenu_menu_div__2TLdV > p {
    /* background-color: red; */
    margin: 0px;
    padding: 5px;
}

.LeftMenu_menu_div__2TLdV > p:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.LeftMenu_alarm_div1__3sBqh {
    background-color: #F4F8FF;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.LeftMenu_alarm_div1__3sBqh:hover{
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.LeftMenu_alarm_div2__z69AU {
    background-color: #F4F8FF;
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.LeftMenu_alarm_div2__z69AU:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}
.competition_redHederText__3FxDU{
    color: red;
    font-size: 1em;
}


.competition_blueHederText__nz5lp{
    color: blue;
    font-size: 1em;
}

.competition_redText__6tHn3{
    color: red;
    font-size: 1em;
}

.competition_blueText__eDRPw{
    color: blue;
    font-size: 1em;
}

.ScoreModal_set_drop__2Lu4_ {
    /* background-color: rgba(255, 255, 255, 0) !important; */
    font-size: 15px;
    width: 200px !important;
    margin-bottom: 40px;
}
.ScoreModal_score_div__NdQPs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.ScoreModal_score_div__NdQPs > div {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.ScoreModal_score_div__NdQPs > :nth-child(1) {
    background-color: red;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
    width: 150px;
    height: 150px;
}

.ScoreModal_score_div__NdQPs > :nth-child(2) {
    background-color: blue;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Noto Sans KR";
    width: 150px;
    height: 150px;
}

.ScoreModal_score_div__NdQPs > div:hover {
    cursor: pointer;
}


.PlayerSearch_tableRow__ExWul:hover{
    background-color: rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;
}
.CompetitionManager_noDataWrap__1UbJO {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.TeamSearch_tableRow__LlZo4:hover{
    background-color: rgba(0, 0, 0, 0.05) !important;
    cursor: pointer;
}
.CsvDownloadManager_table_row__Iztbd:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.PaymentManager_table_row__3FDEB:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
}
.TeamInfo_datepicker_input__hHvx6 > input {
    border: 1px solid #000000 !important;
}
.TeamPlayers_player_tableRow__WeuEl:hover { 
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08) !important;
 }
.ChangeManager_mouse_hover__3Qyxn:hover {
    cursor: pointer;
}
.TeamRequestsManage_wrap_div__3--wO{
    /* padding: 180px 0px !important; */
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.TeamRequestsManage_content_div__1DCqL {
    max-width: 1200px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TeamRequestsManage_teamRequests_table__-x3qc > tr:hover {
    cursor: pointer;
    background-color: rgba(0,0,50,.1) !important;
  }
  
.ApplyTeamRegistrationManager_wrap_div__rH_5W{
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.ApplyTeamRegistrationManager_content_div__22DOr {
    max-width: 1200px;
    height: 100%;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ApplyTeamRegistrationManager_teamRegistrations_table__3vq-p > tr:hover {
    cursor: pointer;
    background-color: rgba(0,0,50,.1) !important;
  }
  
.ApplyTeamRegistrationForm_content_div__3v2F- {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ApplyTeamRegistrationForm_content_div__3v2F- * {
    font-family: "Noto Sans KR";
}

.ApplyTeamRegistrationForm_content_div__3v2F- span {
    color: red;
    font-weight: bold;
}



.ApplyTeamRegistrationForm_content_div__3v2F- > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
}

/* .content_div > div > p {
    margin: 0px;
    font-size: 15px;
} */
.ApplyTeamRegistrationForm_content_div__3v2F- > div > :nth-child(1) {
    margin: 0px;
    font-size: 15px;
    flex: 1 1;
}



.ApplyTeamRegistrationForm_content_div__3v2F- > div > :nth-child(2) {
    text-align: center;
    flex: 3 1;
}
@keyframes AdultSelect_move-twink-back__2T4so {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes AdultSelect_move-twink-back__2T4so {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes AdultSelect_move-clouds-back__1GakV {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes AdultSelect_move-clouds-back__1GakV {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.AdultSelect_stars__1EPXG, .AdultSelect_twinkling__3g3Ap, .AdultSelect_clouds__2JNOE {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.AdultSelect_stars__1EPXG {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.AdultSelect_twinkling__3g3Ap{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:AdultSelect_move-twink-back__2T4so 200s linear infinite;
  animation:AdultSelect_move-twink-back__2T4so 200s linear infinite;
}

.AdultSelect_clouds__2JNOE{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:AdultSelect_move-clouds-back__1GakV 200s linear infinite;
  animation:AdultSelect_move-clouds-back__1GakV 200s linear infinite;
}


.AdultSelect_wrap_div__3Yjg5 {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdultSelect_content_div__F5mX- {
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 450px;
}
.AdultSelect_content_div__F5mX- > p {
    color: #F0F0F0;
    font-size: 17px;
    font-weight: bold;
    /* position: relative; */
    /* top: -30px; */
    margin-bottom: 40px;
    margin-top: -40px;
}

.AdultSelect_content_div__F5mX- > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px;
}

.AdultSelect_memberselect_div__2xPMN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdultSelect_memberselect_div__2xPMN > p {
    color: #F0F0F0;

}

.AdultSelect_style_a__gzcSX {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.AdultSelect_style_a__gzcSX:hover {
    cursor: pointer;
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.AdultSelect_style_a1__1Ppkp {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #F22121;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.AdultSelect_style_a1__1Ppkp:hover {
    cursor: pointer;
    background: #F22121;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #F22121,
                0 0 25px #F22121,
                0 0 50px #F22121,
                0 0 100px #F22121;
  }

  @media screen and (max-width: 768px) {
    .AdultSelect_content_div__F5mX- {
        width: 80vw;
        height: 700px;
    }
    /* .content_div > p {
        top: -20px;
    } */
    .AdultSelect_content_div__F5mX- > div {
        flex-direction: column;
    }
    .AdultSelect_style_a__gzcSX {
        margin-bottom: 20px;
    }
    .AdultSelect_style_a1__1Ppkp {
        top: 30px;
    }
  }
@keyframes IdentityVerification_move-twink-back__M6oIa {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes IdentityVerification_move-twink-back__M6oIa {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes IdentityVerification_move-clouds-back__MZJwj {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes IdentityVerification_move-clouds-back__MZJwj {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.IdentityVerification_stars__2nEx1, .IdentityVerification_twinkling__3GQk1, .IdentityVerification_clouds__25RNg {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.IdentityVerification_stars__2nEx1 {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.IdentityVerification_twinkling__3GQk1{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:IdentityVerification_move-twink-back__M6oIa 200s linear infinite;
  animation:IdentityVerification_move-twink-back__M6oIa 200s linear infinite;
}

.IdentityVerification_clouds__25RNg{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:IdentityVerification_move-clouds-back__MZJwj 200s linear infinite;
  animation:IdentityVerification_move-clouds-back__MZJwj 200s linear infinite;
}


.IdentityVerification_wrap_div__JsoQD {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    /* padding: 100px; */
    /* background-color: red !important; */
}

.IdentityVerification_content_div__3ywpM {
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 80px 0px;
}
.IdentityVerification_content_div__3ywpM > p {
    color: #F0F0F0;
    font-size: 17px;
    font-weight: bold;
    position: relative;
    top: -40px;
    margin-bottom: 20px;
    /* margin-top: -20px; */
}

.IdentityVerification_content_div__3ywpM > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 60px;
}

.IdentityVerification_memberselect_div__Yoomu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.IdentityVerification_memberselect_div__Yoomu > p {
    color: #F0F0F0;

}

.IdentityVerification_style_a__30EEV {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IdentityVerification_style_a__30EEV:hover {
    cursor: pointer;
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.IdentityVerification_style_a1__2K5HV {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #F22121;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.IdentityVerification_style_a1__2K5HV:hover {
    cursor: pointer;
    background: #F22121;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #F22121,
                0 0 25px #F22121,
                0 0 50px #F22121,
                0 0 100px #F22121;
  }

  @media screen and (max-width: 768px) {
    .IdentityVerification_total_div__gRCRv {
        height: 150vh !important;
    }
    .IdentityVerification_total_div__gRCRv > :nth-child(1) {
        height: 150vh !important;
    }
    .IdentityVerification_total_div__gRCRv > :nth-child(2) {
        height: 150vh !important;
    }
    .IdentityVerification_content_div__3ywpM {
        /* margin-top: 100px; */
        width: 80vw;
        height: 560px;
    }
    .IdentityVerification_content_div__3ywpM > p {
        top: -20px;
    }
    .IdentityVerification_content_div__3ywpM > div {
        flex-direction: column;
    }
    .IdentityVerification_style_a__30EEV {
        margin-bottom: 20px;
    }
    .IdentityVerification_style_a1__2K5HV {
        top: 30px;
    }
  }
@keyframes NiceFail_move-twink-back__17JDP {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes NiceFail_move-twink-back__17JDP {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes NiceFail_move-clouds-back__3KYvv {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes NiceFail_move-clouds-back__3KYvv {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.NiceFail_stars__USVTo, .NiceFail_twinkling__56c5l, .NiceFail_clouds__3f-zc {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.NiceFail_stars__USVTo {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.NiceFail_twinkling__56c5l{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:NiceFail_move-twink-back__17JDP 200s linear infinite;
  animation:NiceFail_move-twink-back__17JDP 200s linear infinite;
}

.NiceFail_clouds__3f-zc{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:NiceFail_move-clouds-back__3KYvv 200s linear infinite;
  animation:NiceFail_move-clouds-back__3KYvv 200s linear infinite;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Noto Sans KR";
}

@keyframes FailedToFetchPage_move-twink-back__PFZS7 {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes FailedToFetchPage_move-twink-back__PFZS7 {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes FailedToFetchPage_move-clouds-back__1oZc8 {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes FailedToFetchPage_move-clouds-back__1oZc8 {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}

.FailedToFetchPage_stars__3mSqG, .FailedToFetchPage_twinkling__2uyv0, .FailedToFetchPage_clouds__1fU7j {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.FailedToFetchPage_stars__3mSqG {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.FailedToFetchPage_twinkling__2uyv0{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;
  -webkit-animation:FailedToFetchPage_move-twink-back__PFZS7 200s linear infinite;
  animation:FailedToFetchPage_move-twink-back__PFZS7 200s linear infinite;
}

.FailedToFetchPage_clouds__1fU7j{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;
  -webkit-animation:FailedToFetchPage_move-clouds-back__1oZc8 200s linear infinite;
  animation:FailedToFetchPage_move-clouds-back__1oZc8 200s linear infinite;
}

.FailedToFetchPage_wrap_div__3RpmM{
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.FailedToFetchPage_content_div__1RE7X{
    /* opacity: 0.7; */
    padding: 50px;
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

.FailedToFetchPage_content_div__1RE7X > h1 {
    color: #FFFFFF;
    font-family: "Noto Sans KR";
}

.FailedToFetchPage_content_div__1RE7X > p {
    color: #FFFFFF;
    font-family: "Noto Sans KR";
}
.Payment_title_div__3pVuQ {
    width: 70%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    height: 30px;
}

.Payment_participationCompetition_div__3BGzJ {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.Payment_participationCompetition_div__3BGzJ > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
    margin: 10px;
}

@media screen and (max-width: 768px) {
    .Payment_title_div__3pVuQ {
        width: 100%;
        flex-direction: column;
    }
}

.CertificateOfExperience_wrap_div__2dOcT {
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: Noto Sans KR;
}

.CertificateOfExperience_back_img__2BHoV {
    width: 600px;
    position: absolute;
    z-index: -99;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%);
}

/* .back_img1 {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, -25%);
}

.back_img2 {
    width: 600px;
    position: absolute;
    z-index: -99;
    transform: translate(0, 225%);
} */

.CertificateOfExperience_title_div__2epJs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.CertificateOfExperience_title_div__2epJs > img {
    width: 135px;
    margin-right: 20px;
}

.CertificateOfExperience_title_div__2epJs > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.CertificateOfExperience_title_div__2epJs > div > h3 {
    /* margin: 0; */
    font-size: 28px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
}

.CertificateOfExperience_title_div__2epJs > div > h1 {
    /* margin: 0; */
    font-size: 70px;
    font-weight: 450;
    margin: 0;
}

.CertificateOfExperience_info_table__3oRYP {
    width: 90%;
    max-width: 793px;
    text-align: center;
    border: 1px solid #010101;
    border-collapse: collapse;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 17px;
    font-family: Noto Sans KR;
    font-weight: normal;
}

.CertificateOfExperience_info_table__3oRYP > tbody > tr td {
    border: 1px solid #010101;
    border-collapse: collapse;
    padding: 5px;
}

.CertificateOfExperience_table_title__ITQvD {
    background-color: #f5f5f5;
    width: 150px;
}

.CertificateOfExperience_table_title2__3vAs0 {
    background-color: #f5f5f5;
    width: 50px;
}

.CertificateOfExperience_info_div__1aEeG {
    width: 100%;
    /* background-color: red; */
    max-width: 650px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 100;
}

.CertificateOfExperience_info_div__1aEeG > :nth-child(1) {
    /* flex: 0.5; */
    flex: 2 1;
    margin-right: 180px;
    text-align: center;
    /* font-weight: bold; */
}
.CertificateOfExperience_info_div__1aEeG > div > :nth-child(1) {
    text-align: center;
    /* font-weight: bold; */
}
/* .info_div > :nth-child(2) {
    flex: 1;
    text-align: center;
    font-weight: bold;
  } */

.CertificateOfExperience_content_p__33BLF {
    width: 90%;
    max-width: 650px;
    font-size: 28px;
    line-height: 250%;
}

.CertificateOfExperience_date_p__3vKrd {
    width: 90%;
    max-width: 650px;
    font-size: 23px;
    text-align: right;
}

.CertificateOfExperience_date_p2__9_cYN {
    width: 90%;
    max-width: 793px;
    font-size: 23px;
    text-align: right;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
    }
    /* ... the rest of the rules ... */
    .CertificateOfExperience_page_break__2nFED {
        page-break-after: always !important;
        -webkit-column-break-after: always !important;
                break-after: always !important;
    }
}

.TestPage_wrap_div__1Vebz {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.TestPage_back_img__7O3kM {
    width: 600px;
    position: absolute;
    z-index: -99;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%);
}

@page {
    size: A4;
    margin: 0;

  }
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
    /* ... the rest of the rules ... */
    .TestPage_page_break__8wui4 {
        page-break-after: always !important;
        -webkit-column-break-after: always !important;
                break-after: always !important;
    }

  }
