.wrap_div * {
    font-family: "Noto Sans KR";
}

.wrap_div {
    /* background-color: #F7F8Fd; */
    background-color: #F4F8FF;
    padding: 100px 30px;
    font-family: "Noto Sans KR";;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    height: 100%;
}

.content_div {
    height: max-content;
    position: relative;
    width: 90%;
    border-radius: 5px;
    padding: 10px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    min-height: 472px;
}