.wrap_div * {
    font-family: "Noto Sans KR";
}

.wrap_div {
    font-family: "Noto Sans KR";
    background-image: url('/images/info_squad/squad_bg_01.jpg');
    min-height: 1080px;
    padding: 180px 0em;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.wrap_div > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrap_div > :nth-child(1) > p {
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    text-shadow: gray 2px 2px 2px;
}

.wrap_div > :nth-child(1) > h1 {
    margin-top: 0px;
    font-size: 56px;
    text-shadow: gray 2px 2px 2px;
    text-align: center;
}

.province_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0px;
}


.province_chief_table{
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.province_chief_table > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 16px;
}

.province_chief_table > tbody > tr > :nth-child(1) {
    height: 60px;
    font-size: 18px;
    font-weight: bold;
}

.squad_table{
    max-width: 1000px;
    width: 80vw;
    text-align: center;
    border-spacing: 0px;
    font-family: "Noto Sans KR";
}

.squad_table > thead > tr {
    height: 40px ;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.squad_table > thead > tr > th {
    background-color: #5814e1;
    width: 80vw;
}

.squad_table > tbody {
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.squad_table > tbody > tr {
    height: 60px;
    font-size: 16px;
    font-family: "Noto Sans KR";
}

.squad_table > tbody > tr > td {
    border-bottom: 1px dashed gray;
}

.squad_table > tbody > :nth-last-child(1) > td {
    border-bottom: 0px
}

.testtest{
    display: none;
}

@media screen and (max-width: 768px){

    /* .wrap_div > :nth-child(1) > h1 { font-size: 30px; } */

    .wrap_div > :nth-child(2) { font-size: 15px; }

    .wrap_div > :nth-child(2) > :nth-child(1) > :nth-child(1) > *{ font-size: 13px; }

    .province_div > h1 { font-size: 20px; }

    .province_chief_table > tbody > tr > :nth-child(1) { height: 40px; font-size: 13px; }

    .province_chief_table > tbody > tr {font-size: 12px;}

    .squad_table { width: 80vw; }
    
    .squad_table > thead > tr { height: 40px ; font-size: 18px;}

    .squad_table > tbody > tr { height: 40px; font-size: 16px; }
}