.wrap_modal > div{
  /* background: radial-gradient(circle, rgba(68,69,154,1) 0%, rgba(11,26,42,1) 66%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.content_div {
    /* margin: 50px, 0px; */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 50px 0px; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate( -50%, -50% ); */
}

.content_div > p {
    color: white;
    font-family: "Noto Sans KR";
    font-size: 25px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #61ece1;
}

.content_div > h1 {
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
}

.content_div > :nth-child(3) {
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.team_logo_div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

.team_logo_div > img{
    width: 200px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
                0 0 0 6px #020202, 
                0 0 0 9px #61ece1;
}

.team_logo_div > p{
    color: white;
    font-family: "Noto Sans KR";
    font-size: 25px;
    margin-top: 30px;
    text-align: center;
}

.member_div{
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 700px;
}

.member_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  position: relative;
}

/* .member_profile:hover {
  background-color: red;

} */

.member_profile:hover {
  cursor: pointer;
   
}
/* generated element for shine effect.
 * normal state is semi-transparent
 * white but with zero width. Set no
 * transition here for no mouse-leave
 * animations. Otherwise the effect
 * will play in reverse when your mouse
 * leaves the element
 */
.member_profile:after {
    content: "";
    position: absolute;
    top: 120px;
    left: 0px;
    width: 0%;
    height: 30%;
    background-color: rgba(255,255,255,0.4);
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
   
}
/* on hover we animate the width to
 * 100% and opacity to 0 so the element
 * grows and fades out
 */
.member_profile:hover:after {
    width: 120%;
    background-color: rgba(255,255,255,0);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.member_profile > img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(97, 236, 224, 0.3), 
              0 0 0 6px #020202, 
              0 0 0 9px #61ece1;
  margin-bottom: 20px;
}

.member_profile > p{
  margin: 0px;
  font-family: "Noto Sans KR";
}

.member_profile > :nth-child(2){
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.member_profile > :nth-child(3){
  font-size: 12px;
  color: #61ece1;
  /* font-weight: bold; */
}

.style_a {
    border-radius: 5px;
    background-color: #e02e0e;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    /* font-weight: bold; */

}

.style_a:hover {
    cursor: pointer;
    background: #e02e0e;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #e02e0e,
                0 0 25px #e02e0e,
                0 0 50px #e02e0e,
                0 0 100px #e02e0e;
  }


  @media screen and (max-width: 1200px){
    .wrap_modal { width: 800px !important; }
    .content_div { width: 800px; }
    .content_div > :nth-child(3) { flex-direction: column; }
    .team_logo_div{ margin-right: 0px; margin-bottom: 50px; }
  }

  @media screen and (max-width: 768px) {
    .wrap_modal { width: 430px !important; }
    .content_div { width: 430px; }
    .member_profile { width: 240px; margin: 20px 0px;}
  }