.wrap_div {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    /* background-color: #341977; */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 100vh; */
    min-height: 100vh;
    height: 100%;
}

.wrap_div *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.wrap_div > h1{
    font-weight: bold;
    font-size: 52px;
    margin-bottom: 80px;
}

.title_div {
    margin-bottom: 80px;
}

.title_div h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}


.content_div {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 80%;
    background-color: #f7f7f7;
    padding-bottom: 30px;
}

.board_content_div {
    padding: 10px;
    width: 100%;
    min-height: 300px;
}


.comment_btn {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px;
}

.comment_btn:hover {
    cursor: pointer;
}

.submit_btn:hover {
    cursor: pointer;
}

.img_preview {
    width: 90%;
    margin-bottom: 10px;
}

.video_iframe iframe {
    /* width: 500px; */
    /* height: auto; */
    max-width: 80%;
    max-height: 500px;
    /* height: 100%; */
}

.video_iframe * {
    line-height: initial;
}

@media screen and (max-width: 768px) {
    .wrap_div {
        padding: 100px 0px;
    }
    .content_div {
        width: 100%;
    }

    .board_content_div {
        padding: 10px;
    }

    .video_iframe iframe {
        max-width: 90%;
        height: 300px;
    }
    

}