.allWrap {
    background-image: url(/images/kiosk/back_01.jpg);
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kioskWrap {
    background-image: url(/images/kiosk/20201130.png);
    background-size: cover;
    width: 1920px;
    height: 1080px;
    flex-direction: column;
}

.card {
    width:231px;
    height: 367px;
}