.wrap_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px;
    overflow-y: hidden;
}

.wrap_div > p {
    font-family: "Noto Sans KR";
    color: white;
    font-weight: bold;
}

.wrap_div > :nth-child(1){
    font-size: 3em;
    margin: 0px;
}

.wrap_div > :nth-child(2){
    font-size: 40em;
    margin: 0px;
    padding: 0px;
    width: max-content;
    height: fit-content;
}