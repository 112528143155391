.content_div {
  width: 100vw !important;
  height: 100vh;
  background-repeat: repeat-x;
  background-position: center;
  background-size: cover;
  flex-direction:column;
  padding: 180px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* .video_frame {
  pointer-events: none;
} */

.iframe_div {
  padding-top:56.25%;
  position: relative;
}

@media screen and (max-width: 768px){
  .iframe_div {
    padding-top: 490px;
    pointer-events: auto;
  }
}

/*  */


