.item_div {
    width: 100px;
    background-color: white;
    padding: 13px 0;
    color: gray;
    text-align: center;
    font-family: "Noto Sans KR";
    font-size:1.1em;
    border-right:1px solid #c0c0c0;
}

.item_div:hover {
    cursor: pointer;
    background-color: #5814E1;
    color: white;
}

.item_div:first-child {
    border-radius: 8px 0 0 8px;
}

.item_div:last-child {
    border-radius: 0 8px 8px 0;
    border:0;
}

@media (min-width: 500px) and (max-width: 768px) {
    .item_div {
        width: 80px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 13px;
        border-right:1px solid #c0c0c0;
    }
}

@media (max-width: 499px) {
    .item_div {
        width: 70px;
        background-color: white;
        padding: 13px 0;
        color: gray;
        text-align: center;
        font-family: "Noto Sans KR";
        font-size: 13px;
        border-right:1px solid #c0c0c0;
    }
 
}