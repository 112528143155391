/* div, p, span {font-size:1em} */
.wrap_div {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    flex-grow: 1;
    /* background-image: url('/images/board/bg_01.jpg'); */
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
}

.wrap_div * {
    color: white;
}

.title_div {
    margin-bottom: 80px;
}

.title_div h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    margin: 0px;
}

.board_header {
    display: flex;
    width: 80%;
    max-width: 1200px;
    padding: 0px 20px;
    background-color: #E4EAF8;
    border-top: 2px solid #4984A9;
    border-bottom: 1px solid #000000;
}

.board_header div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 29px;
    text-align: center;
    color: #333333;
    font-weight: bold;
}

.table_content_line {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 80%;
    background-color: #F7F7F7;
}

.table_content_line > div {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 20px;
}

.table_content_line > div > div {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.table_content_line > hr {
    border: 0;
    border-bottom: 1px solid silver;
    width: 100%;
}
.table_content_line:hover {
    cursor: pointer;
    background-color: rgba(230, 230, 230, 1);
}

.board_btn {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px !important;
}

.board_btn:hover {
    cursor: pointer;
}

.board_pagination {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px;
    height: 25px !important;
}


.board_pagination > a {
    outline: none;
    font-size: 10px;
}

.board_pagination > :nth-child(1){
    background-color: rgba(0, 0, 0, 0) !important;
}

.board_pagination > :nth-last-child(1) {
    background-color: rgba(0, 0, 0, 0) !important;
}

.form_select {
    color: #333333;
    background-color: rgba(0,0,0,.05);
    border: 1px solid #333333;
    outline: none;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-size: 12px;
    line-height: 29px;
    text-align: center;
    padding: 2px 0px 2px 10px;
}

.search_text {
    border: 1px solid #333333;
    outline: none;
    color: #333333;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #e2e2e2;
    padding: 5px;
    font-size: 12px;
}

.search_btn {
    background: rgba(0,0,0,.05);
    border: 1px solid #333333;
    box-sizing: border-box;
    color: #333333;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.search_btn:hover {
    cursor: pointer;
}

.scroll_hidden::-webkit-scrollbar {
    display: none;
}

.scroll_hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width: 768px) {
    .wrap_div { padding: 100px 0px; }
    .table_content_line { width: 100%; }
}