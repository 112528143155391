@keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
    from {background-position: 0;}
    to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  /* min-height: 1080px; */
  display:block;
}

.stars {
  background:#000 url(/images/login/stars.png) repeat top center;
  z-index:0;
}

.twinkling{
  background:transparent url(/images/login/twinkling.png) repeat top center;
  z-index:1;

  -moz-animation:move-twink-back 200s linear infinite;
  -ms-animation:move-twink-back 200s linear infinite;
  -o-animation:move-twink-back 200s linear infinite;
  -webkit-animation:move-twink-back 200s linear infinite;
  animation:move-twink-back 200s linear infinite;
}

.clouds{
    background:transparent url(/images/login/clouds3.png) repeat top center;
    z-index:3;

  -moz-animation:move-clouds-back 200s linear infinite;
  -ms-animation:move-clouds-back 200s linear infinite;
  -o-animation:move-clouds-back 200s linear infinite;
  -webkit-animation:move-clouds-back 200s linear infinite;
  animation:move-clouds-back 200s linear infinite;
}


.wrap_div {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content_div {
    border: 0px;
    box-shadow: 0 0 30px white !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 450px;
}
.content_div > p {
    color: #F0F0F0;
    font-size: 17px;
    font-weight: bold;
    /* position: relative; */
    /* top: -30px; */
    margin-bottom: 40px;
    margin-top: -40px;
}

.content_div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px;
}

.memberselect_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.memberselect_div > p {
    color: #F0F0F0;

}

.style_a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.style_a:hover {
    cursor: pointer;
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4,
                0 0 25px #03e9f4,
                0 0 50px #03e9f4,
                0 0 100px #03e9f4;
  }

.style_a1 {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #F22121;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    /* margin-top: 40px; */
    top: 40px;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
}

.style_a1:hover {
    cursor: pointer;
    background: #F22121;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #F22121,
                0 0 25px #F22121,
                0 0 50px #F22121,
                0 0 100px #F22121;
  }

  @media screen and (max-width: 768px) {
    .content_div {
        width: 80vw;
        height: 700px;
    }
    /* .content_div > p {
        top: -20px;
    } */
    .content_div > div {
        flex-direction: column;
    }
    .style_a {
        margin-bottom: 20px;
    }
    .style_a1 {
        top: 30px;
    }
  }