.wrapper {
    background-color: #d32d36;
    /* background-color: blue; */
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 79px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* overflow: hidden; */
}

.topmenuWrap {
    background-color: #d32d36;
    max-width: 1900px;
    width: 100vw;
    flex-shrink: 0;
    z-index: 1000;
}


/* .largeMenuLogo { */
    /* margin-top: 10px; */
    /* width: 55px !important; */
    /* background-color: black; */
/* } */

.smallLogoColumn {
    display: none !important;
}

/* .smallLogoColumn > img { */
    /* width: 40px; */
/* } */

.smallLogoColumn:hover {
    cursor: pointer;
}


.largeMenuLogo:hover {
    cursor: pointer;
}

.topmenuWrap span {
    font-weight: bold;
    color:white;
    font-size:1.2em;
}

.topmenuWrap .item {
    font-weight: bold;
    font-size:1em;
    color: #d32d36;
}

.topmenuWrap .item {
    font-weight: bold;
    font-size:1em;
    color: #d32d36;
}

.topmenuWrap .menuList {
    width:1000px;
    justify-content: space-between;
    align-items: center;
}

.topmenuWrap .miniMenuDiv {
    display: none;
}
.miniMenuColumn {
    display: none !important;
}

.miniMenuColumn > div > div * {
    font-size: 12px !important;
}

.largeMenuDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.largeMenuDiv > div > :nth-child(1){
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding-bottom: 10px !important;
}

.dropdown_tap {
    font-weight: bold !important;
    text-align: center !important;
    font-size: 13px !important;
}

.dropdown_tap:hover {
    background-color: #9B1C23 !important;
}


.dropdown_tap:hover > div >  img {
    color: white;
    filter: brightness(0) invert(1);
}

.dropdown_tap > :nth-child(2) {
    border: 0px !important;
}

.dropdown_tap > :nth-child(2) >div {
    background-color: #d32d36 !important;
    text-align: center !important;
    width: 95px !important;
    position: relative;
    height: 40px !important;
    color: white !important;
}

.dropdown_tap > :nth-child(2) > div > :nth-child(1) {
    color: white;
    font-family: "Noto Sans KR";
    font-weight: bold;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}


.dropdown_tap > :nth-child(2) >div:hover {
    background-color: #9B1C23 !important;
}

.dropdown_tap > :nth-child(2) > div >  span {
    font-family: "Noto Sans KR";
    font-weight: bold !important;
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    position: absolute !important;
    display: block !important;
    
    width: 95px !important;
    word-break: break-all !important;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50% );
    color: white !important;
}

.dropdown_tap > :nth-child(2) > div > :nth-child(2) {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
    border: none;
}

.dropdown_tap > :nth-child(2) > div > :nth-child(2) > div{
    background-color: #d32d36 !important;
    font-family: "Noto Sans KR" !important;
    font-style: normal !important;
    font-weight: bold !important;
    color: white !important;
    font-size: 13px !important;
    line-height: 15px !important;
    left: 10px;
    text-align: center !important;
}

.dropdown_tap > :nth-child(2) > div > :nth-child(2) > div:hover{
    background-color: #9B1C23 !important;
}

.dropdown_tap > :nth-child(2) > div > :nth-child(2)::after{
    background-color: #d32d36 !important;
    left: 7px !important;
}

.miniMenuDiv > div > div {
    background-color: #d32d36 !important;
}

.miniMenuDiv > div > div > div {
    color: white !important;
}




.style_a1 {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block !important;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    transition: .5s;
    letter-spacing: 0px;
    white-space: nowrap;
}

.style_a1:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;
  }

  .style_a2 {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* overflow: hidden; */
    transition: .5s;
    letter-spacing: 0px;
    margin-right: 5px;
    white-space: nowrap;
    font-family: "Noto Sans KR";
    /* width: 69px; */
}

.style_a2:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;
  }


  .style_a3 {
    border-radius: 5px;
    background-color: #DFA4A4;
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    /* overflow: hidden; */
    transition: .5s;
    letter-spacing: 0px;
    font-family: "Noto Sans KR";
    white-space: nowrap;
    /* width: 59px; */
}

.style_a3:hover {
    background: #DFA4A4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #DFA4A4,
                0 0 25px #DFA4A4,
                0 0 50px #DFA4A4,
                0 0 100px #DFA4A4;
    cursor: pointer;

  }

.profile_div > a > span{
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    color: white;
}

.login_dropdown {
    padding-right: 14px;
    position: relative !important;
}


.login_dropdown > div > :nth-child(1) > span  {
    display: static !important;
    color: #010101;
    font-size: 14px;
    display: none;
}

.login_dropdown > div > :nth-child(3) {
    position: absolute !important;
    background-color: white !important;
    top: 90px !important;
    left: 5px !important;
    transform: translate(-50%, -50%);

}

.login_dropdown > div > :nth-child(3) > div > img {
    width: 30px !important;
}

.login_dropdown > div > :nth-child(3) > div > span {
    font-family: "Noto Sans KR" !important;
    font-size: 13px !important;
    color: black;
    font-weight: 400 !important;
    text-align: center !important;
    width: 100% !important;
    display: inline-block;
}

.login_dropdown > div > :nth-child(3) > :nth-child(1) > span {
    display: inline;
}




.largeMenuLogoColumn {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: left !important;
}

@media screen and (min-width: 769px) {
    .topmenuWrap .miniMenuDiv {
        display: none;
    }

    .topmenuWrap .menuList {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .topmenuWrap {
        max-width: 100vw;
        min-width: 0px;
        width: 100vw;
    }

    .topmenuWrap > :nth-child(1){
        display: none;
    }
    .miniMenuColumn {
        display: flex !important;
    
    }
    .largeMenuDiv{
        display: none;
    }
    .largeMenuLogo{
        display: none;
    }
    .largeMenuLogoColumn{
        display: none !important;
    }
    .smallLogoColumn {
        display: flex !important;
    }

    .largeMenuDivColumn{
        display: none !important;
    }

    .topmenuWrap .miniMenuDiv {
        padding: 15px;
        display: flex;
        width:100%;
        align-items: center;
    }

    .login_dropdown {
        padding-right: 0px;
    }

    .login_dropdown > div > :nth-child(3) {
        left: -10px !important;    
    }

}
