.secondtab {
    background-position: center;
    /* min-height: 1080px; */
    height: 100%;
    min-height: 100vh;
    width: 100vw !important;
    /* padding: 180px 0em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.secondtab * {
    color:white;
}

.secondtab > div {
    width: 1200px;
}


.thirdtab > div {
    width: 1200px;
}

.intro_third_forth_img{
    width: 350px;
}

.intro_third_forth_img2{
    width: 400px;
}

.secondtab > div > :nth-child(1) {
    margin-right: 60px;
}

.thirdtab > div > :nth-child(1) {
    margin-right: 60px;
}

.secondtab > div > :nth-child(2) > :nth-child(1) > h1 {
    text-align: center;
    display: inline;
    font-size: 56px;
    font-family: "Noto Sans KR";
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
}
.secondtab > div > :nth-child(2) > :nth-child(2) > div{
    font-family: "Noto Sans KR";
    /* margin-top: 30px; */
    color: white;
    width: 700px;
    font-size: 20px;
    line-height: 160%;
}


.thirdtab > div > :nth-child(1) > :nth-child(1) > h1 {
    text-align: center;
    display: block;
    font-size: 56px;
    font-family: "Noto Sans KR";
    text-shadow: gray 2px 2px 2px;
    margin-top: 5px;
    word-break: keep-all;
}

.thirdtab > div > :nth-child(1) > :nth-child(2) > div {
    color: white;
    width: 700px;
    line-height: 160%;
    font-size: 20px;
    line-height: 160%;
    font-family: "Noto Sans KR";
}
/* style={{ textAlign: "left", display: "inline", fontSize: "1.3em" }} */
/*  */
.thirdtab {
    background-position: center;
    /* min-height: 1080px; */
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    /* padding: 180px 0em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.thirdtab * {
    color:white;
}

/*  */

.nav_link_down {
    position: absolute;
    bottom: 10px;
    /* background-color: red; */
}


.nav_link_down:hover {
    cursor: pointer;
}

/* .nav_link_down > i:hover {
    color: #D32D36;
} */

.nav_link_up {
    position: absolute;
    top: 10px;
}

.nav_link_up:hover {
    cursor: pointer;
}
/* 
.nav_link_up > i:hover {
    color: #D32D36;
} */

.intro_tap_box h1 {
    margin: 0;
    font-size:5em
}

.intro_tap_box .text_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* max-width:1200px;
    min-width:600px; */
}

/* @media screen and (max-width: 1200px){
    .intro_third_forth_img{
        width: 300px;
    }

    .secondtab > div > :nth-child(2) > :nth-child(2) > div{
        width: 500px;
    }
    
    .thirdtab > div > :nth-child(1) > :nth-child(2) > div{
        width: 500px;
    }
} */


@media screen and (max-width: 768px){
    .secondtab { height: max-content; padding: 180px 0px;}
    .thirdtab { height: max-content;}
    .secondtab > div > :nth-child(1) { margin-right: 0px; }
    .thirdtab > div > :nth-child(1) { margin-right: 0px; }

    .secondtab > div > :nth-child(2) > :nth-child(1) > h1 {
        /* color: red !important; */
        font-size: 3em;
        width: 85vw;
        word-break: break-all;
    }

    .thirdtab > div > :nth-child(1) > :nth-child(1) > h1 {
        /* color: red !important; */
        font-size: 3em;
        width: 85vw;
        word-break: break-all;
    }

    .secondtab > div > :nth-child(2) > :nth-child(2) > div{
        color: white;
        width: 100vw;
        font-size: 1.2em;
        line-height: 160%;
    }

    .secondtab > div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 430px;
    }
    
    .secondtab > div > :nth-child(2) {
        margin-top:50px; 
    }

    .secondtab {
        width: 350px;
    }

    .intro_third_forth_img {
        width: 280px;
    }

    .intro_third_forth_img2{
        width: 280px;
        margin-bottom: 40px;
    }

    /* .secondtab > div > :nth-child(2) > :nth-child(2) {
        width: 90vw;
    } */

    .thirdtab { padding: 120px 0px 30px 0px !important; }
    .thirdtab > div > :nth-child(1) > :nth-child(1) {
        width: 400px;
    }

    .thirdtab > div > :nth-child(1)  {
        margin-bottom: 180px;
    }

    .thirdtab > div > :nth-child(1) > :nth-child(2) > div {
        color: white;
        font-size: 1.2em;
        line-height: 160%;
        /* background-color: red; */
        width: 400px;
    }

    .thirdtab> div {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width:90vw;
    }

    .thirdtab > div > :nth-child(2) {
        margin-top:50px; 
    }

    .thirdtab > .intro_third_forth_img {
        margin-bottom: 60px;
        width: 350px;
    }


    .thirdtab > div > :nth-child(1) > :nth-child(2) {
        width: 460px;
    }

    .nav_link_down { display: none; }
    .nav_link_up { display: none; }
}