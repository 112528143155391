.competition.competition > span {
  color: white;
}

@media screen and (max-width: 768px) {
  .competition_form_wrap {
    max-width: 300px;
  }
}

.login_form_wrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
  /* background-color: ; */
  border-radius: 15px;
}

.login_form_div {
  border-radius: 15px;
  padding: 50px;
  /* opacity: 0.7; */
  border: 0px;
  box-shadow: 0 0 30px white !important;
  background: linear-gradient(235deg, #020202, #565656, #020202), 0.1 !important;
}

/* 배경 starts, twinkling, cloud 이벤트 */
@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-webkit-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-moz-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}
@-ms-keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-webkit-keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-moz-keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}
@-ms-keyframes move-clouds-back {
  from {
    background-position: 0;
  }
  to {
    background-position: 10000px 0;
  }
}

.stars,
.twinkling,
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 1500px;
  height: 100%;
  display: block;
}

.stars {
  background: #000 url(/images/login/stars.png) repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url(/images/login/twinkling.png) repeat top center;
  z-index: 1;

  -moz-animation: move-twink-back 200s linear infinite;
  -ms-animation: move-twink-back 200s linear infinite;
  -o-animation: move-twink-back 200s linear infinite;
  -webkit-animation: move-twink-back 200s linear infinite;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  background: transparent url(/images/login/clouds3.png) repeat top center;
  z-index: 3;

  -moz-animation: move-clouds-back 200s linear infinite;
  -ms-animation: move-clouds-back 200s linear infinite;
  -o-animation: move-clouds-back 200s linear infinite;
  -webkit-animation: move-clouds-back 200s linear infinite;
  animation: move-clouds-back 200s linear infinite;
}

/* ==================== */

/* ============================ 버튼 이벤트 a태그 */
.style_a1 {
  border-radius: 5px;
  background-color: #4b89dc;
  position: relative;
  display: inline-block;
  padding: 7px 10px;
  color: white;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.style_a1:hover {
  background: #4b89dc;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #4b89dc, 0 0 25px #4b89dc, 0 0 50px #4b89dc,
    0 0 100px #4b89dc;
}

.style_a2 {
  border-radius: 5px;
  background-color: #88dc4b;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.style_a2:hover {
  background: #88dc4b;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #88dc4b, 0 0 25px #88dc4b, 0 0 50px #88dc4b,
    0 0 100px #88dc4b;
}

.style_a3 {
  background-color: #e02e0e;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 0px;
}

.style_a3:hover {
  background: #e02e0e;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e02e0e, 0 0 25px #e02e0e, 0 0 50px #e02e0e,
    0 0 100px #e02e0e;
}

.style_a > span {
  position: absolute;
  display: block;
}

.style_a > :nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

.mouse_hover:hover {
  cursor: pointer;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.style_a > :nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.style_a > :nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.style_a > :nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
/* ================================= */

@media screen and (max-width: 768px) {
  .login_form_wrap {
    width: 500px;
  }
  .login_form_div {
    width: 500px;
  }
}
