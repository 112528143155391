/* @font-face {
    font-family: 'Noto Sans KR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/Noto Sans KR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

.wrap_div * {
    font-family: "Noto Sans KR";
    color: white;
    font-size: 0.8em;
}

.wrap_div {
    background-color: #D32D36;
    background-image: url('/images/footer/background_01.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    flex-shrink: 0;
    width: 100vw;
    position: relative;
    z-index: 99;
}

.wrap_div > :nth-child(1) > :nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    /* margin-top: 15px; */
}

.wrap_div > :nth-child(1) > :nth-child(1) > span {
    font-style: normal;
    font-weight: bold;
    font-size: 1.8em;
    text-align: center;
    line-height: 27px;
    margin-left: 15px;
}

.wrap_div > :nth-child(1) > :nth-child(2) {
    margin-top: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.wrap_div > :nth-child(2) {
    margin-top: 40px;
}

.wrap_div > :nth-child(2) > div > img:hover {
 cursor: pointer;
}


.lang_div * {
    color:black;
}

.lang_div {
    display: flex;
    /* background-color: white; */
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.wrap_div > :nth-child(1) > :nth-child(2) > a {
    color: #FFFFFF;
    font-size: 9pt;
    border-bottom: 1px solid #FFFFFF;
    padding: 2px;
    margin-left: 60px;
}


@media screen and (max-width: 768px) {
    .wrap_div { flex-direction: column; word-break: keep-all;}
    .wrap_div > :nth-child(1) { display: flex; flex-direction: column; align-items: center;}
    .wrap_div > :nth-child(1) > p { text-align: center; }
}