.wrap_div{
    /* padding: 180px 0px !important; */
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.content_div {
    max-width: 1200px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.teamRequests_table > tr:hover {
    cursor: pointer;
    background-color: rgba(0,0,50,.1) !important;
  }
  