.wrap_div {
    background-image: url("/images/main/bg_shop.png");
    width: 100vw;
    display: flex;
    padding: 30px 0px;
    /* height: 200px; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-repeat: no-repeat; */
    background-position: center;
    /* background-size: cover; */
    /* overflow-x: hidden; */
}

.banner_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    flex-wrap: wrap;
}

.banner_div > div {
    margin: 10px;
}