.menu_div {
    /* width: 150px; */
    height: max-content;
    border-radius: 5px;
    padding: 25px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 30px;
    min-width: 200px;
}

.menu_div> h1 {
    font-size: 20px;
    margin: 0px;
}

.menu_div > hr {
    border: 0;
    border-top: 1px solid rgba(17, 12, 46, 0.15);
    width: 100%;
    margin: 10px 0px;
}

.menu_div > p {
    /* background-color: red; */
    margin: 0px;
    padding: 5px;
}

.menu_div > p:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.alarm_div1 {
    background-color: #F4F8FF;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.alarm_div1:hover{
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}

.alarm_div2 {
    background-color: #F4F8FF;
    height: max-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.alarm_div2:hover {
    border-right: 3px solid blue;
    background-color: rgba(17, 12, 46, 0.05) !important;
    border-radius: 2px;
    cursor: pointer;
}