@import url("https://blogfonts.com/css/aWQ9MTU1MTUxJnN1Yj0xNTEmYz1wJnR0Zj1wb3N0LW5vLWJpbGxzLWNvbG9tYm8uZXh0cmFib2xkLnR0ZiZuPXBvc3Qtbm8tYmlsbHMtY29sb21iby1leHRyYWJvbGQ/Post No Bills Colombo ExtraBold.ttf");
.content_div {
    padding: 100px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(/images/info_competition/bg_02.jpg);
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
}

.content_div > h1 {
    font-family: "post-no-bills-colombo-extrabold", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(52, 52, 52, 0.25), 0px 0px 250px #ffffff;
}

.content_div > hr {
    width: 171px;
}

.dropdown_div {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.dropdown_div > :nth-child(1) {
    margin-right: 10px;
}

.arrow_btn {
    color: rgba(255, 255, 255, 0.5);
    text-shadow: 2px 4px 6px gray;
}

.arrow_btn:hover {
    cursor: pointer;
    color: #ffffff;
}

.competition_carousel {
    max-width: 1200px;
    width: 100vw;
    height: 100vh;
    max-height: 700px;
    margin-top: 80px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.competition_carousel > div > :nth-child(1) {
    opacity: 0.8 !important;
}
.competition_carousel > div > :nth-child(2) {
    opacity: 0.8 !important;
}
.competition_carousel > div > :nth-child(4) {
    opacity: 0.8 !important;
}
.competition_carousel > div > :nth-child(5) {
    opacity: 0.8 !important;
}

.competition_posters {
    border-radius: 10px;
    box-shadow: 0px 4px 30px gray;
}

.competition_posters:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition-duration: 700ms;
}

.transition_div {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 30px;
    border-radius: 15px;
}

.transition_content_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.transition_img {
    max-width: 600px;
    width: auto;
    height: 70vh;
    max-height: 900px;
}

.transition_img:hover {
    cursor: pointer;
}

.infoText_div {
    margin: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Noto Sans KR";
    word-break: keep-all;
    text-align: center;
}

.infoText_div > hr {
    width: 100%;
}

.infoText_div > h1 {
    margin: 5px;
    font-weight: bold;
}

.infoText_div > p {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    margin: 5px;
}

.infoText_div > p > span {
    font-family: "Noto Sans KR";
    text-align: center;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.orange_span {
    color: #f7941c;
    font-weight: 400;
}

/* .infoText_div > button {
    background: linear-gradient(90deg, #4158CD 4.83%, #A177E7 97.24%), #C4C4C4;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    border-radius: 7px;
    outline: none;
    border: 0px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    width: 110px;
    margin-top: 10px;
}

.infoText_div > button:hover{
    cursor: pointer;
} */

.all_rank_btn {
    margin-top: 23px;
    border: 2px solid #bd3fdc;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 9px #c44cd8);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0);
    font-family: "Noto Sans KR";
    color: #ffffff;
    width: 110px;
    font-style: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.015em;
    text-shadow: 0px 1.25px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    transition: background-color 0.5s ease;
    /* margin-bottom: 300px; */
}

.all_rank_btn:hover {
    cursor: pointer;
    background-color: #bd3fdc;
}

@media screen and (max-width: 1200px) {
    .competition_carousel {
        max-width: 800px;
        max-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .content_div {
        padding: 100px 0px;
    }
    .dropdown_div {
        flex-direction: column;
    }
    .dropdown_div > :nth-child(1) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .competition_carousel {
        width: 100%;
        height: 300px;
        margin-bottom: 40px;
    }
    .transition_img {
        height: 40vh;
    }
    .infoText_div {
        width: 80%;
    }
}
