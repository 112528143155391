.wrap_div {
    padding: 130px 0px;
    font-family: "Noto Sans KR";
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: #f1f1f1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.wrap_div *{
    color: #020202;
    font-family: "Noto Sans KR";
}

.title_div {
    margin-bottom: 80px;
}

.title_div > h1 {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 58px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #020202;
    margin: 0px;
}

.content_div {
    border-top: 2px solid #4984A9;
    border-bottom: 2px solid silver;
    max-width: 1200px;
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}
.modify_btn {
    background: rgba(0,0,0,.05);
    border: 1px solid #020202;
    box-sizing: border-box;
    color: #020202;
    font-family: "Noto Sans KR";
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    padding: 5px 10px;
}

.modify_btn:hover {
    cursor: pointer;
}

.del_btn {
    background-color: rgba(255, 0, 0, 0.4);
    outline: none;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 13px;
    font-family: "Noto Sans KR";
    font-style: normal;
    width: 32px !important;
    height: 19px !important;
    padding: 0px 5px;
    font-size: 11px;
    color: #FFFFFF;
    margin-left: 10px;
}

.del_btn:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .wrap_div { padding: 100px 0px; }
    .content_div {
        padding: 10px;
    }
}